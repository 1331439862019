import React, { Suspense } from "react";
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCol, IonContent, IonGrid,
  IonPage, IonRow
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATH_LOGIN } from 'mimex-libs/helpers/constants';
import { AccountFooter } from './components/AccountFooter';
import { AccountHeader } from './components/AccountHeader';

import '../../styles/global.css';
import './Login.css';


interface EmailValidationProps {
}

export function EmailValidation(props: EmailValidationProps) {

  const history = useHistory()

  const { t } = useTranslation(["translation"]);

  const onClick = () => {
    history.replace(PATH_LOGIN)
  }

  return (
    <IonPage data-testid="email-validation">
      <AccountHeader />
      <IonContent fullscreen>
        <Suspense fallback={null}>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonCardHeader>
                  <IonCardTitle
                    className="verify-email-title"
                  >
                    {t('Verification required')}
                  </IonCardTitle>
                  <IonCardSubtitle className="verify-email-subtitle">
                    {t('To continue, please click on the activation link we have sent to your email.')}
                  </IonCardSubtitle>
                </IonCardHeader>
              </IonCol>
            </IonRow>

          </IonGrid>
        </Suspense>
      </IonContent>

      <AccountFooter buttonClick={onClick} buttonLabel={t('Continue')} disabled={false} />

    </IonPage>
  )
}