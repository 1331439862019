import { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonModal, IonPage, IonRow, IonText, useIonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TopToolbar from 'src/components/TopToolbar';
import { BASE_URL, PATH_VIRTUAL_CART } from 'mimex-libs/helpers/constants';
import { getUserId } from 'mimex-libs/helpers/userStore';
import { getClient } from "mimex-libs/api/sdk";
import { createLogger } from "mimex-libs/logger";
import { UserAccountDto, UserAccountDtoStatusEnum, UserProfileDto } from 'mimex-libs/sdk';
import TabBarContainer from '../../components/TabBarContainer';
import { getCurrentUserInfo, getUserAccount } from 'mimex-libs/api/endpoints';
import { UserDisabled } from './components/UserDisabled';
import { QrcodeModal } from './components/QrcodeModal';

import './Dashboard.css';

const logger = createLogger({ component: 'Dashboard' })

export function Dashboard() {
  const history = useHistory()
  const { t } = useTranslation(["translation"]);
  const [name, setName] = useState("")
  const [showQrCodeModal, setShowQrCodeModal] = useState(false)
  const [userDisabled, setUserDisabled] = useState(false)
  const [showProgress, hideProgress] = useIonLoading();

  const dismissQrCodeModal = () => {
    setShowQrCodeModal(false)
  }

  useEffect(() => {
    window.onbeforeunload = function (e) {
      return "You're leaving the app, are you sure?";
    };
    return () => {
      window.onbeforeunload = null
    }
  }, [])


  useEffect(() => {
    getClient()?.shoppingControllerGetCurrentSession()
      .then((res) => {
        if (Object.entries(res.data).length !== 0) {
          logger.info(`Session retrieved`)
          history.push({
            pathname: PATH_VIRTUAL_CART,
          })
        }
      }).catch(e => {
        logger.error(`no session`)
      })
  }, [history]);


  useEffect(() => {
    showProgress({
      message: t('Loading'),
      duration: 2000
    })

    const userId = getUserId()
    if (!userId) return
    getCurrentUserInfo(userId).then((user: UserProfileDto) => {
      setName(user.name)
      logger.debug(`user profile info retrieved`)
      getUserAccount().then((userAccount: UserAccountDto) => {
        logger.debug(`Checking user account if enabled`)
        if (userAccount.status === UserAccountDtoStatusEnum.PaymentNeeded) {
          setUserDisabled(true)
        }
        hideProgress()
      }).catch((e: any) => {
        logger.error(`Error in retrieving account info of the user: ${e.message}`, { ref: 'getUserAccount' })
      })
    }).catch((e: any) => {
      logger.error(`Error in retrieving profile info about the user: ${e.message}`, { ref: 'getCurrentUserInfo' })
    })
  }, [hideProgress, setUserDisabled, showProgress, t])

  const clickQrCodeButton = (event: any) => {
    event.preventDefault()
    //Show QR code scanning modal
    setShowQrCodeModal(true)
  }

  return (
    <ion-tabs data-testid="dashboard-page">
      <IonPage>
        <TopToolbar />
        {userDisabled ? <UserDisabled /> : (
          <>
            <QrcodeModal open={showQrCodeModal} onClose={dismissQrCodeModal} />
            <IonContent>
              <IonGrid>
                <IonRow className="user-row">
                  <IonCol className="ion-text-center" size="12">
                    <IonText> <h2> <strong>{t("Hello")}, {name} </strong> </h2></IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonImg data-testid="slide-image" src={`${BASE_URL}/assets/images/slide6.svg`} className="dashboard-img" />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonButton data-testid="scan-qr-code-button" id="qr-code-button" expand="block" fill="solid" color="primary" onClick={(e) => clickQrCodeButton(e)}>
                  {t("Scan QR Code")}
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}