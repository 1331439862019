import { EventEmitter } from 'events'

export interface TokenPayload {
  aud: string
  auth_time: number
  email: string
  email_verified: boolean
  exp: number
  firebase: unknown
  iat: number
  iss: string
  sub: string
  user_id: string
}

export enum UserStoreOperation {
  REMOVED, 
  UPDATED
}

export interface UserStoreChanged<T=unknown> {
  op: UserStoreOperation
  type: UserStoreType
  value: T
}

export enum UserStoreType {
  userToken="userToken", 
  userLanguage="userLanguage", 
  storeId="storeId",
  onBoardingSession="onBoardingSession",
  session="session",
  paymentMethod="paymentMethod",
  configLoader="configLoader",
  userAccount="userAccount",
  broker="broker",
  appVersion ="appVersion",
  // used for dashboard demo mode
  mode="mode",
}

const loadLocalStorage = () => {
  Object.values(UserStoreType).forEach(key => {
    const raw = localStorage.getItem(key) || null
    if (raw) {
      try {
        const val = JSON.parse(raw)
        memoryStoreData.set(UserStoreType[key], val)
      } catch{}
    }
  })
}

type MemoryStoreEmitterCallback<T=unknown> = (ev: UserStoreChanged<T>) => void

class MemoryStoreEmitter {
  ee = new EventEmitter()
  emit<T=unknown>(type: UserStoreType, op: UserStoreOperation, value: T = null): boolean {
    const userStoreChanged = {type, op, value} as UserStoreChanged
    this.ee.emit("store", userStoreChanged)
    this.ee.emit(type, userStoreChanged)
    return true
  }
  on(type: UserStoreType, cb: MemoryStoreEmitterCallback): this {
    this.ee.on(type, cb)
    return this
  }
  once(type: UserStoreType, cb: MemoryStoreEmitterCallback): this {
    this.ee.once(type, cb)
    return this
  }
  off(type: UserStoreType, cb: MemoryStoreEmitterCallback): this {
    this.ee.off(type, cb)
    return this
  }
}

export const emitter = new MemoryStoreEmitter()
//memory store
const memoryStoreData = new Map<UserStoreType, unknown>()
// load values from local storage
if (typeof window !== "undefined") {
  loadLocalStorage()
}

export const memoryStore = {
  set(key: UserStoreType, value: unknown, save=false): void {
    memoryStoreData.set(key, value)
    if (save) {
      localStorage.setItem(key, JSON.stringify(value))
    }
    emitter.emit(key, UserStoreOperation.UPDATED, value)
  },
  get<T=unknown>(key: UserStoreType, defaultValue: any = null): T {
    const val = memoryStoreData.get(key)
    return val === undefined ? defaultValue : val
  },
  del(key: UserStoreType, save=true): void {
    memoryStoreData.delete(key)
    if (save) {
      localStorage.removeItem(key);
    }
    emitter.emit(key, UserStoreOperation.REMOVED)
  }
}

// app version
export const setAppVersion = (version: string): void => {
  memoryStore.set(UserStoreType.appVersion, version, true)
}
export const getAppVersion = (): string | null => {
  const token = memoryStore.get(UserStoreType.appVersion, '') as string
  return token || null
}
export const deleteAppVersion = (): void => {
  memoryStore.del(UserStoreType.appVersion, true)
};

// access token
export const setUserToken = (userToken: string): void => {
  memoryStore.set(UserStoreType.userToken, userToken, true)
}
export const getUserToken = (): string | null => {
  const token = memoryStore.get(UserStoreType.userToken, '') as string
  return token || null
}
export const deleteUserToken = (): void => {
  memoryStore.del(UserStoreType.userToken, true)
};

// language selection
export const setSelectedLanguage = (lang: string): void => {
  memoryStore.set(UserStoreType.userLanguage, lang, true)
};
export const getSelectedLanguage = (): string | null => {
  const lang = memoryStore.get(UserStoreType.userLanguage, '') as string
  return lang || null
};
export const deleteSelectedLanguage = (): void => {
  memoryStore.del(UserStoreType.userLanguage, true)
};

// storeId
export const setStoreId = (id: string): void => {
  memoryStore.set(UserStoreType.storeId, id, true)
}
export const getStoreId = (): string | null => {
  const val = memoryStore.get(UserStoreType.storeId, '') as string
  return val || null
}
export const deleteStoreId = (): void => {
  memoryStore.del(UserStoreType.storeId, true)
}

// onBoardingSession
export const setOnBoardingSession = (value: string = "ok"): void => {
  memoryStore.set(UserStoreType.onBoardingSession, value, true)
}
export const getOnBoardingSession = (): string | null => {
  const val = memoryStore.get(UserStoreType.onBoardingSession, '') as string
  return val || null
}
export const deleteOnBoardingSession = (): void => {
  memoryStore.del(UserStoreType.onBoardingSession, true)
}


// token handling functions
export const getTokenPayload = (): TokenPayload|null => {
  const token = memoryStore.get(UserStoreType.userToken) as string
  if (!token) return null
  try {
    return JSON.parse(atob(token.split(".")[1])) as TokenPayload
  } catch (e: any) {
    console.debug("cannot parse token", e)
  }
  return null
}

export const isLoggedIn = () => {
  const token = getTokenPayload()
  if (!token) return false
  if (token.exp === undefined) return true
  return token.exp * 1000 > Date.now()
}

export const getUserId = (): string | null => {
  const tokenInfo = getTokenPayload()
  return tokenInfo ? tokenInfo.sub : null
};
