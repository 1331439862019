import React, { useCallback, useEffect, useState } from "react";
import { IonCol, IonGrid, IonInput, IonList, IonRouterLink, IonRow, useIonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { createLogger } from "mimex-libs/logger";
import useToast from '../../CustomHooks/useToast';
import { BASE_URL, ERROR, GENERIC_ERROR, PATH_PASSWORD_RESET, PATH_VERIFY_EMAIL, SUCCESS } from "mimex-libs/helpers/constants";
import { GetJWTHandler } from 'mimex-libs/api/endpoints';
import '../../styles/global.css';
import './Login.css';

const logger = createLogger({ component: 'Login' })

interface LoginProps {
  onLoginSuccess: (jwt: string) => void
  setDisabled: (value: boolean) => void
  submit: boolean
}

export function Login(props: LoginProps) {

  const { t, i18n } = useTranslation(["translation"]);
  const history = useHistory()

  const [showProgress, hideProgress] = useIonLoading();
  const [renderToast] = useToast()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    i18n.changeLanguage(navigator.language)
  }, [])

  const emailChanged = (e: any) => {
    setEmail(e.target.value)
  }
  const passwordChanged = (e: any) => {
    setPassword(e.target.value)
  }

  const submit = useCallback(() => {
    showProgress({
      message: t('Loading'),
      duration: 30 * 1000
    })

    GetJWTHandler(email, password)
      .then((jwt: string) => {
        logger.info(`Login succeeded`)
        renderToast(SUCCESS, "Login succeeded")
        props.onLoginSuccess(jwt)
      })
      .catch((e) => {

        if (e.response && e.response.data && e.response.data.error) {
          logger.error(`Login error: ${e.response.data.error.message}`, { section: 'userLoginHandler' })
          if (e.response.data.error.code === 10) { // email not verified
            history.push(PATH_VERIFY_EMAIL)
            return
          }
          if (e.response.data.error.code === 0) { // not found
            renderToast(ERROR, t('Account email not found'))
            return
          }
          renderToast(ERROR, e.response.data.error.message)
        } else {
          logger.error(`Login error: ${e.message}`, { section: 'userLoginHandler' })
          renderToast(ERROR, GENERIC_ERROR)
        }

      })
      .finally(() => {
        hideProgress()
      })
  }, [email, hideProgress, history, password, props, renderToast, showProgress, t])

  useEffect(() => {
    if (props.submit) {
      submit()
    }
  }, [props.submit, submit])

  useEffect(() => {
    props.setDisabled(!email || !password)
  }, [email, password, props])

  return (
    <IonGrid data-testid="login">
      <IonRow>
        <IonCol size="12">
          <form onSubmit={() => submit()}>
            <IonList>
              <IonInput
                id="login-email"
                data-testid="login-email"
                name="email"
                value={email}
                autocomplete="username"
                onIonInput={emailChanged}
                onChange={emailChanged}
                onFocus={emailChanged}
                placeholder={t("Enter your email")}
                ref={(input) => { emailChanged({ target: { value: input?.value } }) }}
              />
              <IonInput
                id="login-password"
                data-testid="login-password"
                name="password"
                value={password}
                autocomplete="current-password"
                onIonInput={passwordChanged}
                onChange={passwordChanged}
                onFocus={passwordChanged}
                type="password"
                placeholder={t("Enter your password")}
                ref={(input) => { passwordChanged({ target: { value: input?.value } }) }}
              />
            </IonList>
          </form>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <div className="ion-text-center">
            <IonRouterLink href={BASE_URL + PATH_PASSWORD_RESET} >
              {t("Forgot password?")}
            </IonRouterLink>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}
