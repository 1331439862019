
const loggerBuffer = {
    store: new Array<string>(),
    onAddCallback: () => {},
    add(message: string): void {
        this.store.push(message)
        this.onAddCallback && this.onAddCallback()
    },
    shift(): string|null {
        return this.store.length ? this.store.shift() || null : null
    },
    size(): number {
        return this.store.length
    },
    clean() {
        this.store.splice(0)
    }
}

export default loggerBuffer