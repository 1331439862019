
import { APP_NAME } from './helpers/constants'
import { getUserId } from './helpers/userStore'
import loggerBuffer from './loggerBuffer'

export enum LogLevel {
    'trace' = 'trace', 
    'debug' = 'debug', 
    'info' = 'info', 
    'warn' = 'warn', 
    'error' = 'error'
}

export interface Context {
    app?: string
    id?: string
    group?: string
    component?: string
    section?: string
    ref?: string
}

export interface LogOpts {
    skipSync: boolean
}

const log = (level: LogLevel, message: string|Error, context?: Context, logOpts?: LogOpts) => {

    logOpts = logOpts || {} as LogOpts

    if (message instanceof Error) {
        message = `Error: ${message.message} stack=${message.stack}`
    }
    
    context = context || {}
    context.app = APP_NAME

    const userId = getUserId()
    if (userId) context.id = userId

    let output = ''
    let ctx = ''
    if (context) {
        ctx = Object.keys(context).reduce((c, k) => ([...c, `${k}=${(context as any)[k]}`]), [] as string[]).join(" ")
    }

    output += message
    
    if (process.env.NODE_ENV !== 'production') {
        console[level]((ctx ? `[${ctx}] ` : '') + output)
    }
    
    if (!logOpts.skipSync && ["info", "warn", "error"].includes(level)) {
        loggerBuffer.add(JSON.stringify({level, message, context}))
    }
}

export interface Logger {
    trace: (message: string, context?: Context) => void
    debug: (message: string, context?: Context) => void
    info: (message: string, context?: Context) => void
    warn: (message: string, context?: Context) => void
    error: (message: string|Error, context?: Context) => void
}

const mergeContext = (c1?: Context, c2?: Context): Context|undefined => {
    if (!c1) return c2
    if (!c2) return c1
    return Object.assign({}, (c1 as any), (c2 as any)) as Context
}

export const createLogger = (defaultContext?: Context, logOpts?: LogOpts): Logger => {
    return {
        trace: (message: string, context: Context) => log(LogLevel.trace, message, mergeContext(defaultContext, context), logOpts),
        debug: (message: string, context: Context) => log(LogLevel.debug, message, mergeContext(defaultContext, context), logOpts),
        info: (message: string, context: Context) => log(LogLevel.info, message, mergeContext(defaultContext, context), logOpts),
        warn: (message: string, context: Context) => log(LogLevel.warn, message, mergeContext(defaultContext, context), logOpts),
        error: (message: string | Error, context: Context) => log(LogLevel.error, message, mergeContext(defaultContext, context), logOpts),
    } as Logger
}

const logger = createLogger({component: "logger"})

export const getLogger = (): Logger => {
    return logger
}