import { IonContent } from '@ionic/react';
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from 'react';
import MapInfoWindow from './MapInfoWindow';
import './MapView.css';

interface GeoPoint {
  type: string
  coordinates: number[]
}
interface IStore {
  storeId: string
  name: string
  location: GeoPoint
  address: string
  image: string
}

const containerStyle = {
  width: "100vw",
  height: "100vh"
};

const defaultCenter = {
  lat: 41.8719, lng: 12.5674
};


const MapView = (props: any) => {
  const { storeList } = props
  const [storeCoords, setStoreCoords] = useState(defaultCenter)
  const [activeStore, setActiveStore] = useState<string>();
  const [map, setMap] = React.useState(null)

  useEffect(() => {
    if (activeStore !== undefined) return
    if (!storeList || !storeList.length) return
    const [{ storeId, location }] = storeList
    setStoreCoords((prevState: any) => ({
      ...prevState,
      lat: location.coordinates[0], lng: location.coordinates[1]
    }))
    setActiveStore(storeId);
  }, [storeList, setStoreCoords, activeStore])

  const handleActiveMarker = (marker: IStore): string => {
    if (marker.storeId === activeStore) return marker.name
    setActiveStore(marker.storeId);
    return marker.name
  };

  const handleOnLoad = (map: any) => {
    // const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    // map.fitBounds(bounds);
  };

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  return (
    <IonContent className="parent-container" data-testid="map-view">
      <GoogleMap
        onLoad={handleOnLoad}
        onUnmount={onUnmount}
        onClick={() => setActiveStore(undefined)}
        zoom={10}
        mapContainerStyle={containerStyle}
        center={storeCoords}
      >
        {storeList.map((store: IStore, index: number) => {
          const { coordinates, type } = store.location
          return <Marker
            key={store.storeId || index}
            title={store.name}
            position={{ lat: coordinates[0], lng: coordinates[1] }}
            onClick={() => handleActiveMarker(store)}
          >
            {activeStore === store.storeId ? (
              <InfoWindow
                data-testid="close-button"
                position={{ lat: coordinates[0], lng: coordinates[1] }}
                onCloseClick={() => setActiveStore(undefined)}
              >
                <MapInfoWindow store={store} />
              </InfoWindow>
            ) : null}
          </Marker>
        })}
      </GoogleMap>
    </IonContent>
  );
}

export default React.memo(MapView)
