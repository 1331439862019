import React, { Suspense, useCallback, useEffect, useState } from "react";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { PATH_LOGIN } from 'mimex-libs/helpers/constants';
import '../../styles/global.css';
import { AccountHeader } from './components/AccountHeader';
import { AccountFooter } from './components/AccountFooter';
import { AccountSwitch } from './components/AccountSwitch';
import { Login } from './Login';
import { Registration } from './Registration';

import './Login.css';

interface AccountProps {
  onLoginSuccess: (jwt: string) => void
  onRegistrationSuccess: () => void
}



export function Account(props: AccountProps) {

  const { t } = useTranslation(["translation"]);

  const [view, setView] = useState(PATH_LOGIN)
  const [disabled, setDisabled] = useState(true)
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    if (submit) setSubmit(false)
  }, [submit, setSubmit])

  const onSubmit = useCallback(() => {
    setSubmit(true)
  }, [])

  const onSwitchChange = (value: string) => {
    setView(value)
  }

  const isLoginPage = () => {
    return view === PATH_LOGIN
  }

  return (
    <IonPage data-testid="account-page">
      <AccountHeader />
      <IonContent fullscreen>
        <Suspense fallback={null}>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <AccountSwitch value={view} onSwitchChange={onSwitchChange} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                {
                  isLoginPage() ?
                    <Login onLoginSuccess={props.onLoginSuccess} setDisabled={setDisabled} submit={submit} />
                    :
                    <Registration onRegistrationSuccess={props.onRegistrationSuccess} setDisabled={setDisabled} submit={submit} />
                }
              </IonCol>
            </IonRow>
          </IonGrid>
        </Suspense>
      </IonContent>
      <AccountFooter buttonClick={onSubmit} buttonLabel={t(isLoginPage() ? 'Login' : 'Create your account')} disabled={disabled} />
    </IonPage>
  )
}