import React from 'react';

export const Spinner: React.FC = () => {

 return( 
   <>
    <div data-testid="spinner" style={{
              position:"fixed",
              overflow:"show",
              zIndex:999,
              margin:"auto",
              top:0,
              left:0,
              bottom:0,
              right:0,
              width:"80px",
              height:"50px",
    }}>
     Loading...
    </div>
    </>
)}