import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from "@ionic/react";
import { BrokerMessage, getBroker } from "mimex-libs/api/broker";
import {
  currentShoppingSession,
  getVirtualCartHandler,
  virtualCartAddItemHandler,
  virtualCartRemoveItemHandler
} from "mimex-libs/api/endpoints";
import { PATH_HOME, SUCCESS, WARNING } from "mimex-libs/helpers/constants";
import { parseCurrency } from "mimex-libs/helpers/currency";
import { createLogger } from "mimex-libs/logger";
import { ShoppingSessionDtoMimexResponse, VirtualCartItemDto } from "mimex-libs/sdk";
import { CartEvent, CartEventStatus } from 'mimex-libs/sdk/events/cart.events';
import { SessionEvent, ShoppingSessionStatus } from "mimex-libs/sdk/events/session.events";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import TopToolbar from "src/components/TopToolbar";
import ItemsList from "../components/ItemsList";
import PaymentStatus from "../components/PaymentStatus";
import TabBarContainer from "../components/TabBarContainer";
import useToast from '../CustomHooks/useToast';
import SearchItemModal from "../modals/SearchItemModal";
import "./VirtualCart.css";


const logger = createLogger({ component: 'VirtualCart' })

const VirtualCart: React.FC = () => {
  const { t } = useTranslation(["translation"]);

  const [eventItems, setEventItems] = useState([] as VirtualCartItemDto[])
  const [items, setItems] = useState<VirtualCartItemDto[]>([]);
  const [showSearchItemModal, setShowSearchItemModal] = useState(false);
  const [virtualCartOpened, setVirtualCartOpened] = useState(false);
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [session, setSession] = useState('')
  const [onGoing, setOnGoing] = useState(false)
  const [updating, setUpdating] = useState(false)
  let history = useHistory()
  const [renderToast] = useToast()

  function fetchVirtualCart(sessionId: string) {
    getVirtualCartHandler(sessionId)
      .then((response: any) => {
        logger.info(`Retrieving virtual cart for ${sessionId}`)
        setItems(response.items);
        setVirtualCartOpened(true);
      })
      .catch((e) => {
        logger.error(e, { ref: 'fetchVirtualCart' })
        if (e.response && e.response.status === 404) {
          setVirtualCartOpened(false);
        }
      });
  }

  async function addItemPopover(item: VirtualCartItemDto) {
    console.log('add one item', item)
    await addItem(item);
  }

  async function removeItemPopover(item: VirtualCartItemDto) {
    if (item.quantity === 1 && !window.confirm(`Remove ${item.name} from the cart?`)) {
      return
    }
    await removeItem(item);
  }

  async function addItem(item: VirtualCartItemDto) {
    setUpdating(true)
    await virtualCartAddItemHandler(session, item)
      .then((response: any) => {
        addToCart(item);
        setUpdating(false)
      })
      .catch((e) => {
        logger.error(e, { ref: 'addItem' })
        setUpdating(false)
      });
    setShowSearchItemModal(false);
  }

  async function removeItem(item: VirtualCartItemDto) {
    setUpdating(true)
    await virtualCartRemoveItemHandler(session, item)
      .then((response: any) => {
        removeFromCart(item, false, false);
        setUpdating(false)
      })
      .catch((e) => {
        logger.error(e, { ref: 'removeItem' })
        setUpdating(false)
      });
  }

  function addToCart(item: VirtualCartItemDto) {
    let found = false;
    setItems(
      items.map((p) => {
        if (p.productId === item.productId) {
          p.quantity++;
          found = true;
        }
        return p;
      })
    );
    if (!found) {
      item.quantity = 1;
      setItems([...items, item]);
    }
  }

  const removeFromCart = useCallback((item: VirtualCartItemDto, show: boolean, forceRemove: boolean) => {
    let toRemove = forceRemove
    let found = false
    setItems(
      items.map((p) => {
        if (p.productId === item.productId) {
          found = true
          p.quantity--;
          if (p.quantity === 0) {
            toRemove = true
          }
        }
        return p;
      })
    );
    if (found) {
      if (toRemove) {
        setItems(items.filter((p) => p.productId !== item.productId));
      }
      if (show) {
        renderToast(WARNING, `${item.name} ${t('removed from cart')}`)
      }
    }
  }, [items, renderToast])

  const addToCartFromEvent = useCallback((item: VirtualCartItemDto) => {
    if (item.quantity === 0) {
      removeFromCart(item, true, true)
      return
    }
    let found = false;
    setItems(
      items.map((p) => {
        if (p.productId === item.productId) {
          p.quantity = item.quantity
          found = true;
        }
        return p;
      })
    );
    if (!found) {
      setItems([...items, item]);
    }
    renderToast(SUCCESS, `${item.name} ${t('added to cart')}`)
  }, [items, removeFromCart, renderToast])

  function hideModal() {
    setShowSearchItemModal(false);
  }

  const getTotalPrice = () => items.reduce((acc, item) => acc + item.unitPrice * item.quantity, 0).toFixed(2);

  const getUserSession = (): void => {
    currentShoppingSession()
      .then((res: ShoppingSessionDtoMimexResponse) => {
        if (!res || !res.data.sessionId) {
          history.push(PATH_HOME)
          return
        }
        setSession(res.data.sessionId)
        fetchVirtualCart(res.data.sessionId);
      }).catch(e => {
        logger.error(e, { section: 'shoppingControllerGetCurrentSession' })
        //go to homepage
        history.push(PATH_HOME)
      })
  }

  useEffect(() => {
    if (eventItems.length === 0) return
    addToCartFromEvent(eventItems[0])
    setEventItems(eventItems.slice(1))
    setOnGoing(false)
  }, [addToCartFromEvent, eventItems, onGoing])


  useEffect(() => {
    const onSession = (ev: BrokerMessage<SessionEvent>) => {
      console.log("MQTT event in Virtual Cart")
      if (ev.payload.status === ShoppingSessionStatus.IN_STORE) {
        getUserSession()
      } else if (ev.payload.status === ShoppingSessionStatus.PAYMENT_PENDING) {
        logger.info("Payment event received")
        //Check if virtual cart is empty or not. 
        getVirtualCartHandler(ev.payload.sessionId)
          .then((response: any) => {
            setShowPaymentButton(true)
          })
          .catch((e) => {
            logger.error(e, { ref: 'getVirtualCartHandler' })
          });
      }
    }

    const onCart = (ev: BrokerMessage<CartEvent>) => {
      setOnGoing(true)
      logger.debug("Cart event received")
      if (ev.payload.action === CartEventStatus.UPDATE) {
        setEventItems([...eventItems, ev.payload.product as VirtualCartItemDto])
      }
    }

    const onBrokerReconnect = () => {
      logger.info("Refreshing virtual cart")
      fetchVirtualCart(session)
    }

    getBroker()?.on("session", onSession)
    getBroker()?.on("cart", onCart)
    getBroker()?.on("reconnect", onBrokerReconnect)

    return () => {
      getBroker()?.off("session", onSession)
      getBroker()?.off("cart", onCart)
      getBroker()?.off("reconnect", onBrokerReconnect)
    }
  }, [eventItems])

  useEffect(() => {

    setItems([]);
    //addTestItems() // test
    //setSession('x') // test

    getUserSession()

  }, []);

  return (
    <ion-tabs data-testid="virtual-cart">
      {showPaymentButton ?
        <PaymentStatus
          data-testid="payment-status"
          isEmptyCart={items.length === 0}
          totalPrice={getTotalPrice()}
          currency={parseCurrency(items[0]?.currency)}
        />
        :
        <IonPage>
          <TopToolbar title="Shopping Cart"
            fetch={fetchVirtualCart}
            session={session}
            search={setShowSearchItemModal}
            virtualcart={virtualCartOpened} />
          <IonContent fullscreen>
            {virtualCartOpened && (
              <ItemsList
                selected={() => { }}
                items={items}
                addItem={addItemPopover}
                removeItem={removeItemPopover}
                showQuantity={true}
                showEmpty={true}
                selectedId={""}
                updating={updating}
              />
            )}
            {!virtualCartOpened && (
              <IonRow class="ion-justify-content-center ion-padding-top">
                <IonText color="medium" class="ion-padding ion-text-center">
                  <p>{t("The shopping cart is closed. Enter the shop to begin.")}</p>
                </IonText>
              </IonRow>
            )}

            <SearchItemModal
              showSearchItemModal={showSearchItemModal}
              addItem={addItem}
              hideModal={hideModal}
            />
          </IonContent>
          {items.length > 0 && (
            <div className="ion-padding-start list-footer">
              <IonGrid fixed={true}>
                <IonRow>
                  <IonCol className="subtotal-text" data-testid="subtotal">
                    {t("Subtotal")}:
                  </IonCol>
                  <IonCol className="subtotal-number">
                    Tot. {parseCurrency(items[0].currency)} {getTotalPrice()}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
          <TabBarContainer />
        </IonPage>
      }
    </ion-tabs>
  );
};

export default VirtualCart;
