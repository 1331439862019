import { APP, AppConfig, getAppConfig, setAppConfig } from "../config"
import { emitter, UserStoreOperation, UserStoreType } from "../helpers/userStore"
import { createLogger } from "../logger"

const logger = createLogger({component: 'config-loader'})

export interface ConfigLoaderOptions {
    emit: boolean
    filename: string
    force: boolean
}

const defaultOptions: ConfigLoaderOptions = {
    filename: 'config.json',
    emit: true,
    force: false
}

export const loadEnv = async <T extends AppConfig>(opts?: ConfigLoaderOptions): Promise<void> => {
    await loadConfig<T>(opts)

    const config = getAppConfig() as T
    logger.debug(`loaded config=${JSON.stringify(config)}`)
}

export const loadConfig = <T extends AppConfig>(opts?: ConfigLoaderOptions): Promise<T|null> => {
    
    opts = {...defaultOptions, ...(opts || {})}

    const config = getAppConfig()

    const emitEvent = (appcfg?: AppConfig): void => {
        if (!opts.emit) return
        appcfg = appcfg || getAppConfig()
        emitter.emit(UserStoreType.configLoader, UserStoreOperation.UPDATED, appcfg)
    }

    if (config.loaded !== undefined && !opts.force) {
        const appcfg = config as T
        emitEvent(appcfg)
        return Promise.resolve(appcfg)
    }

    if (typeof document === "undefined") return Promise.resolve(null)

    let appcfg: T|null = null

    const configPath = `${document.location.protocol}//${document.location.host}${APP.BASEURL}/${opts.filename}`
    return fetch(configPath)
        .then(response => response.json().catch((e) => {
            logger.debug(`${configPath} not found or invalid`)
            return Promise.resolve({})
        }))
        .then(data => {
            // cache loaded config
            appcfg = data as T
            setAppConfig(appcfg)
            emitEvent()
            return Promise.resolve(appcfg)
        })
        .catch(e => {
            logger.error(`Config load error: ${e.stack}`)
            return null
        })
        .finally(() => {
            setAppConfig({
                loaded: "1"
            } as AppConfig)
            emitEvent(appcfg)
        })
}