import React from 'react';
import { IonButton, IonRow, IonCol, IonGrid, IonFooter } from '@ionic/react';
import './Account.css'

interface AccountFooterProps {
    buttonLabel: string
    buttonClick: () => void
    disabled?: boolean
}

export function AccountFooter(props: AccountFooterProps) {
    return (
        <IonFooter className="account-footer" data-testid="footer-page">
            <IonGrid>
                <IonRow>
                    <IonCol size="12">
                        <IonButton
                            id="footer-button"
                            data-testid="footer-button"
                            type="submit"
                            fill="solid"
                            onClick={() => props.buttonClick()}
                            expand="block"
                            disabled={props.disabled}
                        >
                            {props.buttonLabel}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonFooter>
    )
}