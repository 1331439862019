
const LOCAL_BASEPATH = 'mimex.local'
const NATS_SUFFIX = '/nats'

export interface AppConfig extends Record<string, string> {
    loaded: string
    backendUrl: string
    brokerUrl: string
    natsUrl: string
    NAME:string,
    BASEURL:string,
    BACKEND_URL:string,
    MQTT_URI:string,
}

export interface CustomerAppConfig extends AppConfig {
    MAP_KEY:string,
    STRIPE_API_KEY:string,
    RECAPTCHA_KEY:string,
    PRIVACY_LINK: string,
    LOGO_URL: string,
    PAYMENT_SERVICE: string
}

export interface ManagerAppConfig extends AppConfig {
    INDOOR_CAMERA_ID: string
}

export interface TotemAppConfig extends AppConfig {
    BACKEND_INTERNAL_URL: string,
    STORE_ID: string,
    NATS_URL: string,
    TOTEM_LOGO_URL: string
}

const APPS = [
    "customer-app", "totem-app", "manager-app"
]

export const getAppName = () => {
    const matches = APPS.filter(p => document.location.pathname.indexOf(p) > -1)
    const appName = (matches && matches.length) ? matches[0] : process.env.REACT_APP_NAME
    if (!appName) throw new Error('APP_NAME is missing')
    return appName
}

export const getAppBaseUrl = () => {
    const appName = getAppName()
    return `/${appName}`
}

export interface AppContext {
    ENV: "localhost" | "dev" | "staging" | "prod"
    DEPLOYMENT: "dev" | "staging" | "prod-es" | "prod-tr" | string
    NAME: string
    BASEURL: string
    BACKEND_URL: string
    MQTT_URI: string
    BACKEND_INTERNAL_URL: string
    NATS_URL: string
}

export const getAppContext = (): AppContext => {
    const ctx = {
        NAME: getAppName(),
        BASEURL: getAppBaseUrl()
    } as AppContext

    ctx.ENV = 'prod'
    if (document.location.host.indexOf('localhost') > -1) {
        ctx.ENV = 'dev'
        ctx.DEPLOYMENT = 'localhost'
    }
    if (
        document.location.host.indexOf('mimex.local') > -1 ||
        document.location.host.indexOf('mimex.spindoxlabs.it') > -1
    ) {
        const parts = document.location.host.split('.')
        if (parts.length > 0) {
            switch(parts[0]) {
                case "dev":
                    ctx.ENV = "dev"
                    break
                case "staging":
                    ctx.ENV = "staging"
                    break
            }
        }
    }

    ctx.DEPLOYMENT = ctx.DEPLOYMENT || ctx.ENV
    if (ctx.ENV === "prod") {
        const parts = document.location.host.split('.')
        ctx.DEPLOYMENT = parts.length ? parts[0] : null
    }

    const isLocalhost = (ctx.DEPLOYMENT === "localhost")
    const host = isLocalhost ? 
        "172.17.0.1" // localhost development
        : document.location.host
    const isHttps = document.location.protocol === 'https:' ? 's' : ''

    ctx.BACKEND_URL=`${document.location.protocol}//${host}/api`
    ctx.MQTT_URI=`mqtt${isHttps}://${host}/ws`
    
    // internal pointers for the totem-app
    ctx.BACKEND_INTERNAL_URL=`${document.location.protocol}//${ctx.DEPLOYMENT ? ctx.DEPLOYMENT + '.' : ''}${LOCAL_BASEPATH}/api`
    ctx.NATS_URL=`ws${isHttps}://${ctx.DEPLOYMENT ? ctx.DEPLOYMENT + '.' : ''}${LOCAL_BASEPATH}${NATS_SUFFIX}`

    // localhost development
    if (isLocalhost) {
        ctx.BACKEND_INTERNAL_URL=`${document.location.protocol}//${host}:3000`
        ctx.NATS_URL=`ws${isHttps}://${host}:9222`
    }

    return ctx
}

export const APP = getAppContext()

const initConfig = (): AppConfig => {

    // extract config from process.env
    const processConfig = Object.keys(process.env)
        .filter(key => key.indexOf("REACT_APP") > -1)
        .reduce((conf, key) => ({...conf, [key.replace("REACT_APP_", "")]: process.env[key]}), {} as AppConfig)
    
    return {
        ...APP,
        ...processConfig
    }
}

let __config: AppConfig = initConfig()

export const getAppConfig = <T extends AppConfig>(): T => {
    return __config as T
}

export const setAppConfig = (cfg: AppConfig) : void => {
    Object.assign(__config, cfg)
}

export const getManagerAppConfig = () => getAppConfig<ManagerAppConfig>()
export const getCustomerAppConfig = () => getAppConfig<CustomerAppConfig>()
export const getTotemAppConfig = () => getAppConfig<TotemAppConfig>()
