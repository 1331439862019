import {
  IonCol, IonInput, IonItemDivider, IonItemGroup, IonLabel, IonRow
} from '@ionic/react';
import { BILLING_ADDRESS_FIELDS } from "mimex-libs/helpers/constants";
import { createLogger } from "mimex-libs/logger";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';

interface InputProps {
  fullName: string
  country: string
  city: string
  name: string
  phone: string
  surname: string
  taxCode: string
  zipCode: string
}
interface IPropsBillingDetails {
  inputs: InputProps | any
  setInputs: (value: any) => void
  addressRef: HTMLIonInputElement | any
  phoneRef: HTMLIonInputElement | any
  countryRef: HTMLIonInputElement | any
  cityRef: HTMLIonInputElement | any
  taxCodeRef: HTMLIonInputElement | any
  zipCodeRef: HTMLIonInputElement | any
  fullNameRef: HTMLIonInputElement | any
}

const BillingAddressInsert = (props: IPropsBillingDetails) => {
  const { t } = useTranslation(["translation"]);
  const logger = createLogger({ component: 'BillingAddressInsert' })

  const {
    inputs,
    setInputs,
  } = props

  useEffect(() => {
    setInputs((prevState: any) => {
      const state = {
        ...prevState,
      }
      if (inputs.name && inputs.surname) {
        state.fullName = `${inputs.name} ${inputs.surname}`
      }
      return state
    })

  }, [inputs.name, inputs.surname, setInputs])

  const handleInputChange = useCallback(
    (event: any) => {
      const name = event.target.name
      const value = event.target.value
      setInputs((prevState: any) => ({
        ...prevState,
        [name]: value,

      }))
      logger.debug(`user billing address updated`)
    },
    [setInputs, logger]
  )

  return (
    <IonItemGroup class="ion-margin" data-testid="billing-address-insert">
      <IonItemDivider>
        <IonLabel>{t("Add a billing address")}</IonLabel>
      </IonItemDivider>
      <IonRow>
        <IonCol>
          <IonLabel color="dark" className="ion-text-wrap">
          </IonLabel>
        </IonCol>
      </IonRow>
      {BILLING_ADDRESS_FIELDS.map(({
        id,
        dataTestId,
        placeholder,
        name,
        maxLength,
        value,
        autocomplete
        // ref
      }, index) => {
        return (
          <IonRow key={index + id}>
            <IonCol>
              <IonInput
                data-testid={dataTestId}
                type="text"
                autocomplete={autocomplete}
                placeholder={t(placeholder)}
                name={name}
                value={(inputs && inputs[value]) || ""}
                maxlength={maxLength}
                onIonInput={(e: any) => handleInputChange(e)}
              />
            </IonCol>
          </IonRow>
        )
      })}
    </IonItemGroup>

  )
}

export default BillingAddressInsert
