import React from 'react'
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'mimex-libs/helpers/constants';
import TabBarContainer from 'src/components/TabBarContainer'
import { AccountFooter } from '../pages/account/components/AccountFooter';

import './PageNotFound.css'

const PageNotFound: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(["translation"]);

  const onSubmit = () => {
    history.push('/')
  }

  return (
    <ion-tabs data-testid="page-not-found">
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("Page not found")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid  className="grid-spacing">
            <IonRow>
              <IonCol>
              </IonCol>
            </IonRow>
            <IonRow className="not-found-element">
              <IonCol>
                <IonImg
                data-testid="page-not-found-image"
                  src={`${BASE_URL}/assets/images/page_not_found.svg`} alt="MIMEX"
                />
              </IonCol>
            </IonRow>
             <IonRow className="not-found-element">
              <IonCol>
                <h3>{t("Not Found (404)")}</h3>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <AccountFooter buttonClick={onSubmit} buttonLabel={t("Back to home")} disabled={false} />
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}
export default PageNotFound

