import { IonPage } from "@ionic/react";
import { useLoadScript } from "@react-google-maps/api";
import { getStoresList } from "mimex-libs/api/endpoints";
import { getCustomerAppConfig } from "mimex-libs/config";
import { ERROR, WARNING } from "mimex-libs/helpers/constants";
import { createLogger } from "mimex-libs/logger";
import { StoreDto } from 'mimex-libs/sdk';
import React, { useEffect, useMemo, useState } from 'react';
import TopToolbar from "src/components/TopToolbar";
import TabBarContainer from "../components/TabBarContainer";
import useToast from '../CustomHooks/useToast';
import MapView from "./MapView";
import './StoresMap.css';
import { useTranslation } from 'react-i18next';

const StoresMap = () => {
  const { t } = useTranslation(["translation"]);

  const [storeList, setStoreList] = useState<StoreDto[]>()
  const logger = createLogger({ component: 'UserAccount' })
  const [renderToast] = useToast()
  const config = useMemo(() => getCustomerAppConfig(), [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.MAP_KEY || ''
  });

  useEffect(() => {
    if (storeList !== undefined) return
    getStoresList().then((stores: StoreDto[]) => {

      setStoreList(stores)

      if (stores.length === 0) {
        logger.debug(`Store list is empty`)
        renderToast(WARNING, t('Store list is empty'))
      }

      logger.debug(`${stores.length} store retrieved`)
    })
      .catch((e: any) => {
        logger.error(`Error in retrieving store list: ${e.message}`,
          { ref: 'getUserReceipts' })
        renderToast(ERROR, t('Failed to plot stores'))
      })
  }, [logger, renderToast, storeList])


  return (
    <ion-tabs data-testid="stores-map">
      <IonPage>
        <TopToolbar title="Stores" />
        {isLoaded ? <MapView storeList={storeList || []} /> : null}
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}

export default StoresMap
