
import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow } from '@ionic/react';
import { capitalize } from 'lodash';
import { PATH_BILLING_ADDRESS, PATH_PAYMENT_METHODS } from 'mimex-libs/helpers/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TabBarContainer from 'src/components/TabBarContainer';
import TopToolbar from '../components/TopToolbar';
import './PaymentPage.css';

interface IPaymentList {
  id?: string;
  title: string;
  path: string;
  props: string;
  disabled: boolean
}
const paymentList = [
  {
    "id": "0",
    "dataTestId": "payment-method",
    "title": "Payment method",
    "path": PATH_PAYMENT_METHODS,
    "props": "account props",
    "disabled": false
  },
  {
    "id": "1",
    "dataTestId": "invoice-information",
    "title": "Invoice Information",
    "path": PATH_BILLING_ADDRESS,
    "props": "payment props",
    "disabled": false
  },
]



const PaymentPage = () => {
  const history = useHistory()
  const { t } = useTranslation(["translation"]);

  const onRowClick = (paymentElement: IPaymentList) => {
    switch (paymentElement.title) {
      case 'Payment method':
        return history.push(paymentElement.path);
      case 'Invoice Information':
        return history.push(paymentElement.path);
      default:
        return ''
    }

  }

  return (
    <ion-tabs data-testid="payment-page">
      <IonPage>
        <TopToolbar title="Payment"> </TopToolbar>
        <IonContent className="ion-padding parent-container">
          <IonGrid className="grid-container">
            <IonRow>
              <IonCol className="payment-list">
                <IonContent>
                  <IonList>
                    {paymentList.map(payment => (
                      <IonItem data-testid={payment.dataTestId} disabled={payment.disabled} className="title-layout" key={payment.title} onClick={() => onRowClick(payment)} button>
                        <IonLabel>
                          <h2>{t(capitalize(payment.title))}</h2>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonContent>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}

export default PaymentPage
