import React, { useEffect } from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { createLogger } from 'mimex-libs/logger';
import useToast from '../../../CustomHooks/useToast';
import { useHistory } from 'react-router-dom';
import { BASE_URL, PATH_RECEIPTS, WARNING } from 'mimex-libs/helpers/constants';
import { AccountFooter } from '../../account/components/AccountFooter';

import "./UserDisabled.css"

const logger = createLogger({ component: 'UserDisabled' })

type Item = {
    src: string;
    text: string;
};
const item: Item = { src: `${BASE_URL}/assets/images/credit_card_payments.svg`, text: '' };

export function UserDisabled(props: any) {
    const { t } = useTranslation(["translation"]);
    const [renderToast] = useToast()
    const history = useHistory()

    const onRowClick = () => {
        return history.push(PATH_RECEIPTS)
    }

    useEffect(() => {
        renderToast(WARNING, t('You have pending payment. Please go to receipts page to pay'))
        logger.debug(`User has pending payment`)
    }, [renderToast])

    return (
        <>
            <IonContent data-testid="user-disabled">
                <IonGrid>
                    <IonRow class="vs">
                        <IonCol size={"1"}>
                        </IonCol>
                        <IonCol size={"10"}>
                            <IonImg data-testid="user-disabled-img" src={item.src} className="need-payment-img" />
                        </IonCol>
                        <IonCol size={"1"}>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <AccountFooter buttonClick={onRowClick} buttonLabel={t('Go to Receipts')} disabled={false} />
        </>
    )
}
