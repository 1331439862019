import React from 'react'
import { IonButton, IonCardContent, IonCol, IonIcon, IonItem, IonLabel, IonList, IonThumbnail } from '@ionic/react'
import { PaymentMethod } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from "mimex-libs/helpers/constants";
import { trash } from 'ionicons/icons';

import './BKMCardList.css'

interface BKMCardListProp {
    paymentMethods: PaymentMethod[]
    deletePaymentMethod: CallableFunction
}

const BKMCardList = (props: BKMCardListProp) => {
    const { t } = useTranslation(['translation'])

    const deletePaymentMethod = (id: string) => {
        props.deletePaymentMethod(id)
    }
    return (
        <IonCardContent className="bkm-list">
            <IonList lines="none">
                {props.paymentMethods &&
                    props.paymentMethods.map((paymentMethod: PaymentMethod) => {
                        return (
                            <IonItem key={paymentMethod.id}>
                                <IonThumbnail slot="start">
                                    <img alt="BKM Express" src={`${BASE_URL}/assets/images/bkm-logo.png`} />
                                </IonThumbnail>
                                <IonCol>
                                    <IonLabel>BKM Express</IonLabel>
                                    <IonLabel>{paymentMethod.card ? paymentMethod.card.last4 : ''}</IonLabel>
                                </IonCol>
                                <IonButton slot="end" fill="clear" shape="round" onClick={() => deletePaymentMethod(paymentMethod.id)}>
                                    <IonIcon slot="icon-only" icon={trash} />
                                </IonButton>
                            </IonItem>
                        )
                    })}
            </IonList>
        </IonCardContent>
    )
}

export default BKMCardList
