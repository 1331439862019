import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonLoading, IonPage, IonRow, IonTitle } from '@ionic/react';
import { alertCircleOutline, chevronForward } from 'ionicons/icons';
import { getUserReceipts, getUserReceiptsSummary } from 'mimex-libs/api/endpoints';
import { MonthsList, PATH_RECEIPTS } from 'mimex-libs/helpers/constants';
import { formatTotalPrice, parseCurrency } from 'mimex-libs/helpers/currency';
import { createLogger } from "mimex-libs/logger";
import { ReceiptDto, ReceiptSummaryDto, UserSessionFindDto } from 'mimex-libs/sdk';
import moment from 'moment';
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import TabBarContainer from 'src/components/TabBarContainer';
import TopToolbar from '../components/TopToolbar';
import { ReceiptSummary } from './dto/receipts';
import './Receipts.css';
import { ReceiptSelection } from './ReceiptSelection';
import ReceiptsNotFound from './ReceiptsNotFound';

const summaryDefaultItem = {
  count: 0,
  month: "-1",
  label: MonthsList["-1"],
  year: "",
} as ReceiptSummary

const logger = createLogger({ component: 'Receipts' })

const Receipts: React.FC = () => {

  const { t } = useTranslation(["translation"]);
  const history = useHistory()

  const [showLoading, setShowLoading] = useState(false)

  const [receiptSessions, setReceiptSessions] = useState<ReceiptDto[]>()

  const [months, setMonths] = useState<ReceiptSummary[]>();
  const [selectedMonth, setSelectedMonth] = useState<ReceiptSummary>(summaryDefaultItem);

  const formatDate = (date: string) => {
    const [sessionWeek, sessionMonth, sessionDay] = new Date(date).toDateString().split(" ")
    return `${sessionWeek}, ${sessionDay} ${sessionMonth}`
  }

  useEffect(() => {
    if (months !== undefined) return
    setShowLoading(true)
    getUserReceiptsSummary({}).then((summary: ReceiptSummaryDto[]) => {
      logger.debug(`Retrieved summary`)
      const list = [
        summaryDefaultItem,
        ...summary.map(s => ({
          ...s,
          label: MonthsList[s.month]
        }))
      ]
      setMonths(list)
    }).catch((e: any) => {
      logger.error(`Error in retrieving summary: ${e.stack}`, { ref: 'getUserReceiptsSummary' })
    }).finally(() => setShowLoading(false))
  }, [months])

  useEffect(() => {
    if (months === undefined) return
    if (receiptSessions !== undefined) return

    setShowLoading(true)

    const qs: UserSessionFindDto = {}
    if (selectedMonth.month === "-1") {
      qs.limit = 5
    } else {
      const m = moment(new Date(+selectedMonth.year, +selectedMonth.month))
      qs.from = m.startOf('month').toISOString()
      qs.to = m.endOf('month').toISOString()
      qs.limit = -1 // all
    }

    getUserReceipts(qs).then((receipts: ReceiptDto[]) => {
      logger.debug(`Retrieved ${receipts.length} receipts`)
      setReceiptSessions(receipts)
    }).catch((e: any) => {
      logger.error(`Error in retrieving receipt session: ${e.stack}`, { ref: 'getUserReceipts' })
    }).finally(() => setShowLoading(false))
  }, [months, receiptSessions, selectedMonth.month, selectedMonth.year, setReceiptSessions])

  const onMonthSelection = (e: ReceiptSummary) => {
    setSelectedMonth(e)
    setReceiptSessions(undefined)
  }

  const onRowClick = (receipt: ReceiptDto) => {
    history.push(`${PATH_RECEIPTS}/${receipt.sessionId}`)
  }

  return (
    <ion-tabs data-testid="receipts-page">
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('Loading')}
      />
      <IonPage>
        <IonContent>
          <TopToolbar data-testid="top-toolbar" />
          <div className="session-content">
            <IonGrid>
              <IonRow className="session-layout">
                <IonCol>
                  <IonTitle size="large" className="receipts-title">
                    {t("Receipts")}
                  </IonTitle>
                </IonCol>
              </IonRow>
              <IonRow className="session-layout">
                <IonCol>
                  <ReceiptSelection
                    data-testid="receipt-selection"
                    Months={months || [summaryDefaultItem]}
                    onMonthSelection={onMonthSelection}
                    selectedMonth={selectedMonth}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="session-period">
                <IonCol size='auto'>
                  {t(selectedMonth.label) || ""} {selectedMonth.year || ""}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="session-list">
                  <IonList>
                    {!receiptSessions || !receiptSessions.length ? <ReceiptsNotFound /> :
                      receiptSessions.map((receiptItem: ReceiptDto, index: number) => (
                        <Link to={`${PATH_RECEIPTS}/${receiptItem.sessionId}`} key={index} style={{ textDecoration: 'none' }}>
                          <IonItem
                            key={index}
                            lines="none"
                            disabled={false}
                            id="receipt-button"
                            className="list-layout"

                            onClick={() => onRowClick(receiptItem)}
                            button
                          >
                            <IonGrid>
                              <IonRow>
                                <IonCol size="auto" className="product-date">
                                  {receiptItem.createdAt ? formatDate(receiptItem.createdAt.toString()) : ''}
                                </IonCol>
                                <IonCol></IonCol>
                                <IonCol className="product-amount">
                                  {`${parseCurrency(receiptItem.payment.currency)}${formatTotalPrice(receiptItem.payment.totalPrice)}`}
                                </IonCol>
                                <IonCol className="product-payment">
                                  {receiptItem.payment.paymentStatus === "pending" ?
                                    <div className="payment-required-container">
                                      <IonIcon color="danger" className="icon-layout-pending" icon={alertCircleOutline}></IonIcon>
                                      <span className="payment-required">
                                        {t("Payment Required")}
                                      </span>
                                    </div>
                                    : ''}
                                </IonCol>
                                <IonCol className="">
                                  <IonIcon className="ion-float-end icon-layout" icon={chevronForward} item-right />
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonItem>
                        </Link>
                      ))}
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
        <TabBarContainer />
      </IonPage>
    </ion-tabs>


  )
}

export default Receipts
