import React, { useEffect, useRef, useState } from 'react'
import { IonContent, IonPage, IonSlides } from '@ionic/react'
import { isEmpty } from 'lodash'
import { slideContent } from 'mimex-libs/helpers/constants'
import OnBoardingSlide from './components/OnBoardingSlide'
import { useTranslation } from 'react-i18next'

import './OnBoarding.css'

export const OnBoarding: React.FC = () => {

    const sliderRef = useRef<any>(null)
    const [lastSlide, setLastSlide] = useState(false)
    const [firstSlide, setFirstSlide] = useState(true)
    const { i18n } = useTranslation(['translation'])

    useEffect(() => {
        i18n.changeLanguage(navigator.language)
    }, [])

    const checkSlides = async () => {
        if (
            isEmpty(sliderRef.current.isEnd()) ||
            isEmpty(sliderRef.current.isBeginning())
        )
            return

        const isLastSlide = await sliderRef.current.isEnd()
        const isFirstSlide = await sliderRef.current.isBeginning()
        setLastSlide(isLastSlide)
        setFirstSlide(isFirstSlide)
    }

    return (
        <IonPage data-testid="on-boarding" id="onboarding">
            <IonContent fullscreen>
                <IonSlides
                    data-testid="slider"
                    onIonSlideWillChange={checkSlides}
                    pager={true}
                    ref={sliderRef}
                    id="slider"
                    options={{
                        slidesPerView: 'auto',
                        zoom: true,
                        grabCursor: true,
                    }}
                >
                    {slideContent.map((slide, index) => {
                        return (
                            <OnBoardingSlide
                                key={index}
                                {...slide}
                                firstSlide={firstSlide}
                                lastSlide={lastSlide}
                                sliderRef={sliderRef}
                            />
                        )
                    })}
                </IonSlides>
            </IonContent>
        </IonPage>
    )
}
