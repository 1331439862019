import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonItem, IonList,
    IonPage,
    IonRow,
    IonTitle, IonToolbar
} from '@ionic/react'
import { addOutline, removeOutline } from "ionicons/icons"
import { getCustomerAppConfig } from 'mimex-libs/config'
import { HELP_LIST } from 'mimex-libs/helpers/constants'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TabBarContainer from 'src/components/TabBarContainer'
import TopToolbar from '../../components/TopToolbar'
import './HelpAndSupport.css'

export default function HelpAndSupport() {
    const config = useMemo(() => getCustomerAppConfig(), [])
    const { t } = useTranslation(['translation'])

    const [items, setItems] = useState(HELP_LIST)

    const toggleGroup = (group: any) => {
        group.show = !group.show;

        // setItems(items.filter(e=> e.id !== group.id ))
        let newArr = [...items];
        const index = group.id - 1
        newArr[index] = {
            id: group.id,
            transI: group.transI,
            transN: group.transN,
            name: group.name,
            //item: group.item,
            show: group.show
        }
        setItems(newArr);
    }

    const isGroupShown = (group: any) => {
        return group.show;
    };

    const fillText = (str: any) => {
        const text = t(str)
        return text
            .split(" ")
            .map(part => {
                if (/privacy_policy/.test(part)) {
                    return <a href={config.PRIVACY_LINK || ""}>{t('Privacy policy')}</a>
                }
                if (/terms_and_conditions/.test(part)) {
                    return <a href={config.PRIVACY_LINK || ""}>{" " + t('Terms and conditions')}</a>
                }
                return part + " "
            }
            );
    };

    useEffect(() => {
    }, [])

    return (
        <IonPage data-testid="help-component">
            <TopToolbar title="Help and Support" />
            <IonContent fullscreen data-testid="help-header">
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">
                            {t("Help and Support")}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-align-self-start">
                            <IonList data-testid="toggle-group">
                                {items.map((g, k) => {
                                    return (
                                        <Fragment key={k}>
                                            <IonItem key={k} button onClick={() => toggleGroup(g)}
                                                class="item-stable"
                                            >
                                                {g.show ? <IonIcon class="ion-margin" icon={removeOutline}></IonIcon> : <IonIcon class="ion-margin" icon={addOutline}></IonIcon>}
                                                {t(g.transN)}
                                            </IonItem>
                                            <IonItem class="item-accordion ion-padding" className={isGroupShown(g) ? 'show' : 'hide'}>
                                                {fillText(g.transI)}
                                            </IonItem>
                                        </Fragment>)
                                })}
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
            <TabBarContainer />
        </IonPage>
    )
}


