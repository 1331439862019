import React from 'react'
 import {IonHeader, IonContent, IonToolbar, IonTitle} from '@ionic/react';
 import { useTranslation } from 'react-i18next';
 import {gdprText} from './gdprAndTerms'

 
 const GDPRModal = () => {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <IonHeader translucent data-testid="gdpr-page">
        <IonToolbar>
          <IonTitle>{t("GDPR")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {t(gdprText)}
        </IonContent>
    </>
  )
}

export default GDPRModal