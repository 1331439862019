import { IonCheckbox, IonCol, IonContent, IonGrid, IonInput, IonItem, IonItemGroup, IonLabel, IonRow, IonTitle } from '@ionic/react';
import { values } from "lodash";
import { getCurrentUserInfo, userUpdateInfoInInvoice } from 'mimex-libs/api/endpoints';
import { ERROR, MAX_INPUT_LENGTH, PATH_PAYMENT, SUCCESS } from "mimex-libs/helpers/constants";
import { getUserId } from 'mimex-libs/helpers/userStore';
import { createLogger } from "mimex-libs/logger";
import { UserProfileDto, UserProfileInvoiceDto } from 'mimex-libs/sdk';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useToast from '../CustomHooks/useToast';
import { AccountFooter } from './account/components/AccountFooter';
import { AccountHeader } from './account/components/AccountHeader';
import './BillingAddress.css';

const BillingAddressUpdate = () => {
  const { t } = useTranslation(["translation"]);
  const logger = createLogger({ component: 'BillingAddressUpdate' })
  const [renderToast] = useToast()
  const [showFields, setShowFields] = useState(false)
  const [userDetails, setUserDetails] = useState<UserProfileDto>()
  const history = useHistory()

  useEffect(() => {
    if (userDetails) return
    const userId = getUserId()
    if (!userId) return
    getCurrentUserInfo(userId).then((user: UserProfileDto) => {
      if (!user) return
      setUserDetails(user)
      setShowFields(user.invoice) //Show or hide fields
      logger.debug(`user info retrieved`)
    })
      .catch((e: any) => {
        logger.error(`Error in retrieving info about the user: ${e.message}`, { ref: 'getCurrentUserInfo' })
      })
  }, [logger, setUserDetails, userDetails])

  //List checkbox form inside billingAddress section
  const checkboxBillingAddressFields = useMemo(() => (["invoice"]), [])

  const billingAddressFields = [
    {
      id: "fullName",
      dataTestId: "fullName",
      type: "text",
      autocomplete: "on",
      placeholder: t("Full name"),
      name: "fullName",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.fullName,
    },
    {
      id: "address",
      dataTestId: "address",
      type: "text",
      autocomplete: "on",
      placeholder: t("Address"),
      name: "address",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.address,
    },
    {
      id: "phone",
      dataTestId: "phone",
      type: "text",
      autocomplete: "on",
      placeholder: t("Phone"),
      name: "phone",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.phone,
    },
    {
      id: "country",
      dataTestId: "country",
      type: "text",
      autocomplete: "on",
      placeholder: t("Country"),
      name: "country",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.country,
    },
    {
      id: "city",
      dataTestId: "city",
      type: "text",
      autocomplete: "on",
      placeholder: t("City"),
      name: "city",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.city,
    },
    {
      id: "taxCode",
      dataTestId: "taxCode",
      type: "text",
      autocomplete: "on",
      placeholder: t("Tax code"),
      name: "taxCode",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.taxCode,
    },
    {
      id: "zipCode",
      dataTestId: "zipCode",
      type: "text",
      autocomplete: "on",
      placeholder: t("zip code"),
      name: "zipCode",
      maxlength: MAX_INPUT_LENGTH,
      value: userDetails?.zipCode,
    }
  ]

  const toggleButton = () => {
    if (!showFields) return false //skip control
    if (values(userDetails).some(e => e === "")) {
      return true
    }
  }

  const onBillingAddressUpdate = () => {

    const userProfileInvoice = {
      fullName: userDetails?.fullName,
      address: userDetails?.address,
      country: userDetails?.country,
      city: userDetails?.city,
      zipCode: userDetails?.zipCode,
      taxCode: userDetails?.taxCode,
      invoice: userDetails?.invoice
    } as UserProfileInvoiceDto


    //check fields
    const userId = getUserId()
    if (!userId) return

    userUpdateInfoInInvoice(userProfileInvoice)
      .then((user: UserProfileDto) => {
        if (!user) return
        setUserDetails(user)
        logger.debug(`user profile info update`)
        renderToast(SUCCESS, t('Invoice informations updated'))
        history.push(PATH_PAYMENT)
      })
      .catch((e: any) => {
        renderToast(ERROR, e.response.data.error)
        logger.error(`Error in saving new invoice info about the user: ${e.message}`, { ref: 'userUpdateInfoInInvoice' })
      })
  }

  const handleInputChange = useCallback(
    (event: any) => {
      const name = event?.target?.name
      if (!name) return
      const value: any = checkboxBillingAddressFields.includes(name) ? event.target.checked : event.target.value

      setUserDetails((prevState: any) => ({
        ...prevState,
        [name]: value,

      }))
    },
    [checkboxBillingAddressFields]
  )

  return (
    <IonContent className="ion-padding parent-container" data-testid="billing-address-update">
      <AccountHeader />
      <IonTitle size="large" className="billing-title">
        {t("Invoice Information")}
      </IonTitle>
      <IonGrid className="grid-container">
        <IonRow className="billing-fields">
          <IonCol>
            <IonContent>
              <IonItemGroup>
                <IonItem className="invoice-class">
                  <IonLabel>{t("Do you need invoices?")}</IonLabel>
                  <IonCheckbox data-testid="billing-address-update-checkbox" name="invoice" checked={userDetails?.invoice} onIonChange={(e: any) => {
                    handleInputChange(e)
                    setShowFields(e?.target?.checked)
                  }
                  } />
                </IonItem>
                {showFields && (
                  billingAddressFields.map(({
                    id,
                    dataTestId,
                    placeholder,
                    name,
                    maxlength,
                    value
                  }, index) => {
                    return (
                      <IonInput
                        key={id}
                        type="text"
                        autocomplete="on"
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        maxlength={maxlength}
                        onIonInput={(e: any) => handleInputChange(e)}
                      />
                    )
                  })
                )}
              </IonItemGroup>
            </IonContent>
            <AccountFooter data-testid="billing-address-footer" buttonClick={onBillingAddressUpdate} buttonLabel={t('Set as default address')} disabled={toggleButton()} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>

  )
}

export default BillingAddressUpdate
