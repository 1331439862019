import { SegmentChangeEventDetail } from "@ionic/core";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PATH_LOGIN, PATH_REGISTRATION } from "mimex-libs/helpers/constants";
import { createLogger } from "mimex-libs/logger";

interface AccountSwitchProps {
    value: string
    onSwitchChange: (value: string) => void
}

const logger = createLogger({ component: 'AccountSwitch' })

export function AccountSwitch(props: AccountSwitchProps) {

    const { t } = useTranslation(["translation"]);
    const [currentValue, setCurrentValue] = useState(PATH_LOGIN)

    const onSwitchClick = useCallback((e: CustomEvent<SegmentChangeEventDetail>) => {
        // TODO: if not a problem below code could be removed
        // e.stopImmediatePropagation()
        logger.debug(`Selected ${e.detail.value}`)
        setCurrentValue(e.detail.value || PATH_LOGIN)
        props.onSwitchChange(e.detail.value || PATH_LOGIN)
    }, [props])

    useEffect(() => {
        setCurrentValue(props.value)
    }, [setCurrentValue, props.value])

    return (
        <IonSegment
            data-testid="account-switch"
            value={currentValue}
            onIonChange={onSwitchClick}
        >
            <IonSegmentButton value={PATH_LOGIN} data-testid="account-login">
                <IonLabel>{t("LOGIN")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={PATH_REGISTRATION} data-testid="account-registration">
                <IonLabel>{t("REGISTER")}</IonLabel>
            </IonSegmentButton>
        </IonSegment>
    )

}
