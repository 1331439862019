import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TabBarContainer from 'src/components/TabBarContainer';
import { BASE_URL, PROFILE_LIST } from 'mimex-libs/helpers/constants';
import { getUserId } from 'mimex-libs/helpers/userStore';
import { createLogger } from "mimex-libs/logger";
import { UserProfileDto } from 'mimex-libs/sdk';
import { getCurrentUserInfo } from 'mimex-libs/api/endpoints';

import './UserAccount.css';
interface IProfileList {
  id?: string;
  title: string;
  path: string;
  props: string;
  disabled: boolean
}

const logger = createLogger({ component: 'Dashboard' })

const UserAccount: React.FC = () => {

  const history = useHistory()
  const { t } = useTranslation(["translation"]);

  const [fullName, setFullName] = useState("")

  const onRowClick = (profileElement: IProfileList) => {
    switch (profileElement.title) {
      case 'account':
        return history.push(profileElement.path);
      case 'payment':
        return history.push(profileElement.path);
      case 'settings':
        return history.push(profileElement.path);
      case 'Help and Support':
        return history.push(profileElement.path);
      case 'logout':
        return history.push(profileElement.path);
      default:
        return ''
    }

  }

  useEffect(() => {
    const userId = getUserId()
    if (!userId) return
    getCurrentUserInfo(userId).then((user: UserProfileDto) => {
      setFullName(`${user.name} ${user.surname}`)
      logger.debug(`user info retrieved`)
    })
      .catch((e) => {
        logger.error(`Error in retrieving info about the user: ${e.message}`, { ref: 'getCurrentUserInfo' })
      })
  }, [])

  return (
    <ion-tabs data-testid="user-account">
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t("User account")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding parent-container">
          <IonGrid className="grid-container">
            <IonRow>
              <IonCol className="profile-title">
                <IonText>
                  <img
                    data-testid="user-account-avatar"
                    src={`${BASE_URL}/assets/images/user.png`} alt="avatar"
                    className="profile-logo"
                  />
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="profile-title">
                <IonText data-testid="user-account-name">
                  <h1>{capitalize(fullName)}</h1>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="profile-list">
                <IonContent>
                  <IonList>
                    {PROFILE_LIST.map(profile => (
                      <IonItem
                        data-testid="user-account-button"
                        disabled={profile.disabled}
                        className="title-layout"
                        key={profile.title}
                        onClick={() => onRowClick(profile)}
                        button
                      >
                        <IonLabel>
                          {t(capitalize(profile.title))}
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonContent>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}

export default UserAccount


