import React from 'react'
 import {IonHeader, IonContent, IonToolbar, IonTitle} from '@ionic/react';
 import { useTranslation } from 'react-i18next';
import {termsText} from './gdprAndTerms'
const TermsAndConditionsModal = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
       <IonHeader translucent data-testid="terms-conditions">
         <IonToolbar>
           <IonTitle>{t("Terms and Conditions")}</IonTitle>
         </IonToolbar>
      </IonHeader>
       <IonContent class="ion-padding" data-testid="terms-text">
         {t(termsText)}
      </IonContent>
    </>
  )
}

export default TermsAndConditionsModal












// import React from 'react';
// import {IonHeader, IonContent, IonToolbar, IonTitle} from '@ionic/react';

// class TermsAndConditionsModal extends React.Component {

//   render() {
//     return <>
//       <IonHeader translucent>
//         <IonToolbar>
//           <IonTitle>Terms and Conditions</IonTitle>
//         </IonToolbar>
//       </IonHeader>
//       <IonContent class="ion-padding">
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas finibus ante sit amet nulla rutrum interdum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In non pulvinar arcu, ut porttitor nunc. Curabitur sit amet enim quis tellus tristique volutpat in in quam. Phasellus eu consectetur lacus, eu gravida metus. Maecenas et mauris ac lacus interdum mollis. In a nulla ac leo commodo congue a egestas mi. Sed id mollis nisi. Fusce commodo eu urna id vestibulum. Cras nec euismod sem. Nunc in tincidunt tortor. Sed consectetur leo ac blandit consequat.

//         Mauris fringilla, odio in imperdiet dapibus, lorem lacus auctor nulla, quis sagittis eros risus at lorem. Ut gravida, massa at fringilla tincidunt, lectus nibh auctor libero, ac venenatis quam mauris ac neque. Sed id pellentesque risus. Proin volutpat, risus non tempor rutrum, mauris turpis accumsan mi, quis vestibulum ante purus scelerisque ex. Nullam elementum aliquet ultrices. Proin vel neque et enim volutpat imperdiet. Nullam tempus lorem luctus consequat faucibus. Vestibulum vel aliquet odio, quis tristique felis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque nibh tortor, molestie ut quam et, vehicula ultricies felis. Mauris vitae laoreet dolor. Duis vitae fermentum diam.

//         Duis ipsum libero, maximus sit amet nulla ac, tristique luctus turpis. In in nulla a mauris condimentum dictum ac cursus elit. Praesent consectetur ornare quam sit amet ultrices. Nulla ac justo ultricies, congue lectus nec, pretium lorem. Ut scelerisque accumsan lacus, non efficitur mi laoreet ac. Sed porta, turpis ac pharetra feugiat, nibh justo semper dolor, quis fringilla massa urna in ligula. Praesent vel quam id sem scelerisque feugiat quis non turpis. Mauris ipsum neque, pharetra quis augue quis, dapibus bibendum ante. Morbi semper sapien at ligula condimentum, eu aliquam nibh eleifend. Praesent pulvinar venenatis dolor sed viverra. Quisque et scelerisque magna, eu sollicitudin sem. Praesent ipsum justo, efficitur in viverra vel, dapibus vitae justo. Curabitur pretium tempus erat quis maximus. Donec non diam in ante placerat sollicitudin vitae in nisl.

//         Mauris in ultrices enim. Etiam blandit sem vel orci accumsan, non interdum tortor venenatis. In lobortis iaculis neque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce placerat nulla ut nisi vehicula, ac consequat arcu consequat. Pellentesque eget sodales nisi. In hac habitasse platea dictumst. Etiam lacinia sagittis rutrum. Sed tellus dolor, malesuada ac cursus ac, condimentum vitae ex. Mauris blandit ante dolor, a sollicitudin nisi laoreet in. Sed venenatis elit sed mauris dignissim, et faucibus diam facilisis. Sed posuere urna sapien, sit amet lacinia elit vehicula non. Proin scelerisque eu sapien sit amet luctus.

//         Ut gravida ante quam, id luctus risus accumsan ac. Aliquam in ornare mauris. Nunc blandit tempor ex et iaculis. Integer tincidunt cursus odio, in dignissim orci placerat eu. Vestibulum tortor urna, aliquam ac sodales vel, semper eu libero. Sed sed rhoncus urna, sed semper enim. Sed pellentesque sagittis purus, sit amet faucibus nulla condimentum nec. Vivamus vehicula nunc rutrum, vulputate neque a, rhoncus ligula. Duis mattis interdum pretium. Etiam ac interdum augue, sed ornare nunc. In iaculis luctus imperdiet. Donec ornare ac sapien id vestibulum.

//         Integer dolor nisl, ornare ac purus non, feugiat pulvinar lacus. Duis urna est, viverra vel ornare id, luctus a tortor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce pellentesque congue sem. Vestibulum sodales lobortis efficitur. Aenean consectetur eget velit non eleifend. Vestibulum ullamcorper consectetur odio, auctor accumsan leo volutpat non. Nullam eget augue leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur quis iaculis felis, quis tristique ante. Morbi id sem pretium, vestibulum enim id, vestibulum orci. Integer consequat auctor enim, at fringilla erat placerat in. Suspendisse potenti. Etiam eget aliquam mauris.

//         Pellentesque vehicula tellus vel tellus vulputate molestie. Aenean non pretium mi. Duis ac magna ac nibh tincidunt pretium. Pellentesque metus odio, rutrum in faucibus ac, blandit sit amet urna. Vestibulum et mauris id lorem porta bibendum eu in lacus. Donec faucibus dui commodo massa luctus, fermentum iaculis nisi volutpat. Maecenas ornare nunc quis est porta, ac pharetra diam elementum. Mauris vitae ultrices est, ut blandit est.

//         Sed vehicula lacus at nulla placerat, in consequat est sodales. Aenean rhoncus, quam sit amet ultricies suscipit, ex mi auctor urna, sit amet consequat elit lacus id elit. Sed sit amet sagittis lacus. Morbi ut lacus dui. Maecenas congue elementum nulla, vitae congue ligula ullamcorper ut. Aliquam nisi nunc, vestibulum sed consectetur at, aliquam vitae orci. Etiam ac orci in leo blandit posuere eu vel erat. Suspendisse aliquet mi ac posuere ultricies. Aenean interdum maximus mi, non tristique elit rhoncus vel. Proin sit amet arcu tempus, tempor justo sit amet, pretium sapien. Nulla non ultrices dolor, vitae tristique arcu.

//         Vestibulum sed consequat nisl. Morbi ultrices, risus eget gravida iaculis, justo nisi tempus lorem, sed laoreet risus leo id neque. Nullam maximus enim non ultricies sodales. Vivamus luctus, sem sed pulvinar rhoncus, velit odio interdum dolor, finibus euismod orci lacus eget neque. Donec at metus ut mi consectetur semper. Cras a orci in metus aliquet posuere. Proin non risus ac turpis bibendum mollis id ac velit. Sed malesuada laoreet enim, a iaculis ipsum maximus non. Proin elementum nisi sed purus mollis viverra. Quisque ultricies aliquet vulputate. Fusce arcu diam, condimentum a rhoncus sit amet, elementum in lacus.

//         In augue ex, iaculis vitae neque in, facilisis dignissim lectus. Cras eget massa magna. Duis tincidunt consequat suscipit. Cras tempus iaculis molestie. Praesent ac porttitor mauris, nec facilisis ante. Fusce non elementum lorem. Sed fermentum diam tellus, non eleifend augue blandit vitae. Curabitur sit amet metus ultrices, condimentum dolor ut, bibendum ipsum. Sed vel metus quis erat finibus sodales non sed sapien. Donec vitae luctus massa. Sed quis vehicula eros. Maecenas aliquet ante magna, sed fringilla nisl dignissim nec. Donec turpis libero, iaculis in nunc ac, lobortis posuere mi.
//         </IonContent>
//     </>
//   }

// }

// export default TermsAndConditionsModal;