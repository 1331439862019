import React, { lazy, Suspense, useState } from 'react';
import {
    IonButton, IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonList,
    IonModal, IonPage, IonRow, IonTitle, IonToolbar
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useToast from '../../CustomHooks/useToast';
import { ERROR, GENERIC_ERROR, PATH_LOGIN, WARNING } from "mimex-libs/helpers/constants";
import { resetPasswordHandler } from 'mimex-libs/api/endpoints';
import { createLogger } from 'mimex-libs/logger';
import { AccountHeader } from './components/AccountHeader';
import { AccountFooter } from './components/AccountFooter';

import '../../styles/global.css';
import './PasswordReset.css';

const PasswordResetModal = lazy(() => import('./modals/PasswordResetModal'))

const logger = createLogger({ component: 'PasswordReset' })

export default function PasswordReset() {

    const { t } = useTranslation(["translation"]);

    const [username, setUsername] = useState('')
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const [renderToast] = useToast()

    const handleUsernameInput = (e: any) => {
        setUsername(e.target.value)
    }

    const toggleButton = () => {
        return !username
    }

    const submit = async () => {
        try {
            await resetPasswordHandler(username)
            // setShowModal(true)
            dismissModal()
            logger.info('Sent reset password')
            renderToast(WARNING, "Sent reset password")
        } catch (e: any) {
            logger.error(`Failed to send reset email: ${e.message}`, e)
            if (e.response && e.response.data && e.response.data.error) {
                setError(e.response.data.error.message)
                renderToast(ERROR, e.response.data.error.message)
            } else {
                setError(GENERIC_ERROR)
                renderToast(ERROR, GENERIC_ERROR)
            }
        }
    }

    const dismissModal = () => {
        setShowModal(false)
        history.push(PATH_LOGIN)
    }

    return (
        <Suspense fallback={null} data-testid="password-reset">
            <IonPage>
                <IonContent fullscreen>
                    <AccountHeader />

                    <IonModal isOpen={showModal}>
                        <PasswordResetModal />
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonButton
                                        type="submit"
                                        data-testid="password-reset-modal-dismiss"
                                        onClick={() => dismissModal()}
                                    >
                                        {t('Close')}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonModal>

                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">{t('Reset password')}</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <div className="ion-text-center">
                        <p className="redColor">{error}</p>
                    </div>
                    <IonCardHeader>
                        <IonCardTitle className="reset-password-title" data-testid="reset-password-title">
                            {t('Reset password')}
                        </IonCardTitle>
                        <IonCardSubtitle className="reset-password-subtitle" data-testid="reset-password-subtitle">
                            {t('Enter the email address associated with your Mimex account.')}
                        </IonCardSubtitle>
                    </IonCardHeader>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            submit()
                        }}
                    >
                        <IonGrid>
                            <IonRow>
                                <IonCol size="12">
                                    <IonList>
                                        <label>
                                            <IonInput
                                                data-testid="reset-password-email"
                                                name="username"
                                                value={username}
                                                className="input-field-layout"
                                                autocomplete="on"
                                                placeholder={t("EnterEmailAddress")}
                                                onIonInput={(e: any) =>
                                                    handleUsernameInput(e)
                                                }
                                            />
                                        </label>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>
                </IonContent>
                <AccountFooter data-testid="footer-button" buttonClick={submit} buttonLabel={t('ResetPassword')} disabled={toggleButton()} />
            </IonPage>
        </Suspense>
    )
}

