
export enum ShoppingSessionPhase {

    CHECKIN = 1000,
    IN_STORE = 2000,
    CHECKOUT = 3000,
    AFTER_SALE = 4000,

    UNKNOWN = 100000,
    NONE = 100010,
    CANCELLED = 100020,
    CLOSED = 100030,
}

export enum ShoppingSessionStatus {
    // 1xxx CHECKIN
    QRCODE_ACCESS = 1100,
    COVID_CHECK = 1200,
    DOOR_ENTRANCE_OPEN = 1300,
    DOOR_ENTRANCE_CLOSED = 1350,
    TRACKER_ENTER = 1400,

    // 2xxx IN_STORE
    IN_STORE = 2100,
    TRACKER_ON_RACK = 2200,

    // 3xxx CHECKOUT
    TRACKER_EXIT = 3100,
    RFID_CART = 3200,
    DOOR_EXIT_OPEN = 3300,
    DOOR_EXIT_CLOSED = 3350,
    PAYMENT_PENDING = 3400,
    PAYMENT_OK = 3450,
    PAYMENT_ERR = 3499,

    // 4xxx AFTER_SALE
    PRODUCT_RETURN_REQUEST = 4100,
    PRODUCT_RETURN_RESULT = 4200,


    UNKNOWN = 100000,
    NONE = 100010,
    CANCELLED = 100020,
}

export interface SessionEvent {
    storeId: string
    sessionId: string
    status: ShoppingSessionStatus
    phase: ShoppingSessionPhase
}

export interface SessionTrackStatusEvent {
    storeId: string
    trackNr: number
    haveAllSTAssociated: boolean
}

export enum SessionTrackUpdateType {
    added = "added",
    removed = "removed"
}

export interface SessionTrackUpdate {
    action: SessionTrackUpdateType
    sessionId: string
    trackId: number
}