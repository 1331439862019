import { loadEnv } from "mimex-libs/api/config-loader"
import { useEffect, useState } from "react"
import { getAppConfig } from "mimex-libs/config"

export function ConfigLoader({ children }: any): React.ReactElement | null {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const config = getAppConfig()
        if (config.loaded) return
        loadEnv().finally(() => setLoading(false))
    }, [])
    return (loading ? <span /> : children)
}