import { IonAvatar, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { useStripe } from '@stripe/react-stripe-js';
import { createPaymentIntent, getUserReceipts, markPaymentAsSuccedeed } from "mimex-libs/api/endpoints";
import { BASE_URL, ERROR, GENERIC_ERROR, PATH_HOME, PATH_RECEIPTS, SUCCESS } from "mimex-libs/helpers/constants";
import { formatTotalPrice, parseCurrency } from "mimex-libs/helpers/currency";
import { createLogger } from "mimex-libs/logger";
import { ReceiptDto } from 'mimex-libs/sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AssetImage from 'src/components/AssetImage';
import TabBarContainer from 'src/components/TabBarContainer';
import { IReceipt, ReceiptProductDto } from 'src/pages/dto/receipts';
import useToast from '../CustomHooks/useToast';
import './ReceiptDetails.css';


const logger = createLogger({ component: 'ReceiptDetails' })

export default function ReceiptDetails() {

  const { t } = useTranslation(["translation"]);
  const stripe = useStripe();
  const history = useHistory()
  const [renderToast] = useToast()
  const location = useLocation()

  const [receiptDetails, setReceiptDetails] = useState<IReceipt>()
  const [showLoading, setShowLoading] = useState(false)

  const sessionId = useMemo(() => {
    const parts = location.pathname.split("/")
    if (!parts || !parts.length) return undefined
    return parts.pop()
  }, [location.pathname])

  useEffect(() => {
    if (sessionId) return
    history.push(PATH_RECEIPTS)
  })

  useEffect(() => {

    if (!sessionId) return
    if (receiptDetails && receiptDetails.sessionId === sessionId) return

    setShowLoading(true)
    getUserReceipts({ sessionId }).then((receipts: ReceiptDto[]) => {
      logger.debug(`Retrieved ${receipts.length} receipts`)
      if (!receipts || receipts.length === 0) {
        logger.debug(`Receipt sessionId=${sessionId} not found`)
        history.push(PATH_RECEIPTS)
        return
      }
      setReceiptDetails(receipts[0] as unknown as IReceipt)
    }).catch((e: any) => {
      logger.error(`Error in retrieving receipt session: ${e.message}`, { ref: 'getUserReceipts' })
      renderToast(ERROR, "Failed to load receipt, please retry")
    }).finally(() => setShowLoading(false))

  }, [history, receiptDetails, renderToast, sessionId])

  const processPayment = (e: any) => {

    if (showLoading) return
    if (!sessionId) return

    setShowLoading(true)

    e.preventDefault()
    logger.debug(`Create payment for sessionId=${sessionId}`)
    createPaymentIntent(sessionId)
      .then((response: any) => {
        logger.debug(`Confirm payment sessionId=${sessionId}`)
        return stripe?.confirmCardPayment(response.data.secret, {
          payment_method: response.data.paymentMethodId
        }).then((payload) => {

          if (payload?.error) {

            if (payload?.error.code === 'card_declined') {
              renderToast(ERROR, t('The payment has been declined by your card issuer'))
              logger.error(`stripe error code=${payload?.error?.code}`, { section: 'stripe.confirmCardPayment' })
              return Promise.resolve()
            }

            return Promise.reject(payload?.error)
          }

          return markPaymentAsSuccedeed(sessionId)
            .then((response: any) => {
              logger.info(`Payment confirmed: ${response}`, { section: 'markPaymentAsSuccedeed' })
              renderToast(SUCCESS, t('Payment confirmed'))
              history.push(PATH_HOME)
              return Promise.resolve()
            })
        })
      }).catch((e) => {
        if (e.response && e.response.data && e.response.data.error) {
          logger.error(`createPaymentIntent: ${e.response.data.error.message}`, { section: 'createPaymentIntent' })
          if (e.response.data.error.message === 'Duplicated payment not allowed') {
            renderToast(SUCCESS, 'Payment will be processed, thank you')
            return
          }
          renderToast(ERROR, e.response.data.error.message)
        } else {
          renderToast(ERROR, GENERIC_ERROR)
          logger.error(`createPaymentIntent: ${e.stack}`, { section: 'createPaymentIntent' })
        }
      })
      .finally(() => setShowLoading(false))
  }

  const renderPaymentMethod = (receiptDetails: any) => t(`Paid with %cardType% ending in %cardNumber%`)
    .replace('%cardType%', receiptDetails.payment.cardType)
    .replace('%cardNumber%', receiptDetails.payment.cardNumber)

  return (
    <ion-tabs data-testid="receipt-details">
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('Loading')}
      />
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" >
              {/* <IonBackButton  defaultHref={PATH_RECEIPTS}/> */}
            </IonButtons>
            <IonTitle>{t("Receipt Details")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {!receiptDetails ? '' :
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <h2 className="receipt-details">{t("Receipt details")}</h2>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonList>
                        {receiptDetails.products.map((product: ReceiptProductDto, index: number) => {
                          return (
                            <IonItem lines="full" key={product.productId || index} className="receipt-item">
                              <IonAvatar slot="start" className="receipt-avatar">
                                <AssetImage testId="user-account-avatar" alt={product.name} src={product.images} />
                              </IonAvatar>

                              <IonGrid>
                                <IonRow>
                                  <IonCol size='auto'>
                                    <span className="product-name">{product.quantity}x {product.name}</span>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol>
                                    <div className="product-price">
                                      {`${formatTotalPrice(product.unitPrice, false)}${parseCurrency(product.currency)}`}
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>)
                        })}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              {receiptDetails.payment.paymentStatus === "succeeded" ?
                <>

                  <IonRow class="row-total">
                    <IonCol size="8">
                      <IonLabel>  <span className="total-pay">{t("Grand total")}</span></IonLabel>
                    </IonCol>
                    <IonCol size="4" class='ion-text-right'>
                      <IonLabel>
                        <span className="total-amount">
                          {formatTotalPrice(receiptDetails.payment.totalPrice)}{parseCurrency(receiptDetails.payment.currency)}
                        </span>
                      </IonLabel>
                    </IonCol>
                  </IonRow>

                  {(receiptDetails.payment.fullName || receiptDetails.payment.taxCode || receiptDetails.payment.address) ?
                    <IonRow>
                      <IonCol className="receipt-row">
                        <IonRow>
                          <IonCol>
                            <h2 className="billing-information">{t("Billing information")}</h2>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol className="billing-details">
                            <IonLabel>
                              {
                                receiptDetails.payment.fullName ?
                                  <span>
                                    <small>{t('Name')}</small>
                                    <br />
                                    {receiptDetails.payment.fullName}
                                  </span> :
                                  ''
                              }
                            </IonLabel>
                          </IonCol>
                          <IonCol className="billing-details billing-details-taxCode" class="ion-text-right">
                            <IonLabel>
                              {
                                receiptDetails.payment.taxCode ?
                                  <span>
                                    <small>{t('Tax code')}</small>
                                    <br />
                                    {receiptDetails.payment.taxCode}
                                  </span>
                                  : ''
                              }
                            </IonLabel>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol className="billing-details">
                            <IonLabel data-testid="payment-address">
                              {
                                receiptDetails.payment.address ?

                                  <span>
                                    <small>{t('Address')}</small>
                                    <br />
                                    {receiptDetails.payment.address}
                                  </span>
                                  :
                                  t('No address provided')
                              }
                            </IonLabel>
                          </IonCol>
                        </IonRow>

                      </IonCol>
                    </IonRow>
                    : ''}
                  {
                    (receiptDetails.payment.cardNumber && receiptDetails.payment.cardType) ?

                      <IonRow>
                        <IonCol className="receipt-row">

                          <IonRow>
                            <IonCol>
                              <h2 className="payment-information">{t("Payment information")}</h2>
                            </IonCol>
                          </IonRow>

                          <IonRow className="payment-details">
                            <IonCol>
                              <IonLabel>
                                {renderPaymentMethod(receiptDetails)}
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                      : ''}

                </>
                :
                <IonRow>
                  <IonCol className="receipt-row">
                    <IonButton expand="block" type="submit" fill="solid" color="primary" id="payment-button"
                      onClick={(e) => processPayment(e)}
                    >{t("Pay now")}
                    </IonButton>
                  </IonCol>
                </IonRow>
              }
            </IonGrid>
          }
        </IonContent>
        <TabBarContainer />
      </IonPage>
    </ion-tabs>
  )
}