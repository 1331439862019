import React from 'react'
import { IonButton, IonCardTitle, IonCol, IonContent, IonGrid, IonImg, IonList, IonRow, IonSlide } from '@ionic/react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { setOnBoardingSession } from 'mimex-libs/helpers/userStore'

import '../OnBoarding.css'


const OnboardingSlide = ({
    id,
    image,
    mainSlide = false,
    finalSlide = false,
    title,
    text,
    firstSlide,
    lastSlide,
    sliderRef,
}: any) => {

    const history = useHistory()
    const { t } = useTranslation(["translation"]);

    const redirectToLogin = () => {
        setOnBoardingSession()
        history.push('/login')
    }

    return (
        <IonSlide data-testid="on-boarding-slide" id={id}>
            <IonContent className="ion-padding parent-container">
                <IonGrid className="grid-container">
                    <IonRow className="row-layout">
                        <IonCol>
                            <IonCardTitle className="slide-title">
                                {title}
                            </IonCardTitle>
                        </IonCol>

                    </IonRow>
                    {mainSlide ? (
                        <IonRow >
                            <div>
                                <IonImg src={image} className="main-logo" />
                            </div>
                        </IonRow>) : (<IonRow className="row-layout">
                            <IonCol>
                                <IonList>
                                    <div className="slide-other-images">
                                        <IonImg src={image} className="slide-image-layout" />
                                    </div>
                                </IonList>
                            </IonCol>

                        </IonRow>
                    )}

                    <IonRow className="row-layout">
                        <IonCol>
                            {finalSlide && (
                                <IonButton
                                id="on-boarding-done"
                                    expand="block"
                                    color="primary"
                                    type="submit"
                                    fill="solid"
                                    onClick={redirectToLogin}
                                >
                                    {t("Got it, lets start")}
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonSlide>
    )
}

export default OnboardingSlide
