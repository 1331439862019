import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useTranslation } from 'react-i18next'
import { BASE_URL } from 'mimex-libs/helpers/constants';

import './Receipts.css'


const ReceiptsNotFound = () => {
    const { t } = useTranslation(["translation"]);

    return (
        <IonGrid data-testid="receipts-not-found">
            <IonRow className="no-data">
                <IonCol>
                    <img
                        src={`${BASE_URL}/assets/images/no-data.svg`} alt="MIMEX"
                        className="no-data-image"
                    />
                </IonCol>
            </IonRow>
            <IonRow className="no-data">
                <IonCol><h3>{t("No receipt found")}</h3></IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ReceiptsNotFound
