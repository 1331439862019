
import { emitter, getTokenPayload, memoryStore, setStoreId, setUserToken, UserStoreChanged, UserStoreOperation, UserStoreType } from '../helpers/userStore'
import { createLogger } from '../logger'
import { SessionEvent } from '../sdk/events/session.events'
import { BrokerMessage, getBroker } from './broker'
import { refreshToken } from './endpoints'
import { createClient } from './sdk'

/**
 * Initialize API clients and bind to userStore events
 */

const logger = createLogger({component: 'sdk', section: 'index'})

export const getQueryString = (): Record<string, string> => {
    if (!document.location.search) return {}
    const tokenLink = document.location.search.substring(1)
    if (!tokenLink) return {}
    return tokenLink.split("&").reduce((o, part) => {
        const [key, val] = part.split("=")
        return {...o, [key]: val}
    }, {})
}

const loadURLToken = () => {
    const qs = getQueryString()
    if (!qs.token) return
    memoryStore.set(UserStoreType.userToken, qs.token)
    logger.trace(`Set token from URL`)
}

const tokenRefresh = () => {
    // logger.debug(`Refreshing JWT token`)
    refreshToken()
    .then(({jwt}) => {
        // logger.debug(`Refreshed JWT token`)
        setUserToken(jwt)
    }).catch((e) => {
        logger.debug(`Failed to refresh JWT token error=${e.stack}`)
    })
}

export const init = (): void => {
    
    loadURLToken()

    createClient()
    const broker = getBroker()

    // update session status in the store
    broker?.on("session", (ev: BrokerMessage<SessionEvent>) => {
        const sessionEvent = ev.payload
        setStoreId(sessionEvent.storeId)
        memoryStore.set(UserStoreType.session, sessionEvent)
        logger.debug(`Updated sessionId=${sessionEvent.sessionId} to status=${sessionEvent.status}`)
    })

    let intv: NodeJS.Timeout

    // refresh token if it is going to expire during app usage
    const watchTokenRefresh = () => {
        const payload = getTokenPayload()
        if (!payload || !payload?.exp) return
        const timeout = ((payload?.exp - 60) * 1000) - Date.now()
        // const timeout = 5000 // for testing purposes, will refresh token every 5sec
        logger.info(`Scheduled JWT token refresh in ${Math.round(timeout/1000/60)}m`)
        intv = setTimeout(() => { tokenRefresh() }, timeout)
    }

    emitter.on(UserStoreType.userToken, (ev: UserStoreChanged) => {
        if (ev.op === UserStoreOperation.REMOVED) {
            if (intv) clearTimeout(intv)
        }
        if (ev.op === UserStoreOperation.UPDATED) {
            watchTokenRefresh()
        }
    })
    watchTokenRefresh()

}