
import { IonImg } from '@ionic/react'
import { getAsset } from 'mimex-libs/api/endpoints'
import { BASE_URL } from 'mimex-libs/helpers/constants'
import { createLogger } from 'mimex-libs/logger'
import { ProductImage } from 'mimex-libs/sdk'
import { useEffect, useState } from 'react'


const PLACEHOLDER = '/assets/placeholder.svg'
const NO_IMAGE = `${BASE_URL}/assets/images/no-image.svg`

interface AssetImageProps {
    testId?: string
    src?: string | ProductImage[]
    alt: string
}

const logger = createLogger({ component: 'AssetImage' })

export default function AssetImage(props: AssetImageProps) {

    const [imgSrc, setImgSrc] = useState<string>(PLACEHOLDER)

    useEffect(() => {

        let imageId: string | undefined = undefined
        if (typeof props.src === "string") {
            imageId = props.src
        } else {
            const images = props.src as ProductImage[]
            if (images && images.length) {
                imageId = images.sort(a => a.primary ? -1 : 1)[0].imageId
            }
        }

        if (!imageId) {
            setImgSrc(NO_IMAGE)
            return
        }

        getAsset(imageId)
            .then((imgsrc: string) => {
                setImgSrc(imgsrc)
            })
            .catch((e) => {
                logger.error(`Failed to load asset id=${imageId} err=${e.stack}`)
                setImgSrc(NO_IMAGE)
            })
    }, [props.src])

    return <IonImg
        class="fit-contain"
        data-testid={props.testId || `img_${Math.random().toString(36)}`}
        alt={props.alt}
        src={imgSrc}
    />
}