import { setupIonicReact } from '@ionic/react';
import i18next from "i18next";
import { init } from 'mimex-libs/api';
import { BASE_URL } from "mimex-libs/helpers/constants";
import { configureI18N } from "mimex-libs/i18n/i18n";
import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { onSuccess, onUpdate } from "./registrationStatus";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Spinner } from './Spinner';

configureI18N(i18next)
init()

//force to use material design for every device
setupIonicReact({ mode: 'md' })

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <I18nextProvider i18n={i18next}>
      <React.StrictMode>
        <BrowserRouter basename={BASE_URL}>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('./service-worker.ts').then(function (reg) {
//     console.log('Successfully registered service worker', reg);
//   }).catch(function (err) {
//     console.warn('Error whilst registering service worker', err);
//   });
// }
serviceWorkerRegistration.register({
  onUpdate,
  onSuccess,
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
