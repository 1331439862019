import { useIonToast } from '@ionic/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import "./Toast.css";

// interface ToastOptions {
//   header?: string;
//   message?: string | IonicSafeString;
//   cssClass?: string | string[];
//   duration?: number;
//   buttons?: (ToastButton | string)[];
//   position?: 'top' | 'bottom' | 'middle';
//   translucent?: boolean;
//   animated?: boolean;
//   color?: 'danger' | 'success' | 'warning';
// }
const useToast = () => {
  const { t } = useTranslation(["translation"]);
  const [showToast, hideToast] = useIonToast();


  const renderToast = useCallback((color: string, message: string) => {
    showToast({
      duration: 3000,
      keyboardClose: true,
      color: color,
      position: "top",
      cssClass: `${color}-toast`,
      buttons: [{ text: t('HIDE'), handler: () => hideToast() }],
      message: t(message),
    })
  }, [hideToast, showToast, t])
  return [renderToast];
};

export default useToast;
