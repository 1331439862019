/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiKeyDto
 */
export interface ApiKeyDto {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDto
     */
    apikeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDto
     */
    token?: string;
    /**
     * Expressed in seconds. Default is 30 days.
     * @type {number}
     * @memberof ApiKeyDto
     */
    expiresIn?: number;
}
/**
 * 
 * @export
 * @interface ApiKeyDtoMimexResponse
 */
export interface ApiKeyDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ApiKeyDto}
     * @memberof ApiKeyDtoMimexResponse
     */
    data: ApiKeyDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ApiKeyDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface AssetSaveResponse
 */
export interface AssetSaveResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetSaveResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface AssetSaveResponseMimexResponse
 */
export interface AssetSaveResponseMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof AssetSaveResponseMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {AssetSaveResponse}
     * @memberof AssetSaveResponseMimexResponse
     */
    data: AssetSaveResponse;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof AssetSaveResponseMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface AuthAdminFirebaseEnableUserDto
 */
export interface AuthAdminFirebaseEnableUserDto {
    /**
     * Set email verified status
     * @type {boolean}
     * @memberof AuthAdminFirebaseEnableUserDto
     */
    emailVerified: boolean;
    /**
     * Enable or disable user
     * @type {boolean}
     * @memberof AuthAdminFirebaseEnableUserDto
     */
    disabled?: boolean;
}
/**
 * 
 * @export
 * @interface AuthAdminUserCreateDto
 */
export interface AuthAdminUserCreateDto {
    /**
     * By default is enabled. If set to false, the validation email will be sent.
     * @type {boolean}
     * @memberof AuthAdminUserCreateDto
     */
    emailVerified?: boolean;
    /**
     * The user ID as saved in Firebase
     * @type {string}
     * @memberof AuthAdminUserCreateDto
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUserCreateDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUserCreateDto
     */
    password?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof AuthAdminUserCreateDto
     */
    account?: UserAccountDto;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof AuthAdminUserCreateDto
     */
    profile?: UserProfileDto;
}
/**
 * 
 * @export
 * @interface AuthAdminUserCreateResponseDto
 */
export interface AuthAdminUserCreateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUserCreateResponseDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUserCreateResponseDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AuthAdminUserCreateResponseDtoMimexResponse
 */
export interface AuthAdminUserCreateResponseDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUserCreateResponseDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {AuthAdminUserCreateResponseDto}
     * @memberof AuthAdminUserCreateResponseDtoMimexResponse
     */
    data: AuthAdminUserCreateResponseDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof AuthAdminUserCreateResponseDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface AuthAdminUsersList
 */
export interface AuthAdminUsersList {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUsersList
     */
    pagerToken: string;
    /**
     * 
     * @type {Array<AuthAdminUserCreateDto>}
     * @memberof AuthAdminUsersList
     */
    users: Array<AuthAdminUserCreateDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthAdminUsersList
     */
    errors: Array<string>;
}
/**
 * 
 * @export
 * @interface AuthAdminUsersListMimexResponse
 */
export interface AuthAdminUsersListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUsersListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {AuthAdminUsersList}
     * @memberof AuthAdminUsersListMimexResponse
     */
    data: AuthAdminUsersList;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof AuthAdminUsersListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface AuthAdminUsersListQuery
 */
export interface AuthAdminUsersListQuery {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUsersListQuery
     */
    pagerToken?: string;
    /**
     * 
     * @type {object}
     * @memberof AuthAdminUsersListQuery
     */
    limit?: object;
    /**
     * 
     * @type {AuthAdminUsersListQueryFilter}
     * @memberof AuthAdminUsersListQuery
     */
    filter?: AuthAdminUsersListQueryFilter;
}
/**
 * 
 * @export
 * @interface AuthAdminUsersListQueryFilter
 */
export interface AuthAdminUsersListQueryFilter {
    /**
     * 
     * @type {string}
     * @memberof AuthAdminUsersListQueryFilter
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthAdminUsersListQueryFilter
     */
    uids: Array<string>;
}
/**
 * 
 * @export
 * @interface AutomaticPaymentDto
 */
export interface AutomaticPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof AutomaticPaymentDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof AutomaticPaymentDto
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface BKMPaymentResultDto
 */
export interface BKMPaymentResultDto {
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentResultDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentResultDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentResultDto
     */
    result: BKMPaymentResultDtoResultEnum;
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentResultDto
     */
    errorString?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BKMPaymentResultDtoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface BKMPaymentSetupResultDto
 */
export interface BKMPaymentSetupResultDto {
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentSetupResultDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentSetupResultDto
     */
    result: BKMPaymentSetupResultDtoResultEnum;
    /**
     * 
     * @type {string}
     * @memberof BKMPaymentSetupResultDto
     */
    errorString?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BKMPaymentSetupResultDtoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface CalibDto
 */
export interface CalibDto {
    /**
     * 
     * @type {number}
     * @memberof CalibDto
     */
    points_w0: number;
    /**
     * 
     * @type {number}
     * @memberof CalibDto
     */
    weightCoeff?: number;
}
/**
 * 
 * @export
 * @interface CalibResultDto
 */
export interface CalibResultDto {
    /**
     * 
     * @type {number}
     * @memberof CalibResultDto
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof CalibResultDto
     */
    weightCoeff: number;
}
/**
 * 
 * @export
 * @interface CalibResultDtoMimexResponse
 */
export interface CalibResultDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof CalibResultDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {CalibResultDto}
     * @memberof CalibResultDtoMimexResponse
     */
    data: CalibResultDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof CalibResultDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface CardDto
 */
export interface CardDto {
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof CardDto
     */
    last4: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CartStatusOperation {
    Add = 'ADD',
    Remove = 'REMOVE'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum CartStatusSource {
    Weight = 'WEIGHT',
    Rfid = 'RFID',
    User = 'USER',
    Shelf = 'SHELF',
    Vision = 'VISION'
}

/**
 * 
 * @export
 * @interface CellDto
 */
export interface CellDto {
    /**
     * 
     * @type {string}
     * @memberof CellDto
     */
    cellId: string;
    /**
     * 
     * @type {CalibDto}
     * @memberof CellDto
     */
    calib?: CalibDto;
    /**
     * 
     * @type {CellExtraParamsDto}
     * @memberof CellDto
     */
    extraParams?: CellExtraParamsDto;
}
/**
 * 
 * @export
 * @interface CellExtraParamsDto
 */
export interface CellExtraParamsDto {
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    smoothWindowLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    varianceWindowLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    stabilityWindowLength?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    minWeightThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    maxWeightVariance?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    jumpThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof CellExtraParamsDto
     */
    stableSmoothWindowLength?: number;
}
/**
 * 
 * @export
 * @interface CellStateDto
 */
export interface CellStateDto {
    /**
     * 
     * @type {string}
     * @memberof CellStateDto
     */
    state: string;
}
/**
 * 
 * @export
 * @interface CellStateDtoMimexResponse
 */
export interface CellStateDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof CellStateDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {CellStateDto}
     * @memberof CellStateDtoMimexResponse
     */
    data: CellStateDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof CellStateDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface CellStatusDto
 */
export interface CellStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CellStatusDto
     */
    status: string;
}
/**
 * 
 * @export
 * @interface CellStatusDtoMimexResponse
 */
export interface CellStatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof CellStatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {CellStatusDto}
     * @memberof CellStatusDtoMimexResponse
     */
    data: CellStatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof CellStatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CheckinStatus {
    Ready = 'READY',
    Waiting = 'WAITING',
    Crowded = 'CROWDED',
    Maintenance = 'MAINTENANCE',
    Closed = 'CLOSED',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface CheckinStatusDto
 */
export interface CheckinStatusDto {
    /**
     * 
     * @type {CheckinStatus}
     * @memberof CheckinStatusDto
     */
    status: CheckinStatus;
}
/**
 * 
 * @export
 * @interface CheckinStatusDtoMimexResponse
 */
export interface CheckinStatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckinStatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {CheckinStatusDto}
     * @memberof CheckinStatusDtoMimexResponse
     */
    data: CheckinStatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof CheckinStatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ClientSecretDto
 */
export interface ClientSecretDto {
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    secret: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    redirectUrl?: string;
}
/**
 * 
 * @export
 * @interface ClientSecretDtoMimexResponse
 */
export interface ClientSecretDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ClientSecretDto}
     * @memberof ClientSecretDtoMimexResponse
     */
    data: ClientSecretDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ClientSecretDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface CloseSessionOptions
 */
export interface CloseSessionOptions {
    /**
     * 
     * @type {boolean}
     * @memberof CloseSessionOptions
     */
    closeAll?: boolean;
}
/**
 * 
 * @export
 * @interface DirectPaymentDto
 */
export interface DirectPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof DirectPaymentDto
     */
    secret: string;
    /**
     * 
     * @type {string}
     * @memberof DirectPaymentDto
     */
    paymentMethodId: string;
}
/**
 * 
 * @export
 * @interface DirectPaymentDtoMimexResponse
 */
export interface DirectPaymentDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectPaymentDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {DirectPaymentDto}
     * @memberof DirectPaymentDtoMimexResponse
     */
    data: DirectPaymentDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof DirectPaymentDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface DoorLayout
 */
export interface DoorLayout {
    /**
     * 
     * @type {number}
     * @memberof DoorLayout
     */
    centerX: number;
    /**
     * 
     * @type {number}
     * @memberof DoorLayout
     */
    centerY: number;
}
/**
 * 
 * @export
 * @interface DoorStatusDto
 */
export interface DoorStatusDto {
    /**
     * 
     * @type {DoorStatusEnum}
     * @memberof DoorStatusDto
     */
    status: DoorStatusEnum;
}
/**
 * 
 * @export
 * @interface DoorStatusDtoMimexResponse
 */
export interface DoorStatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof DoorStatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {DoorStatusDto}
     * @memberof DoorStatusDtoMimexResponse
     */
    data: DoorStatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof DoorStatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DoorStatusEnum {
    Open = 'open',
    Closed = 'closed',
    Locked = 'locked'
}

/**
 * 
 * @export
 * @interface DropCollection
 */
export interface DropCollection {
    /**
     * 
     * @type {Array<string>}
     * @memberof DropCollection
     */
    collections: Array<string>;
}
/**
 * 
 * @export
 * @interface EmbedderParameters
 */
export interface EmbedderParameters {
    /**
     * 
     * @type {number}
     * @memberof EmbedderParameters
     */
    batch_size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmbedderParameters
     */
    drop_last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmbedderParameters
     */
    emb_size?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbedderParameters
     */
    embedding_size?: number;
    /**
     * 
     * @type {EmbedderParametersHeads}
     * @memberof EmbedderParameters
     */
    heads?: EmbedderParametersHeads;
    /**
     * 
     * @type {number}
     * @memberof EmbedderParameters
     */
    nepochs?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbedderParameters
     */
    num_workers?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmbedderParameters
     */
    pin_memory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmbedderParameters
     */
    pretrained?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmbedderParameters
     */
    shuffle?: boolean;
}
/**
 * 
 * @export
 * @interface EmbedderParametersHeads
 */
export interface EmbedderParametersHeads {
    /**
     * 
     * @type {number}
     * @memberof EmbedderParametersHeads
     */
    reg?: number;
    /**
     * 
     * @type {number}
     * @memberof EmbedderParametersHeads
     */
    wh?: number;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    errors: Array<string>;
}
/**
 * 
 * @export
 * @interface ExportCollection
 */
export interface ExportCollection {
    /**
     * 
     * @type {string}
     * @memberof ExportCollection
     */
    collection: string;
    /**
     * 
     * @type {ImportFileType}
     * @memberof ExportCollection
     */
    format: ImportFileType;
}
/**
 * 
 * @export
 * @interface FirebaseResetPasswordUserNotFoundMimexResponse
 */
export interface FirebaseResetPasswordUserNotFoundMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof FirebaseResetPasswordUserNotFoundMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {object}
     * @memberof FirebaseResetPasswordUserNotFoundMimexResponse
     */
    data: object;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof FirebaseResetPasswordUserNotFoundMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface GeoPoint
 */
export interface GeoPoint {
    /**
     * 
     * @type {string}
     * @memberof GeoPoint
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeoPoint
     */
    coordinates: Array<string>;
}
/**
 * 
 * @export
 * @interface GuiStatusMimexResponse
 */
export interface GuiStatusMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof GuiStatusMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {object}
     * @memberof GuiStatusMimexResponse
     */
    data: object;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof GuiStatusMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ImportFileType {
    Json = 'json',
    Csv = 'csv'
}

/**
 * 
 * @export
 * @interface JWTExpirationExceptionMimexResponse
 */
export interface JWTExpirationExceptionMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof JWTExpirationExceptionMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {object}
     * @memberof JWTExpirationExceptionMimexResponse
     */
    data: object;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof JWTExpirationExceptionMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface JwtResponse
 */
export interface JwtResponse {
    /**
     * 
     * @type {string}
     * @memberof JwtResponse
     */
    jwt: string;
}
/**
 * 
 * @export
 * @interface JwtResponseMimexResponse
 */
export interface JwtResponseMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof JwtResponseMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {JwtResponse}
     * @memberof JwtResponseMimexResponse
     */
    data: JwtResponse;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof JwtResponseMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface LogItemDto
 */
export interface LogItemDto {
    /**
     * 
     * @type {string}
     * @memberof LogItemDto
     */
    recordingId: string;
    /**
     * 
     * @type {string}
     * @memberof LogItemDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof LogItemDto
     */
    time: string;
    /**
     * 
     * @type {string}
     * @memberof LogItemDto
     */
    payload: string;
}
/**
 * 
 * @export
 * @interface LogItemDtoListMimexResponse
 */
export interface LogItemDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof LogItemDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<LogItemDto>}
     * @memberof LogItemDtoListMimexResponse
     */
    data: Array<LogItemDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof LogItemDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface LogItemRemoveQueryDto
 */
export interface LogItemRemoveQueryDto {
    /**
     * 
     * @type {string}
     * @memberof LogItemRemoveQueryDto
     */
    before: string;
    /**
     * 
     * @type {string}
     * @memberof LogItemRemoveQueryDto
     */
    after: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LogItemRemoveQueryDto
     */
    subjects: Array<string>;
}
/**
 * 
 * @export
 * @interface LogRecordingDto
 */
export interface LogRecordingDto {
    /**
     * 
     * @type {string}
     * @memberof LogRecordingDto
     */
    recordingId: string;
    /**
     * 
     * @type {string}
     * @memberof LogRecordingDto
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LogRecordingDto
     */
    subjects: Array<string>;
    /**
     * 
     * @type {LogRecordingStatus}
     * @memberof LogRecordingDto
     */
    status: LogRecordingStatus;
    /**
     * 
     * @type {Array<LogItemDto>}
     * @memberof LogRecordingDto
     */
    items: Array<LogItemDto>;
}
/**
 * 
 * @export
 * @interface LogRecordingDtoListMimexResponse
 */
export interface LogRecordingDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof LogRecordingDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<LogRecordingDto>}
     * @memberof LogRecordingDtoListMimexResponse
     */
    data: Array<LogRecordingDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof LogRecordingDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface LogRecordingDtoMimexResponse
 */
export interface LogRecordingDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof LogRecordingDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {LogRecordingDto}
     * @memberof LogRecordingDtoMimexResponse
     */
    data: LogRecordingDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof LogRecordingDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum LogRecordingStatus {
    Recording = 'recording',
    Playing = 'playing',
    Stopped = 'stopped'
}

/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password: string;
}
/**
 * 
 * @export
 * @interface MLDestinationDto
 */
export interface MLDestinationDto {
    /**
     * 
     * @type {MLDestinationType}
     * @memberof MLDestinationDto
     */
    type: MLDestinationType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MLDestinationType {
    Broker = 'broker'
}

/**
 * 
 * @export
 * @interface MLDetectorParametersDto
 */
export interface MLDetectorParametersDto {
    /**
     * 
     * @type {string}
     * @memberof MLDetectorParametersDto
     */
    modelId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MLDetectorParametersDto
     */
    embeddings?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MLDetectorParametersDto
     */
    label?: number;
    /**
     * 
     * @type {EmbedderParameters}
     * @memberof MLDetectorParametersDto
     */
    parameters: EmbedderParameters;
    /**
     * 
     * @type {string}
     * @memberof MLDetectorParametersDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MLEmbedderInferenceDto
 */
export interface MLEmbedderInferenceDto {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderInferenceDto
     */
    modelId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MLEmbedderInferenceDto
     */
    embeddings?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MLEmbedderInferenceDto
     */
    label?: number;
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderInferenceDto
     */
    productId: string;
}
/**
 * 
 * @export
 * @interface MLEmbedderInferenceDtoListMimexResponse
 */
export interface MLEmbedderInferenceDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderInferenceDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<MLEmbedderInferenceDto>}
     * @memberof MLEmbedderInferenceDtoListMimexResponse
     */
    data: Array<MLEmbedderInferenceDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MLEmbedderInferenceDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MLEmbedderParametersDto
 */
export interface MLEmbedderParametersDto {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderParametersDto
     */
    modelId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MLEmbedderParametersDto
     */
    embeddings?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MLEmbedderParametersDto
     */
    label?: number;
    /**
     * 
     * @type {EmbedderParameters}
     * @memberof MLEmbedderParametersDto
     */
    parameters: EmbedderParameters;
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderParametersDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MLEmbedderParametersDtoMimexResponse
 */
export interface MLEmbedderParametersDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderParametersDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {MLEmbedderParametersDto}
     * @memberof MLEmbedderParametersDtoMimexResponse
     */
    data: MLEmbedderParametersDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MLEmbedderParametersDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MLEmbedderTrainingDto
 */
export interface MLEmbedderTrainingDto {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderTrainingDto
     */
    modelId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MLEmbedderTrainingDto
     */
    embeddings?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MLEmbedderTrainingDto
     */
    label?: number;
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderTrainingDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MLEmbedderTrainingDtoListMimexResponse
 */
export interface MLEmbedderTrainingDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderTrainingDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<MLEmbedderTrainingDto>}
     * @memberof MLEmbedderTrainingDtoListMimexResponse
     */
    data: Array<MLEmbedderTrainingDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MLEmbedderTrainingDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MLEmbedderTrainingDtoMimexResponse
 */
export interface MLEmbedderTrainingDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MLEmbedderTrainingDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {MLEmbedderTrainingDto}
     * @memberof MLEmbedderTrainingDtoMimexResponse
     */
    data: MLEmbedderTrainingDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MLEmbedderTrainingDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MLJobRequestDto
 */
export interface MLJobRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MLJobRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MLJobRequestDto
     */
    name: string;
    /**
     * 
     * @type {MLType}
     * @memberof MLJobRequestDto
     */
    type: MLType;
    /**
     * 
     * @type {MLSourceDto}
     * @memberof MLJobRequestDto
     */
    source: MLSourceDto;
    /**
     * 
     * @type {MLDestinationDto}
     * @memberof MLJobRequestDto
     */
    destination: MLDestinationDto;
}
/**
 * 
 * @export
 * @interface MLSourceDto
 */
export interface MLSourceDto {
    /**
     * 
     * @type {MLSourceType}
     * @memberof MLSourceDto
     */
    type: MLSourceType;
    /**
     * 
     * @type {Array<string>}
     * @memberof MLSourceDto
     */
    cameras: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MLSourceType {
    StillImage = 'still_image',
    Socket = 'socket'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MLType {
    PoseDetector = 'pose_detector'
}

/**
 * 
 * @export
 * @interface MicroMarketIdExceptionMimexResponse
 */
export interface MicroMarketIdExceptionMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MicroMarketIdExceptionMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {object}
     * @memberof MicroMarketIdExceptionMimexResponse
     */
    data: object;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MicroMarketIdExceptionMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MimexResponse
 */
export interface MimexResponse {
    /**
     * 
     * @type {string}
     * @memberof MimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof MimexResponse
     */
    data: string;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof MimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface MoveStockDto
 */
export interface MoveStockDto {
    /**
     * ID of the store
     * @type {string}
     * @memberof MoveStockDto
     */
    storeId: string;
    /**
     * ID of the product
     * @type {string}
     * @memberof MoveStockDto
     */
    productId: string;
    /**
     * ID of the source stock
     * @type {string}
     * @memberof MoveStockDto
     */
    srcStockId: string;
    /**
     * ID of the destination stock
     * @type {string}
     * @memberof MoveStockDto
     */
    dstStockId: string;
    /**
     * Quantity to transfer
     * @type {number}
     * @memberof MoveStockDto
     */
    qty: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    notificationId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    date: string;
    /**
     * 
     * @type {object}
     * @memberof NotificationDto
     */
    message: object;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    shelfId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    read?: boolean;
    /**
     * 
     * @type {object}
     * @memberof NotificationDto
     */
    level: object;
}
/**
 * 
 * @export
 * @interface NotificationDtoListMimexResponse
 */
export interface NotificationDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof NotificationDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof NotificationDtoListMimexResponse
     */
    data: Array<NotificationDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof NotificationDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface OpenSessionOptions
 */
export interface OpenSessionOptions {
    /**
     * 
     * @type {string}
     * @memberof OpenSessionOptions
     */
    userId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpenSessionOptions
     */
    closeOpen?: boolean;
}
/**
 * 
 * @export
 * @interface OpeningSchedule
 */
export interface OpeningSchedule {
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    mon: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    tue: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    wed: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    thu: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    fri: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    sat: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningSchedule
     */
    sun: Array<string>;
}
/**
 * 
 * @export
 * @interface OutOfPlaceDto
 */
export interface OutOfPlaceDto {
    /**
     * 
     * @type {string}
     * @memberof OutOfPlaceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OutOfPlaceDto
     */
    shelfId: string;
    /**
     * 
     * @type {string}
     * @memberof OutOfPlaceDto
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof OutOfPlaceDto
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof OutOfPlaceDto
     */
    weight: number;
}
/**
 * 
 * @export
 * @interface OutOfPlaceDtoListMimexResponse
 */
export interface OutOfPlaceDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof OutOfPlaceDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<OutOfPlaceDto>}
     * @memberof OutOfPlaceDtoListMimexResponse
     */
    data: Array<OutOfPlaceDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof OutOfPlaceDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PaymentAmountDto
 */
export interface PaymentAmountDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentAmountDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentAmountDto
     */
    currency: string;
}
/**
 * 
 * @export
 * @interface PaymentAmountDtoListMimexResponse
 */
export interface PaymentAmountDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentAmountDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<PaymentAmountDto>}
     * @memberof PaymentAmountDtoListMimexResponse
     */
    data: Array<PaymentAmountDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PaymentAmountDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    submission: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    attempts: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    paymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    cardType: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    cardNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    invoiceNumber: string;
}
/**
 * 
 * @export
 * @interface PaymentDtoListMimexResponse
 */
export interface PaymentDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof PaymentDtoListMimexResponse
     */
    data: Array<PaymentDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PaymentDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PaymentExportDtoListMimexResponse
 */
export interface PaymentExportDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentExportDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof PaymentExportDtoListMimexResponse
     */
    data: Array<object>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PaymentExportDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PaymentMethodDto
 */
export interface PaymentMethodDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodDto
     */
    id: string;
    /**
     * 
     * @type {CardDto}
     * @memberof PaymentMethodDto
     */
    card: CardDto;
}
/**
 * 
 * @export
 * @interface PaymentMethodDtoListMimexResponse
 */
export interface PaymentMethodDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<PaymentMethodDto>}
     * @memberof PaymentMethodDtoListMimexResponse
     */
    data: Array<PaymentMethodDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PaymentMethodDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PaymentTimeSeriesDto
 */
export interface PaymentTimeSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentTimeSeriesDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentTimeSeriesDto
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentTimeSeriesDto
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface PaymentTimeSeriesDtoListMimexResponse
 */
export interface PaymentTimeSeriesDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentTimeSeriesDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<PaymentTimeSeriesDto>}
     * @memberof PaymentTimeSeriesDtoListMimexResponse
     */
    data: Array<PaymentTimeSeriesDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PaymentTimeSeriesDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PeopleTrackerTestDto
 */
export interface PeopleTrackerTestDto {
    /**
     * 
     * @type {string}
     * @memberof PeopleTrackerTestDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PeopleTrackerTestDto
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface PlayRequestDto
 */
export interface PlayRequestDto {
    /**
     * 
     * @type {string}
     * @memberof PlayRequestDto
     */
    camId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayRequestDto
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayRequestDto
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayRequestDto
     */
    path?: string;
    /**
     * Repeat the stream once finished
     * @type {boolean}
     * @memberof PlayRequestDto
     */
    loop?: boolean;
    /**
     * Percentage of FPS speed ratio eg. 1.5 o 0.5
     * @type {number}
     * @memberof PlayRequestDto
     */
    ratio?: number;
}
/**
 * 
 * @export
 * @interface PlaybackOptions
 */
export interface PlaybackOptions {
    /**
     * 
     * @type {string}
     * @memberof PlaybackOptions
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackOptions
     */
    allowParallel?: boolean;
    /**
     * Number of times to repeat. Use -1 to repeat continuously
     * @type {number}
     * @memberof PlaybackOptions
     */
    repeat?: number;
    /**
     * Seconds to delay before repeating
     * @type {number}
     * @memberof PlaybackOptions
     */
    delay?: number;
    /**
     * 
     * @type {object}
     * @memberof PlaybackOptions
     */
    data?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackOptions
     */
    waitResult?: boolean;
}
/**
 * 
 * @export
 * @interface PlaybackResult
 */
export interface PlaybackResult {
    /**
     * 
     * @type {string}
     * @memberof PlaybackResult
     */
    uuid: string;
    /**
     * 
     * @type {PlaybackOptions}
     * @memberof PlaybackResult
     */
    options: PlaybackOptions;
    /**
     * 
     * @type {boolean}
     * @memberof PlaybackResult
     */
    completed: boolean;
    /**
     * 
     * @type {object}
     * @memberof PlaybackResult
     */
    error: object;
    /**
     * 
     * @type {object}
     * @memberof PlaybackResult
     */
    result: object;
    /**
     * 
     * @type {string}
     * @memberof PlaybackResult
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof PlaybackResult
     */
    ended: string;
    /**
     * 
     * @type {number}
     * @memberof PlaybackResult
     */
    repeated: number;
    /**
     * 
     * @type {number}
     * @memberof PlaybackResult
     */
    failed: number;
}
/**
 * 
 * @export
 * @interface PlaybackResultListMimexResponse
 */
export interface PlaybackResultListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PlaybackResultListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<PlaybackResult>}
     * @memberof PlaybackResultListMimexResponse
     */
    data: Array<PlaybackResult>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PlaybackResultListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface PlaybackResultMimexResponse
 */
export interface PlaybackResultMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof PlaybackResultMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {PlaybackResult}
     * @memberof PlaybackResultMimexResponse
     */
    data: PlaybackResult;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof PlaybackResultMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ProcessHandlerStatus
 */
export interface ProcessHandlerStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ProcessHandlerStatus
     */
    running: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProcessHandlerStatus
     */
    maxRestarts: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessHandlerStatus
     */
    restarted: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessHandlerStatus
     */
    background: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessHandlerStatus
     */
    started: string;
}
/**
 * 
 * @export
 * @interface ProcessHandlerStatusMimexResponse
 */
export interface ProcessHandlerStatusMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ProcessHandlerStatusMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ProcessHandlerStatus}
     * @memberof ProcessHandlerStatusMimexResponse
     */
    data: ProcessHandlerStatus;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ProcessHandlerStatusMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    barcodeType: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    weight: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    unitPrice: number;
    /**
     * 
     * @type {StoreCurrency}
     * @memberof ProductDto
     */
    currency: StoreCurrency;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof ProductDto
     */
    images: Array<ProductImage>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    hscode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    weightTolerance: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productHeight: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productWidth: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productLength: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    guarantee: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    orientation: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    status: ProductDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductDtoStatusEnum {
    Active = 'active',
    Passive = 'passive',
    Suspended = 'suspended',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @interface ProductDtoListMimexResponse
 */
export interface ProductDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ProductDtoListMimexResponse
     */
    data: Array<ProductDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ProductDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ProductDtoMimexResponse
 */
export interface ProductDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ProductDto}
     * @memberof ProductDtoMimexResponse
     */
    data: ProductDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ProductDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    imageId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductImage
     */
    primary: boolean;
}
/**
 * 
 * @export
 * @interface ProductPriceDto
 */
export interface ProductPriceDto {
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDto
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDto
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceDto
     */
    vat: number;
}
/**
 * 
 * @export
 * @interface ProductPriceDtoMimexResponse
 */
export interface ProductPriceDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductPriceDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ProductPriceDto}
     * @memberof ProductPriceDtoMimexResponse
     */
    data: ProductPriceDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ProductPriceDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ProductSearchDto
 */
export interface ProductSearchDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductSearchDto
     */
    productId?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface RackDto
 */
export interface RackDto {
    /**
     * 
     * @type {string}
     * @memberof RackDto
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof RackDto
     */
    rackId: string;
    /**
     * 
     * @type {string}
     * @memberof RackDto
     */
    rackName: string;
    /**
     * 
     * @type {RackPositionDto}
     * @memberof RackDto
     */
    position: RackPositionDto;
    /**
     * 
     * @type {RackSizeDto}
     * @memberof RackDto
     */
    size: RackSizeDto;
    /**
     * 
     * @type {Array<ShelfDto>}
     * @memberof RackDto
     */
    shelves: Array<ShelfDto>;
}
/**
 * 
 * @export
 * @interface RackDtoListMimexResponse
 */
export interface RackDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof RackDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<RackDto>}
     * @memberof RackDtoListMimexResponse
     */
    data: Array<RackDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof RackDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface RackDtoMimexResponse
 */
export interface RackDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof RackDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {RackDto}
     * @memberof RackDtoMimexResponse
     */
    data: RackDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof RackDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface RackPositionDto
 */
export interface RackPositionDto {
    /**
     * 
     * @type {number}
     * @memberof RackPositionDto
     */
    rackX0: number;
    /**
     * 
     * @type {number}
     * @memberof RackPositionDto
     */
    rackY0: number;
    /**
     * 
     * @type {number}
     * @memberof RackPositionDto
     */
    rackX1: number;
    /**
     * 
     * @type {number}
     * @memberof RackPositionDto
     */
    rackY1: number;
}
/**
 * 
 * @export
 * @interface RackSizeDto
 */
export interface RackSizeDto {
    /**
     * Rack width in centimeters (cm)
     * @type {number}
     * @memberof RackSizeDto
     */
    width: number;
    /**
     * Rack height in centimeters (cm)
     * @type {number}
     * @memberof RackSizeDto
     */
    height: number;
    /**
     * Rack depth in centimeters (cm)
     * @type {number}
     * @memberof RackSizeDto
     */
    depth: number;
}
/**
 * 
 * @export
 * @interface ReaderDto
 */
export interface ReaderDto {
    /**
     * 
     * @type {string}
     * @memberof ReaderDto
     */
    readerId: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderDto
     */
    readerType: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ReaderDto
     */
    port: string;
    /**
     * 
     * @type {Array<CellDto>}
     * @memberof ReaderDto
     */
    cells: Array<CellDto>;
    /**
     * 
     * @type {ReaderExtraParamsDto}
     * @memberof ReaderDto
     */
    extraParams?: ReaderExtraParamsDto;
}
/**
 * 
 * @export
 * @interface ReaderDtoListMimexResponse
 */
export interface ReaderDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ReaderDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<ReaderDto>}
     * @memberof ReaderDtoListMimexResponse
     */
    data: Array<ReaderDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ReaderDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ReaderDtoMimexResponse
 */
export interface ReaderDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ReaderDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ReaderDto}
     * @memberof ReaderDtoMimexResponse
     */
    data: ReaderDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ReaderDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ReaderExtraParamsDto
 */
export interface ReaderExtraParamsDto {
    /**
     * 
     * @type {number}
     * @memberof ReaderExtraParamsDto
     */
    readInterval?: number;
}
/**
 * 
 * @export
 * @interface ReaderStatusDto
 */
export interface ReaderStatusDto {
    /**
     * 
     * @type {string}
     * @memberof ReaderStatusDto
     */
    readerId: string;
    /**
     * 
     * @type {number}
     * @memberof ReaderStatusDto
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReaderStatusDto
     */
    statusStr: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReaderStatusDto
     */
    cellsStatus: Array<string>;
}
/**
 * 
 * @export
 * @interface ReaderStatusDtoMimexResponse
 */
export interface ReaderStatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ReaderStatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ReaderStatusDto}
     * @memberof ReaderStatusDtoMimexResponse
     */
    data: ReaderStatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ReaderStatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ReceiptDto
 */
export interface ReceiptDto {
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    cartId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDto
     */
    updatedAt: string;
    /**
     * 
     * @type {ReceiptPaymentDto}
     * @memberof ReceiptDto
     */
    payment: ReceiptPaymentDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceiptDto
     */
    products: Array<string>;
}
/**
 * 
 * @export
 * @interface ReceiptDtoListMimexResponse
 */
export interface ReceiptDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ReceiptDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<ReceiptDto>}
     * @memberof ReceiptDtoListMimexResponse
     */
    data: Array<ReceiptDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ReceiptDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ReceiptPaymentDto
 */
export interface ReceiptPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof ReceiptPaymentDto
     */
    totalPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    taxCode: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    cardType: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptPaymentDto
     */
    cardNumber: string;
}
/**
 * 
 * @export
 * @interface ReceiptSummaryDto
 */
export interface ReceiptSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof ReceiptSummaryDto
     */
    year: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptSummaryDto
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptSummaryDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ReceiptSummaryDtoListMimexResponse
 */
export interface ReceiptSummaryDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ReceiptSummaryDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<ReceiptSummaryDto>}
     * @memberof ReceiptSummaryDtoListMimexResponse
     */
    data: Array<ReceiptSummaryDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ReceiptSummaryDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface Reset
 */
export interface Reset {
    /**
     * 
     * @type {string}
     * @memberof Reset
     */
    username: string;
}
/**
 * 
 * @export
 * @interface RfidCartDto
 */
export interface RfidCartDto {
    /**
     * 
     * @type {string}
     * @memberof RfidCartDto
     */
    tagId: string;
    /**
     * 
     * @type {string}
     * @memberof RfidCartDto
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof RfidCartDto
     */
    shelfId: string;
}
/**
 * 
 * @export
 * @interface SearchPaymentQueryDto
 */
export interface SearchPaymentQueryDto {
    /**
     * 
     * @type {string}
     * @memberof SearchPaymentQueryDto
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchPaymentQueryDto
     */
    dateTo?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchPaymentQueryDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPaymentQueryDto
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPaymentQueryDto
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface SearchReceiptsDto
 */
export interface SearchReceiptsDto {
    /**
     * From date, default to 30 days ago
     * @type {string}
     * @memberof SearchReceiptsDto
     */
    from?: string;
    /**
     * From date, default to now
     * @type {string}
     * @memberof SearchReceiptsDto
     */
    to?: string;
    /**
     * Filter by storeId
     * @type {string}
     * @memberof SearchReceiptsDto
     */
    storeId?: string;
    /**
     * Filter by sessionId
     * @type {string}
     * @memberof SearchReceiptsDto
     */
    sessionId?: string;
}
/**
 * 
 * @export
 * @interface SendTrackSessionUpdateDto
 */
export interface SendTrackSessionUpdateDto {
    /**
     * 
     * @type {boolean}
     * @memberof SendTrackSessionUpdateDto
     */
    sendTrackSessionUpdate: boolean;
}
/**
 * 
 * @export
 * @interface SendTrackSessionUpdateDtoMimexResponse
 */
export interface SendTrackSessionUpdateDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof SendTrackSessionUpdateDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {SendTrackSessionUpdateDto}
     * @memberof SendTrackSessionUpdateDtoMimexResponse
     */
    data: SendTrackSessionUpdateDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof SendTrackSessionUpdateDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface SessionEventDto
 */
export interface SessionEventDto {
    /**
     * 
     * @type {string}
     * @memberof SessionEventDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof SessionEventDto
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SessionEventDto
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof SessionEventDto
     */
    created: string;
}
/**
 * 
 * @export
 * @interface SessionEventDtoListMimexResponse
 */
export interface SessionEventDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof SessionEventDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<SessionEventDto>}
     * @memberof SessionEventDtoListMimexResponse
     */
    data: Array<SessionEventDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof SessionEventDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface SessionFindDto
 */
export interface SessionFindDto {
    /**
     * Select a field to use for filtering by a date range (with from / to)
     * @type {string}
     * @memberof SessionFindDto
     */
    dateRangeField?: string;
    /**
     * Filter from date
     * @type {string}
     * @memberof SessionFindDto
     */
    from?: string;
    /**
     * Filter to date
     * @type {string}
     * @memberof SessionFindDto
     */
    to?: string;
    /**
     * Filter by sessionId
     * @type {string}
     * @memberof SessionFindDto
     */
    sessionId?: string;
    /**
     * Filter by storeId
     * @type {string}
     * @memberof SessionFindDto
     */
    storeId?: string;
    /**
     * 
     * @type {ShoppingSessionStatus}
     * @memberof SessionFindDto
     */
    status?: ShoppingSessionStatus;
    /**
     * 
     * @type {ShoppingSessionPhase}
     * @memberof SessionFindDto
     */
    phase?: ShoppingSessionPhase;
    /**
     * Filter sessions that are ended (user completed the shopping session or has been cancelled)
     * @type {boolean}
     * @memberof SessionFindDto
     */
    ended?: boolean;
    /**
     * Limit the number results
     * @type {number}
     * @memberof SessionFindDto
     */
    limit?: number;
    /**
     * Skip a number of results
     * @type {number}
     * @memberof SessionFindDto
     */
    skip?: number;
    /**
     * Filter sessions that are active (not ended)
     * @type {boolean}
     * @memberof SessionFindDto
     */
    active?: boolean;
    /**
     * Define records order
     * @type {object}
     * @memberof SessionFindDto
     */
    sortBy?: object;
    /**
     * Filter by userId
     * @type {string}
     * @memberof SessionFindDto
     */
    userId?: string;
    /**
     * Filter sessions by trackerId
     * @type {number}
     * @memberof SessionFindDto
     */
    trackerId?: number;
}
/**
 * 
 * @export
 * @interface SessionLoggerQueryDto
 */
export interface SessionLoggerQueryDto {
    /**
     * 
     * @type {string}
     * @memberof SessionLoggerQueryDto
     */
    sessionId: string;
    /**
     * Offset in millis before the session started
     * @type {number}
     * @memberof SessionLoggerQueryDto
     */
    startedOffset: number;
    /**
     * Offset in millis after the session ended
     * @type {number}
     * @memberof SessionLoggerQueryDto
     */
    endedOffset: number;
}
/**
 * 
 * @export
 * @interface SessionTrackDto
 */
export interface SessionTrackDto {
    /**
     * 
     * @type {number}
     * @memberof SessionTrackDto
     */
    trackId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionTrackDto
     */
    hasAssociatedST: boolean;
}
/**
 * 
 * @export
 * @interface ShelfDto
 */
export interface ShelfDto {
    /**
     * 
     * @type {string}
     * @memberof ShelfDto
     */
    shelfId: string;
    /**
     * 
     * @type {ShelfPositionDto}
     * @memberof ShelfDto
     */
    position: ShelfPositionDto;
    /**
     * 
     * @type {string}
     * @memberof ShelfDto
     */
    stockId: string;
    /**
     * Reference to the digital reader
     * @type {string}
     * @memberof ShelfDto
     */
    readerId?: string;
    /**
     * Reference to the cell input of the digital reader
     * @type {string}
     * @memberof ShelfDto
     */
    cellId?: string;
}
/**
 * 
 * @export
 * @interface ShelfPositionDto
 */
export interface ShelfPositionDto {
    /**
     * 
     * @type {number}
     * @memberof ShelfPositionDto
     */
    shelfX: number;
    /**
     * 
     * @type {number}
     * @memberof ShelfPositionDto
     */
    shelfY: number;
}
/**
 * 
 * @export
 * @interface ShoppingSessionDto
 */
export interface ShoppingSessionDto {
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    cartId: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    storeId: string;
    /**
     * 
     * @type {Array<SessionTrackDto>}
     * @memberof ShoppingSessionDto
     */
    tracks: Array<SessionTrackDto>;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    started: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    ended: string;
    /**
     * 
     * @type {ShoppingSessionStatus}
     * @memberof ShoppingSessionDto
     */
    status: ShoppingSessionStatus;
    /**
     * 
     * @type {ShoppingSessionPhase}
     * @memberof ShoppingSessionDto
     */
    phase: ShoppingSessionPhase;
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDto
     */
    notes: string;
    /**
     * 
     * @type {SessionEventDto}
     * @memberof ShoppingSessionDto
     */
    events: SessionEventDto;
}
/**
 * 
 * @export
 * @interface ShoppingSessionDtoListMimexResponse
 */
export interface ShoppingSessionDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<ShoppingSessionDto>}
     * @memberof ShoppingSessionDtoListMimexResponse
     */
    data: Array<ShoppingSessionDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ShoppingSessionDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface ShoppingSessionDtoMimexResponse
 */
export interface ShoppingSessionDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof ShoppingSessionDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {ShoppingSessionDto}
     * @memberof ShoppingSessionDtoMimexResponse
     */
    data: ShoppingSessionDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof ShoppingSessionDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ShoppingSessionPhase {
    Checkin = 'CHECKIN',
    InStore = 'IN_STORE',
    Checkout = 'CHECKOUT',
    AfterSale = 'AFTER_SALE',
    Unknown = 'UNKNOWN',
    None = 'NONE',
    Cancelled = 'CANCELLED',
    Closed = 'CLOSED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ShoppingSessionStatus {
    QrcodeAccess = 'QRCODE_ACCESS',
    CovidCheck = 'COVID_CHECK',
    DoorEntranceOpen = 'DOOR_ENTRANCE_OPEN',
    DoorEntranceClosed = 'DOOR_ENTRANCE_CLOSED',
    TrackerEnter = 'TRACKER_ENTER',
    InStore = 'IN_STORE',
    TrackerOnRack = 'TRACKER_ON_RACK',
    TrackerExit = 'TRACKER_EXIT',
    RfidCart = 'RFID_CART',
    DoorExitOpen = 'DOOR_EXIT_OPEN',
    DoorExitClosed = 'DOOR_EXIT_CLOSED',
    PaymentPending = 'PAYMENT_PENDING',
    PaymentOk = 'PAYMENT_OK',
    PaymentErr = 'PAYMENT_ERR',
    ProductReturnRequest = 'PRODUCT_RETURN_REQUEST',
    ProductReturnResult = 'PRODUCT_RETURN_RESULT',
    Unknown = 'UNKNOWN',
    None = 'NONE',
    Cancelled = 'CANCELLED'
}

/**
 * 
 * @export
 * @interface SourceDto
 */
export interface SourceDto {
    /**
     * 
     * @type {CartStatusSource}
     * @memberof SourceDto
     */
    src: CartStatusSource;
    /**
     * 
     * @type {CartStatusOperation}
     * @memberof SourceDto
     */
    op: CartStatusOperation;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    ref: string;
    /**
     * 
     * @type {number}
     * @memberof SourceDto
     */
    createdAt: number;
}
/**
 * 
 * @export
 * @interface StatusDto
 */
export interface StatusDto {
    /**
     * 
     * @type {number}
     * @memberof StatusDto
     */
    statusCode: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatusDto
     */
    readers: Array<string>;
}
/**
 * 
 * @export
 * @interface StatusDtoMimexResponse
 */
export interface StatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {StatusDto}
     * @memberof StatusDtoMimexResponse
     */
    data: StatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StockDto
 */
export interface StockDto {
    /**
     * Stock ID
     * @type {string}
     * @memberof StockDto
     */
    stockId: string;
    /**
     * Store ID
     * @type {string}
     * @memberof StockDto
     */
    storeId?: string;
    /**
     * Rack ID (for type=rack only)
     * @type {string}
     * @memberof StockDto
     */
    rackId?: string;
    /**
     * Product ID
     * @type {string}
     * @memberof StockDto
     */
    productId: string;
    /**
     * Number of items currently available
     * @type {number}
     * @memberof StockDto
     */
    available: number;
    /**
     * Maximum number of items
     * @type {number}
     * @memberof StockDto
     */
    total: number;
    /**
     * A +/- percentage (between 0.0-1.0) of error to match a weight for a product (for type=rack only)
     * @type {number}
     * @memberof StockDto
     */
    weightOffset: number;
    /**
     * A percentage (between 0.0-1.0) of available products on total below which to trigger an alert (for type=rack only)
     * @type {number}
     * @memberof StockDto
     */
    alertPercentage: number;
    /**
     * Date of expiration of the product (for type=rack only)
     * @type {string}
     * @memberof StockDto
     */
    expiryDate?: string;
    /**
     * For sold stocks indicates if it is an history value or not. After maintenance mode is set to false
     * @type {boolean}
     * @memberof StockDto
     */
    current?: boolean;
    /**
     * Track the change history of a stock
     * @type {Array<string>}
     * @memberof StockDto
     */
    history?: Array<string>;
    /**
     * 
     * @type {StockType}
     * @memberof StockDto
     */
    type?: StockType;
}
/**
 * 
 * @export
 * @interface StockDtoListMimexResponse
 */
export interface StockDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StockDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<StockDto>}
     * @memberof StockDtoListMimexResponse
     */
    data: Array<StockDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StockDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StockDtoMimexResponse
 */
export interface StockDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StockDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {StockDto}
     * @memberof StockDtoMimexResponse
     */
    data: StockDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StockDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StockResetDto
 */
export interface StockResetDto {
    /**
     * ID of the stock
     * @type {string}
     * @memberof StockResetDto
     */
    stockId: string;
    /**
     * ID of the reader
     * @type {string}
     * @memberof StockResetDto
     */
    readerId: string;
    /**
     * ID of the cell
     * @type {string}
     * @memberof StockResetDto
     */
    cellId: string;
}
/**
 * 
 * @export
 * @interface StockSearchDto
 */
export interface StockSearchDto {
    /**
     * ID of the stock
     * @type {string}
     * @memberof StockSearchDto
     */
    stockId?: string;
    /**
     * Type of stock
     * @type {string}
     * @memberof StockSearchDto
     */
    type: string;
    /**
     * Product ID
     * @type {string}
     * @memberof StockSearchDto
     */
    productId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StockType {
    Rack = 'RACK',
    Sold = 'SOLD',
    Warehouse = 'WAREHOUSE'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum StoreCurrency {
    Eur = 'eur',
    Try = 'try'
}

/**
 * 
 * @export
 * @interface StoreDto
 */
export interface StoreDto {
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    name: string;
    /**
     * 
     * @type {GeoPoint}
     * @memberof StoreDto
     */
    location: GeoPoint;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    image: string;
    /**
     * 
     * @type {StoreLanguage}
     * @memberof StoreDto
     */
    language: StoreLanguage;
    /**
     * 
     * @type {OpeningSchedule}
     * @memberof StoreDto
     */
    openingSchedule: OpeningSchedule;
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreDto
     */
    status: StoreStatus;
    /**
     * 
     * @type {StoreCurrency}
     * @memberof StoreDto
     */
    currency: StoreCurrency;
    /**
     * 
     * @type {StoreLayout}
     * @memberof StoreDto
     */
    layout: StoreLayout;
}
/**
 * 
 * @export
 * @interface StoreDtoListMimexResponse
 */
export interface StoreDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StoreDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<StoreDto>}
     * @memberof StoreDtoListMimexResponse
     */
    data: Array<StoreDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StoreDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StoreDtoMimexResponse
 */
export interface StoreDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StoreDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {StoreDto}
     * @memberof StoreDtoMimexResponse
     */
    data: StoreDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StoreDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StoreLanguage {
    En = 'en',
    It = 'it',
    Es = 'es',
    Tr = 'tr'
}

/**
 * 
 * @export
 * @interface StoreLayout
 */
export interface StoreLayout {
    /**
     * 
     * @type {DoorLayout}
     * @memberof StoreLayout
     */
    entranceDoor: DoorLayout;
    /**
     * 
     * @type {DoorLayout}
     * @memberof StoreLayout
     */
    exitDoor: DoorLayout;
}
/**
 * 
 * @export
 * @interface StoreResetDto
 */
export interface StoreResetDto {
    /**
     * 
     * @type {string}
     * @memberof StoreResetDto
     */
    storeId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StoreStatus {
    Open = 'OPEN',
    Closed = 'CLOSED',
    Error = 'ERROR',
    Maintenance = 'MAINTENANCE',
    Auto = 'AUTO'
}

/**
 * 
 * @export
 * @interface StoreStatusDto
 */
export interface StoreStatusDto {
    /**
     * 
     * @type {StoreStatus}
     * @memberof StoreStatusDto
     */
    status: StoreStatus;
    /**
     * 
     * @type {boolean}
     * @memberof StoreStatusDto
     */
    openingSchedule: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreStatusDto
     */
    storeId: string;
}
/**
 * 
 * @export
 * @interface StoreStatusDtoMimexResponse
 */
export interface StoreStatusDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StoreStatusDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {StoreStatusDto}
     * @memberof StoreStatusDtoMimexResponse
     */
    data: StoreStatusDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StoreStatusDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StreamingRequestDto
 */
export interface StreamingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof StreamingRequestDto
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof StreamingRequestDto
     */
    camId: string;
    /**
     * 
     * @type {number}
     * @memberof StreamingRequestDto
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof StreamingRequestDto
     */
    height: number;
}
/**
 * 
 * @export
 * @interface StreamingResponseDto
 */
export interface StreamingResponseDto {
    /**
     * 
     * @type {string}
     * @memberof StreamingResponseDto
     */
    camId: string;
    /**
     * 
     * @type {string}
     * @memberof StreamingResponseDto
     */
    streamId: string;
    /**
     * 
     * @type {string}
     * @memberof StreamingResponseDto
     */
    clientId?: string;
}
/**
 * 
 * @export
 * @interface StreamingResponseDtoListMimexResponse
 */
export interface StreamingResponseDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StreamingResponseDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<StreamingResponseDto>}
     * @memberof StreamingResponseDtoListMimexResponse
     */
    data: Array<StreamingResponseDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StreamingResponseDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface StreamingResponseDtoMimexResponse
 */
export interface StreamingResponseDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof StreamingResponseDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {StreamingResponseDto}
     * @memberof StreamingResponseDtoMimexResponse
     */
    data: StreamingResponseDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof StreamingResponseDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface TotemToken
 */
export interface TotemToken {
    /**
     * 
     * @type {string}
     * @memberof TotemToken
     */
    jwtToken: string;
}
/**
 * 
 * @export
 * @interface TotemTokenMimexResponse
 */
export interface TotemTokenMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof TotemTokenMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {TotemToken}
     * @memberof TotemTokenMimexResponse
     */
    data: TotemToken;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof TotemTokenMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface TrackDto
 */
export interface TrackDto {
    /**
     * 
     * @type {number}
     * @memberof TrackDto
     */
    trackId: number;
    /**
     * 
     * @type {TrackPositionDto}
     * @memberof TrackDto
     */
    position: TrackPositionDto;
    /**
     * 
     * @type {number}
     * @memberof TrackDto
     */
    confidence: number;
    /**
     * 
     * @type {object}
     * @memberof TrackDto
     */
    smarTrackTrackState: object;
    /**
     * 
     * @type {string}
     * @memberof TrackDto
     */
    peopleTrackState: string;
    /**
     * 
     * @type {number}
     * @memberof TrackDto
     */
    timestamp: number;
}
/**
 * 
 * @export
 * @interface TrackDtoMimexResponse
 */
export interface TrackDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof TrackDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {TrackDto}
     * @memberof TrackDtoMimexResponse
     */
    data: TrackDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof TrackDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface TrackPositionDto
 */
export interface TrackPositionDto {
    /**
     * 
     * @type {number}
     * @memberof TrackPositionDto
     */
    posX: number;
    /**
     * 
     * @type {number}
     * @memberof TrackPositionDto
     */
    posY: number;
    /**
     * 
     * @type {number}
     * @memberof TrackPositionDto
     */
    posZ: number;
}
/**
 * 
 * @export
 * @interface TrackSearchDto
 */
export interface TrackSearchDto {
    /**
     * 
     * @type {number}
     * @memberof TrackSearchDto
     */
    posX: number;
    /**
     * 
     * @type {number}
     * @memberof TrackSearchDto
     */
    posY: number;
    /**
     * 
     * @type {number}
     * @memberof TrackSearchDto
     */
    posZ: number;
    /**
     * 
     * @type {string}
     * @memberof TrackSearchDto
     */
    method: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrackSearchDto
     */
    filteredTrackIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof TrackSearchDto
     */
    beforeTimeInterval: number;
    /**
     * 
     * @type {number}
     * @memberof TrackSearchDto
     */
    eventTimestamp: number;
}
/**
 * 
 * @export
 * @interface UpdateCardDto
 */
export interface UpdateCardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCardDto
     */
    paymentMethodId: string;
}
/**
 * 
 * @export
 * @interface UserAccountDto
 */
export interface UserAccountDto {
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccountDto
     */
    roles: Array<UserAccountDtoRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    profileId: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    type: UserAccountDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccountDto
     */
    status: UserAccountDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccountDtoRolesEnum {
    Admin = 'admin',
    Manager = 'manager',
    Assistant = 'assistant'
}
/**
    * @export
    * @enum {string}
    */
export enum UserAccountDtoTypeEnum {
    Service = 'service',
    User = 'user'
}
/**
    * @export
    * @enum {string}
    */
export enum UserAccountDtoStatusEnum {
    Enabled = 'enabled',
    Disabled = 'disabled',
    Locked = 'locked',
    Unknown = 'unknown',
    PaymentNeeded = 'payment_needed'
}

/**
 * 
 * @export
 * @interface UserAccountDtoMimexResponse
 */
export interface UserAccountDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof UserAccountDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {UserAccountDto}
     * @memberof UserAccountDtoMimexResponse
     */
    data: UserAccountDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof UserAccountDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface UserCreated
 */
export interface UserCreated {
    /**
     * 
     * @type {string}
     * @memberof UserCreated
     */
    user: string;
}
/**
 * 
 * @export
 * @interface UserCreatedMimexResponse
 */
export interface UserCreatedMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCreatedMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {UserCreated}
     * @memberof UserCreatedMimexResponse
     */
    data: UserCreated;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof UserCreatedMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface UserCredentialsListMimexResponse
 */
export interface UserCredentialsListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCredentialsListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UserCredentialsListMimexResponse
     */
    data: Array<object>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof UserCredentialsListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    emailConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    taxCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    phone: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    termsAndConditions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    gdpr: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    captcha: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    invoice: boolean;
}
/**
 * 
 * @export
 * @interface UserProfileDtoMimexResponse
 */
export interface UserProfileDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof UserProfileDtoMimexResponse
     */
    data: UserProfileDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof UserProfileDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface UserProfileInvoiceDto
 */
export interface UserProfileInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileInvoiceDto
     */
    taxCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileInvoiceDto
     */
    invoice: boolean;
}
/**
 * 
 * @export
 * @interface UserProfileUpdateDto
 */
export interface UserProfileUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDto
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface UserSessionFindDto
 */
export interface UserSessionFindDto {
    /**
     * Select a field to use for filtering by a date range (with from / to)
     * @type {string}
     * @memberof UserSessionFindDto
     */
    dateRangeField?: string;
    /**
     * Filter from date
     * @type {string}
     * @memberof UserSessionFindDto
     */
    from?: string;
    /**
     * Filter to date
     * @type {string}
     * @memberof UserSessionFindDto
     */
    to?: string;
    /**
     * Filter by sessionId
     * @type {string}
     * @memberof UserSessionFindDto
     */
    sessionId?: string;
    /**
     * Filter by storeId
     * @type {string}
     * @memberof UserSessionFindDto
     */
    storeId?: string;
    /**
     * 
     * @type {ShoppingSessionStatus}
     * @memberof UserSessionFindDto
     */
    status?: ShoppingSessionStatus;
    /**
     * 
     * @type {ShoppingSessionPhase}
     * @memberof UserSessionFindDto
     */
    phase?: ShoppingSessionPhase;
    /**
     * Filter sessions that are ended (user completed the shopping session or has been cancelled)
     * @type {boolean}
     * @memberof UserSessionFindDto
     */
    ended?: boolean;
    /**
     * Limit the number results
     * @type {number}
     * @memberof UserSessionFindDto
     */
    limit?: number;
    /**
     * Skip a number of results
     * @type {number}
     * @memberof UserSessionFindDto
     */
    skip?: number;
    /**
     * Filter sessions that are active (not ended)
     * @type {boolean}
     * @memberof UserSessionFindDto
     */
    active?: boolean;
    /**
     * Define records order
     * @type {object}
     * @memberof UserSessionFindDto
     */
    sortBy?: object;
}
/**
 * 
 * @export
 * @interface UserSessionOptions
 */
export interface UserSessionOptions {
    /**
     * 
     * @type {string}
     * @memberof UserSessionOptions
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof UserSessionOptions
     */
    sessions?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserSessionOptions
     */
    exit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserSessionOptions
     */
    productDelay?: number;
}
/**
 * 
 * @export
 * @interface VirtualCartCreateDto
 */
export interface VirtualCartCreateDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartCreateDto
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualCartCreateDto
     */
    cartId: string;
}
/**
 * 
 * @export
 * @interface VirtualCartCreateDtoMimexResponse
 */
export interface VirtualCartCreateDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartCreateDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {VirtualCartCreateDto}
     * @memberof VirtualCartCreateDtoMimexResponse
     */
    data: VirtualCartCreateDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof VirtualCartCreateDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface VirtualCartItemAddDto
 */
export interface VirtualCartItemAddDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemAddDto
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemAddDto
     */
    ref: string;
}
/**
 * 
 * @export
 * @interface VirtualCartItemDto
 */
export interface VirtualCartItemDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemDto
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof VirtualCartItemDto
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemDto
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof VirtualCartItemDto
     */
    unitPrice: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualCartItemDto
     */
    currency: string;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof VirtualCartItemDto
     */
    images: Array<ProductImage>;
    /**
     * 
     * @type {number}
     * @memberof VirtualCartItemDto
     */
    weight: number;
    /**
     * 
     * @type {Array<SourceDto>}
     * @memberof VirtualCartItemDto
     */
    source: Array<SourceDto>;
}
/**
 * 
 * @export
 * @interface VirtualCartResponseDto
 */
export interface VirtualCartResponseDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartResponseDto
     */
    sessionId: string;
    /**
     * 
     * @type {Array<VirtualCartItemDto>}
     * @memberof VirtualCartResponseDto
     */
    items: Array<VirtualCartItemDto>;
}
/**
 * 
 * @export
 * @interface VirtualCartResponseDtoListMimexResponse
 */
export interface VirtualCartResponseDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartResponseDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<VirtualCartResponseDto>}
     * @memberof VirtualCartResponseDtoListMimexResponse
     */
    data: Array<VirtualCartResponseDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof VirtualCartResponseDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface VirtualCartResponseDtoMimexResponse
 */
export interface VirtualCartResponseDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof VirtualCartResponseDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {VirtualCartResponseDto}
     * @memberof VirtualCartResponseDtoMimexResponse
     */
    data: VirtualCartResponseDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof VirtualCartResponseDtoMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @interface VirtualCartSearchDto
 */
export interface VirtualCartSearchDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VirtualCartSearchDto
     */
    cartId?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VirtualCartSearchDto
     */
    sessionId?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VirtualCartSearchDto
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof VirtualCartSearchDto
     */
    limit?: number;
}
/**
 * 
 * @export
 * @interface WebhookDto
 */
export interface WebhookDto {
    /**
     * Webhook Id
     * @type {string}
     * @memberof WebhookDto
     */
    webhookId: string;
    /**
     * Webhook name
     * @type {string}
     * @memberof WebhookDto
     */
    name: string;
    /**
     * URL to perform the request
     * @type {string}
     * @memberof WebhookDto
     */
    url: string;
    /**
     * StoreId events to watch (receive for all store if not provided)
     * @type {string}
     * @memberof WebhookDto
     */
    storeId?: string;
    /**
     * Username used as password for basic auth. Ignored in token auth.
     * @type {string}
     * @memberof WebhookDto
     */
    username?: string;
    /**
     * Secret key added in the payload, used as password for basic auth
     * @type {string}
     * @memberof WebhookDto
     */
    secret?: string;
    /**
     * Secret key added in the payload. Set username:password for type basic auth type
     * @type {string}
     * @memberof WebhookDto
     */
    authType?: string;
    /**
     * Events to be notified
     * @type {Array<WebhookEvent>}
     * @memberof WebhookDto
     */
    events: Array<WebhookEvent>;
}
/**
 * 
 * @export
 * @interface WebhookDtoListMimexResponse
 */
export interface WebhookDtoListMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof WebhookDtoListMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {Array<WebhookDto>}
     * @memberof WebhookDtoListMimexResponse
     */
    data: Array<WebhookDto>;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof WebhookDtoListMimexResponse
     */
    error?: ErrorResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum WebhookEvent {
    Session = 'SESSION',
    Payment = 'PAYMENT'
}

/**
 * 
 * @export
 * @interface WeightDto
 */
export interface WeightDto {
    /**
     * 
     * @type {number}
     * @memberof WeightDto
     */
    weight: number;
}
/**
 * 
 * @export
 * @interface WeightDtoMimexResponse
 */
export interface WeightDtoMimexResponse {
    /**
     * 
     * @type {string}
     * @memberof WeightDtoMimexResponse
     */
    apiVersion?: string;
    /**
     * 
     * @type {WeightDto}
     * @memberof WeightDtoMimexResponse
     */
    data: WeightDto;
    /**
     * 
     * @type {ErrorResponse}
     * @memberof WeightDtoMimexResponse
     */
    error?: ErrorResponse;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Drop content from specfied collections
         * @param {DropCollection} dropCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerDrop: async (dropCollection: DropCollection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropCollection' is not null or undefined
            assertParamExists('adminDatabaseControllerDrop', 'dropCollection', dropCollection)
            const localVarPath = `/admin/db/drop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dropCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export a dump of specified collections
         * @param {ExportCollection} exportCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerExport: async (exportCollection: ExportCollection, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportCollection' is not null or undefined
            assertParamExists('adminDatabaseControllerExport', 'exportCollection', exportCollection)
            const localVarPath = `/admin/db/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import records from a file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerImport: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/db/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import records from an env context
         * @param {string} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerImportEnv: async (env: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'env' is not null or undefined
            assertParamExists('adminDatabaseControllerImportEnv', 'env', env)
            const localVarPath = `/admin/db/import/{env}`
                .replace(`{${"env"}}`, encodeURIComponent(String(env)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger a recording from an env context
         * @param {string} env 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerTriggerEnv: async (env: string, recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'env' is not null or undefined
            assertParamExists('adminDatabaseControllerTriggerEnv', 'env', env)
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('adminDatabaseControllerTriggerEnv', 'recordingId', recordingId)
            const localVarPath = `/admin/db/trigger/{env}/{recordingId}`
                .replace(`{${"env"}}`, encodeURIComponent(String(env)))
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a running playback script
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerCancelScript: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('adminPlaybackControllerCancelScript', 'uuid', uuid)
            const localVarPath = `/admin/playback/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel all running playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerCancelScripts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/playback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerListScripts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/playback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run a playback script
         * @param {PlaybackOptions} playbackOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerRunScript: async (playbackOptions: PlaybackOptions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'playbackOptions' is not null or undefined
            assertParamExists('adminPlaybackControllerRunScript', 'playbackOptions', playbackOptions)
            const localVarPath = `/admin/playback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playbackOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close user session
         * @param {CloseSessionOptions} closeSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerCloseSession: async (closeSessionOptions: CloseSessionOptions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'closeSessionOptions' is not null or undefined
            assertParamExists('adminScriptsControllerCloseSession', 'closeSessionOptions', closeSessionOptions)
            const localVarPath = `/admin/scripts/session/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closeSessionOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open user session
         * @param {OpenSessionOptions} openSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerOpenSession: async (openSessionOptions: OpenSessionOptions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'openSessionOptions' is not null or undefined
            assertParamExists('adminScriptsControllerOpenSession', 'openSessionOptions', openSessionOptions)
            const localVarPath = `/admin/scripts/session/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(openSessionOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run a complete user session
         * @param {UserSessionOptions} userSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerUserSession: async (userSessionOptions: UserSessionOptions, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSessionOptions' is not null or undefined
            assertParamExists('adminScriptsControllerUserSession', 'userSessionOptions', userSessionOptions)
            const localVarPath = `/admin/scripts/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSessionOptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an API key
         * @param {ApiKeyDto} apiKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyControllerCreate: async (apiKeyDto: ApiKeyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyDto' is not null or undefined
            assertParamExists('apiKeyControllerCreate', 'apiKeyDto', apiKeyDto)
            const localVarPath = `/auth/apikey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiKeyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an API key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyControllerDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKeyControllerDelete', 'id', id)
            const localVarPath = `/auth/apikey/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsControllerRemove', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an asset to storage
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerSave: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerServe: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetsControllerServe', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new user
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerAddUser: async (authAdminUserCreateDto: AuthAdminUserCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAdminUserCreateDto' is not null or undefined
            assertParamExists('authAdminControllerAddUser', 'authAdminUserCreateDto', authAdminUserCreateDto)
            const localVarPath = `/auth/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAdminUserCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a user token by ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerCreateToken: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authAdminControllerCreateToken', 'userId', userId)
            const localVarPath = `/auth/admin/user/{userId}/token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete users by email or uid
         * @param {number} keepAccount 
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerDeleteUsers: async (keepAccount: number, authAdminUsersListQuery: AuthAdminUsersListQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'keepAccount' is not null or undefined
            assertParamExists('authAdminControllerDeleteUsers', 'keepAccount', keepAccount)
            // verify required parameter 'authAdminUsersListQuery' is not null or undefined
            assertParamExists('authAdminControllerDeleteUsers', 'authAdminUsersListQuery', authAdminUsersListQuery)
            const localVarPath = `/auth/admin/user/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keepAccount !== undefined) {
                localVarQueryParameter['keepAccount'] = keepAccount;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAdminUsersListQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable a user by ID
         * @param {string} userId 
         * @param {AuthAdminFirebaseEnableUserDto} authAdminFirebaseEnableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerEnable: async (userId: string, authAdminFirebaseEnableUserDto: AuthAdminFirebaseEnableUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authAdminControllerEnable', 'userId', userId)
            // verify required parameter 'authAdminFirebaseEnableUserDto' is not null or undefined
            assertParamExists('authAdminControllerEnable', 'authAdminFirebaseEnableUserDto', authAdminFirebaseEnableUserDto)
            const localVarPath = `/auth/admin/user/{userId}/enable`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAdminFirebaseEnableUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a user profile by user ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerGetProfile: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authAdminControllerGetProfile', 'userId', userId)
            const localVarPath = `/auth/admin/user/{userId}/profile`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all users
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerListUsers: async (authAdminUsersListQuery: AuthAdminUsersListQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAdminUsersListQuery' is not null or undefined
            assertParamExists('authAdminControllerListUsers', 'authAdminUsersListQuery', authAdminUsersListQuery)
            const localVarPath = `/auth/admin/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAdminUsersListQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user by ID
         * @param {string} userId 
         * @param {number} keepAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerRemoveUser: async (userId: string, keepAccount: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authAdminControllerRemoveUser', 'userId', userId)
            // verify required parameter 'keepAccount' is not null or undefined
            assertParamExists('authAdminControllerRemoveUser', 'keepAccount', keepAccount)
            const localVarPath = `/auth/admin/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keepAccount !== undefined) {
                localVarQueryParameter['keepAccount'] = keepAccount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing user. Creates a local account record if missing
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerUpdateUser: async (authAdminUserCreateDto: AuthAdminUserCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authAdminUserCreateDto' is not null or undefined
            assertParamExists('authAdminControllerUpdateUser', 'authAdminUserCreateDto', authAdminUserCreateDto)
            const localVarPath = `/auth/admin/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authAdminUserCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the account for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the account for the logged-in user
         * @param {UserAccountDto} userAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdateUser: async (userAccountDto: UserAccountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAccountDto' is not null or undefined
            assertParamExists('authControllerUpdateUser', 'userAccountDto', userAccountDto)
            const localVarPath = `/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedule automatic payment by session ID
         * @param {AutomaticPaymentDto} automaticPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automaticPaymentControllerStartAutomaticPayment: async (automaticPaymentDto: AutomaticPaymentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'automaticPaymentDto' is not null or undefined
            assertParamExists('automaticPaymentControllerStartAutomaticPayment', 'automaticPaymentDto', automaticPaymentDto)
            const localVarPath = `/automatic/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(automaticPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the state for a recording (eg. record, play, pause)
         * @param {string} recordingId 
         * @param {'record' | 'stop' | 'play' | 'flush'} logOperation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerControl: async (recordingId: string, logOperation: 'record' | 'stop' | 'play' | 'flush', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerControl', 'recordingId', recordingId)
            // verify required parameter 'logOperation' is not null or undefined
            assertParamExists('brokerLoggerControllerControl', 'logOperation', logOperation)
            const localVarPath = `/broker-logger/recording/{recordingId}/control/{logOperation}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)))
                .replace(`{${"logOperation"}}`, encodeURIComponent(String(logOperation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new recording. Also store recording items if provided
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerCreate: async (logRecordingDto: LogRecordingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logRecordingDto' is not null or undefined
            assertParamExists('brokerLoggerControllerCreate', 'logRecordingDto', logRecordingDto)
            const localVarPath = `/broker-logger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRecordingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerDelete: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerDelete', 'recordingId', recordingId)
            const localVarPath = `/broker-logger/recording/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerGet: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerGet', 'recordingId', recordingId)
            const localVarPath = `/broker-logger/recording/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve items for a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerGetItems: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerGetItems', 'recordingId', recordingId)
            const localVarPath = `/broker-logger/recording/{recordingId}/events`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/broker-logger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove items from a recording
         * @param {string} recordingId 
         * @param {LogItemRemoveQueryDto} logItemRemoveQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerRemoveItems: async (recordingId: string, logItemRemoveQueryDto: LogItemRemoveQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerRemoveItems', 'recordingId', recordingId)
            // verify required parameter 'logItemRemoveQueryDto' is not null or undefined
            assertParamExists('brokerLoggerControllerRemoveItems', 'logItemRemoveQueryDto', logItemRemoveQueryDto)
            const localVarPath = `/broker-logger/recording/{recordingId}/remove`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logItemRemoveQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger an event as provided via payload
         * @param {LogItemDto} logItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerTriggerEvent: async (logItemDto: LogItemDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logItemDto' is not null or undefined
            assertParamExists('brokerLoggerControllerTriggerEvent', 'logItemDto', logItemDto)
            const localVarPath = `/broker-logger/trigger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a recording
         * @param {string} recordingId 
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerUpdate: async (recordingId: string, logRecordingDto: LogRecordingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('brokerLoggerControllerUpdate', 'recordingId', recordingId)
            // verify required parameter 'logRecordingDto' is not null or undefined
            assertParamExists('brokerLoggerControllerUpdate', 'logRecordingDto', logRecordingDto)
            const localVarPath = `/broker-logger/recording/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logRecordingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all products in the catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerDeleteAllProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalog/product/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download catalog as CSV
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerExportCatalog: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogControllerExportCatalog', 'storeId', storeId)
            const localVarPath = `/catalog/{storeId}/download`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all store products in the catalog
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerGetAllProductsByStore: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogControllerGetAllProductsByStore', 'storeId', storeId)
            const localVarPath = `/catalog/{storeId}/product/all`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a product from the catalog
         * @param {string} storeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerGetProduct: async (storeId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogControllerGetProduct', 'storeId', storeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogControllerGetProduct', 'id', id)
            const localVarPath = `/catalog/{storeId}/product/{id}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import catalog from CSV
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerImportCatalog: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/catalog/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerInsertProduct: async (productDto: ProductDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productDto' is not null or undefined
            assertParamExists('catalogControllerInsertProduct', 'productDto', productDto)
            const localVarPath = `/catalog/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a product in the catalog
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerRemoveProduct: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('catalogControllerRemoveProduct', 'id', id)
            const localVarPath = `/catalog/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search store products in the catalog by name
         * @param {string} storeId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerSearchProducts: async (storeId: string, q: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogControllerSearchProducts', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('catalogControllerSearchProducts', 'q', q)
            const localVarPath = `/catalog/{storeId}/product/search`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search store products in the catalog
         * @param {string} storeId 
         * @param {ProductSearchDto} productSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerSearchProductsFilter: async (storeId: string, productSearchDto: ProductSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('catalogControllerSearchProductsFilter', 'storeId', storeId)
            // verify required parameter 'productSearchDto' is not null or undefined
            assertParamExists('catalogControllerSearchProductsFilter', 'productSearchDto', productSearchDto)
            const localVarPath = `/catalog/{storeId}/product/search`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerUpdateProduct: async (productDto: ProductDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productDto' is not null or undefined
            assertParamExists('catalogControllerUpdateProduct', 'productDto', productDto)
            const localVarPath = `/catalog/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset the number of people in the store to zero
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerCheckoutAll: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkinControllerCheckoutAll', 'storeId', storeId)
            const localVarPath = `/checkin/empty/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new access QR code
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerCreateQRcode: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkinControllerCreateQRcode', 'storeId', storeId)
            const localVarPath = `/checkin/create/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve checkin status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerStatus: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkinControllerStatus', 'storeId', storeId)
            const localVarPath = `/checkin/status/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate user provided QR code at checkin
         * @param {TotemToken} totemToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerValidateQRcode: async (totemToken: TotemToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'totemToken' is not null or undefined
            assertParamExists('checkinControllerValidateQRcode', 'totemToken', totemToken)
            const localVarPath = `/checkin/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(totemToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a stream source for the camera
         * @param {PlayRequestDto} playRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerSetSource: async (playRequestDto: PlayRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'playRequestDto' is not null or undefined
            assertParamExists('detectorStreamingControllerSetSource', 'playRequestDto', playRequestDto)
            const localVarPath = `/detector/play`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(playRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a recording on the camera stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStartRecord: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/detector/camera`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop a recording instance
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStopRecording: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('detectorStreamingControllerStopRecording', 'recordingId', recordingId)
            const localVarPath = `/detector/camera/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop all recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStopRecordings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/detector/camera`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the camera stream
         * @param {string} detector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStream: async (detector: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'detector' is not null or undefined
            assertParamExists('detectorStreamingControllerStream', 'detector', detector)
            const localVarPath = `/detector/camera`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (detector !== undefined) {
                localVarQueryParameter['detector'] = detector;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the status of a door [open, closed]
         * @param {'entrance' | 'exit'} doorType Door type [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorControllerDoorStatus: async (doorType: 'entrance' | 'exit', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorType' is not null or undefined
            assertParamExists('doorControllerDoorStatus', 'doorType', doorType)
            const localVarPath = `/door/status/{doorType}`
                .replace(`{${"doorType"}}`, encodeURIComponent(String(doorType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the status of a door controller
         * @param {string} storeId ID of the store
         * @param {'entrance' | 'exit'} doorType The type of the door [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorControllerOpenDoor: async (storeId: string, doorType: 'entrance' | 'exit', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('doorControllerOpenDoor', 'storeId', storeId)
            // verify required parameter 'doorType' is not null or undefined
            assertParamExists('doorControllerOpenDoor', 'doorType', doorType)
            const localVarPath = `/door/open/{storeId}/{doorType}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"doorType"}}`, encodeURIComponent(String(doorType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login a user by credentials returning an access token
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerGetJWT: async (login: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginControllerGetJWT', 'login', login)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a new token for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerRefreshToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request the password reset for an user
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerResetPassword: async (reset: Reset, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reset' is not null or undefined
            assertParamExists('loginControllerResetPassword', 'reset', reset)
            const localVarPath = `/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a job to the worker
         * @param {MLJobRequestDto} mLJobRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerAdd: async (mLJobRequestDto: MLJobRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mLJobRequestDto' is not null or undefined
            assertParamExists('mLManagerControllerAdd', 'mLJobRequestDto', mLJobRequestDto)
            const localVarPath = `/ml-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mLJobRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List worker jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ml-manager/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a job from the worker
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mLManagerControllerRemove', 'id', id)
            const localVarPath = `/ml-manager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes a single image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerUploadFile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ml-manager/process_image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelDetectorParameters: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerDelDetectorParameters', 'modelid', modelid)
            const localVarPath = `/ml-repository/detector/parameters/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelDetectorTraining: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerDelDetectorTraining', 'modelid', modelid)
            const localVarPath = `/ml-repository/detector/training/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderInference: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerDelEmbedderInference', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/inference/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderParameters: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerDelEmbedderParameters', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/parameters/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderTraining: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerDelEmbedderTraining', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/training/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllDetectorraining: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerGetAllDetectorraining', 'modelid', modelid)
            const localVarPath = `/ml-repository/detector/training/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllEmbedderInference: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerGetAllEmbedderInference', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/inference/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllEmbedderTraining: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerGetAllEmbedderTraining', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/training/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetDetectorParameters: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerGetDetectorParameters', 'modelid', modelid)
            const localVarPath = `/ml-repository/detector/parameters/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderInferenceProductId: async (productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('mLRepositoryControllerGetEmbedderInferenceProductId', 'productId', productId)
            const localVarPath = `/ml-repository/embedder/inference/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderParameters: async (modelid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelid' is not null or undefined
            assertParamExists('mLRepositoryControllerGetEmbedderParameters', 'modelid', modelid)
            const localVarPath = `/ml-repository/embedder/parameters/{modelid}`
                .replace(`{${"modelid"}}`, encodeURIComponent(String(modelid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderProductIdFromLabel: async (label: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'label' is not null or undefined
            assertParamExists('mLRepositoryControllerGetEmbedderProductIdFromLabel', 'label', label)
            const localVarPath = `/ml-repository/embedder/inference/label/{label}`
                .replace(`{${"label"}}`, encodeURIComponent(String(label)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MLDetectorParametersDto} mLDetectorParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutDetectorParameters: async (mLDetectorParametersDto: MLDetectorParametersDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mLDetectorParametersDto' is not null or undefined
            assertParamExists('mLRepositoryControllerPutDetectorParameters', 'mLDetectorParametersDto', mLDetectorParametersDto)
            const localVarPath = `/ml-repository/detector/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mLDetectorParametersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutDetectorTraining: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('mLRepositoryControllerPutDetectorTraining', 'requestBody', requestBody)
            const localVarPath = `/ml-repository/detector/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MLEmbedderInferenceDto} mLEmbedderInferenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderInference: async (mLEmbedderInferenceDto: MLEmbedderInferenceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mLEmbedderInferenceDto' is not null or undefined
            assertParamExists('mLRepositoryControllerPutEmbedderInference', 'mLEmbedderInferenceDto', mLEmbedderInferenceDto)
            const localVarPath = `/ml-repository/embedder/inference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mLEmbedderInferenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MLEmbedderParametersDto} mLEmbedderParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderParameters: async (mLEmbedderParametersDto: MLEmbedderParametersDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mLEmbedderParametersDto' is not null or undefined
            assertParamExists('mLRepositoryControllerPutEmbedderParameters', 'mLEmbedderParametersDto', mLEmbedderParametersDto)
            const localVarPath = `/ml-repository/embedder/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mLEmbedderParametersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderTraining: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('mLRepositoryControllerPutEmbedderTraining', 'requestBody', requestBody)
            const localVarPath = `/ml-repository/embedder/training`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate user ACL on subscription
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mqttAuthControllerGetAcl: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('mqttAuthControllerGetAcl', 'body', body)
            const localVarPath = `/mqtt/acl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate user authentication on connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mqttAuthControllerGetUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mqtt/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set notification to read
         * @param {string} storeId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAsRead: async (storeId: string, notificationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('notificationControllerMarkAsRead', 'storeId', storeId)
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('notificationControllerMarkAsRead', 'notificationId', notificationId)
            const localVarPath = `/notification/{storeId}/read`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notificationId !== undefined) {
                localVarQueryParameter['notificationId'] = notificationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete All notifications from store
         * @param {string} storeId ID of the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRemoveAll: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('notificationControllerRemoveAll', 'storeId', storeId)
            const localVarPath = `/notification/{storeId}/all`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete All notifications by Type
         * @param {string} storeId ID of the store
         * @param {'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace'} message The type of notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRemoveAllByType: async (storeId: string, message: 'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('notificationControllerRemoveAllByType', 'storeId', storeId)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('notificationControllerRemoveAllByType', 'message', message)
            const localVarPath = `/notification/{storeId}/{message}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"message"}}`, encodeURIComponent(String(message)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve notifications for a store
         * @param {string} storeId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerStatus: async (storeId: string, limit?: number, skip?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('notificationControllerStatus', 'storeId', storeId)
            const localVarPath = `/notification/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a mock payment method
         * @param {string} userId 
         * @param {number} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminCreateMockPaymentMethod: async (userId: string, force: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('paymentControllerAdminCreateMockPaymentMethod', 'userId', userId)
            // verify required parameter 'force' is not null or undefined
            assertParamExists('paymentControllerAdminCreateMockPaymentMethod', 'force', force)
            const localVarPath = `/payment/admin/test/payment-method/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export payments as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportPaymentsCSV: async (searchPaymentQueryDto: SearchPaymentQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPaymentQueryDto' is not null or undefined
            assertParamExists('paymentControllerAdminExportPaymentsCSV', 'searchPaymentQueryDto', searchPaymentQueryDto)
            const localVarPath = `/payment/admin/export_payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPaymentQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export payments as JSON
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportPaymentsJSON: async (searchPaymentQueryDto: SearchPaymentQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPaymentQueryDto' is not null or undefined
            assertParamExists('paymentControllerAdminExportPaymentsJSON', 'searchPaymentQueryDto', searchPaymentQueryDto)
            const localVarPath = `/payment/admin/export/json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPaymentQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export sold products as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportSoldProductsCSV: async (searchPaymentQueryDto: SearchPaymentQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPaymentQueryDto' is not null or undefined
            assertParamExists('paymentControllerAdminExportSoldProductsCSV', 'searchPaymentQueryDto', searchPaymentQueryDto)
            const localVarPath = `/payment/admin/export_sold_products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPaymentQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export user sessions as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportUserSessionsCSV: async (searchPaymentQueryDto: SearchPaymentQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPaymentQueryDto' is not null or undefined
            assertParamExists('paymentControllerAdminExportUserSessionsCSV', 'searchPaymentQueryDto', searchPaymentQueryDto)
            const localVarPath = `/payment/admin/export_user_sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPaymentQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for payments
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminSearch: async (searchPaymentQueryDto: SearchPaymentQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPaymentQueryDto' is not null or undefined
            assertParamExists('paymentControllerAdminSearch', 'searchPaymentQueryDto', searchPaymentQueryDto)
            const localVarPath = `/payment/admin/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPaymentQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle BKM payment result
         * @param {BKMPaymentResultDto} bKMPaymentResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerBkmPaymentResult: async (bKMPaymentResultDto: BKMPaymentResultDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bKMPaymentResultDto' is not null or undefined
            assertParamExists('paymentControllerBkmPaymentResult', 'bKMPaymentResultDto', bKMPaymentResultDto)
            const localVarPath = `/payment/bkm/payment_done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bKMPaymentResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Handle BKM payment setup result
         * @param {BKMPaymentSetupResultDto} bKMPaymentSetupResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerBkmPaymentSetupResult: async (bKMPaymentSetupResultDto: BKMPaymentSetupResultDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bKMPaymentSetupResultDto' is not null or undefined
            assertParamExists('paymentControllerBkmPaymentSetupResult', 'bKMPaymentSetupResultDto', bKMPaymentSetupResultDto)
            const localVarPath = `/payment/bkm/payment_added`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bKMPaymentSetupResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Setup Stripe to register a credit card for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreateCardSecret: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/cards/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Stripe payment intent
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreatePaymentIntent: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('paymentControllerCreatePaymentIntent', 'sessionId', sessionId)
            const localVarPath = `/payment/intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a payment methods references
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerDeleteCardSecret: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('paymentControllerDeleteCardSecret', 'id', id)
            const localVarPath = `/payment/cards/wallet/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve Stripe registered payment methods references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetCardSecret: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/cards/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve payment amount
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetPaymentAmount: async (sessionid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionid' is not null or undefined
            assertParamExists('paymentControllerGetPaymentAmount', 'sessionid', sessionid)
            const localVarPath = `/payment/amount/{sessionid}`
                .replace(`{${"sessionid"}}`, encodeURIComponent(String(sessionid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List payment methods
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerListPayments: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('paymentControllerListPayments', 'sessionId', sessionId)
            const localVarPath = `/payment/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for payment methods
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerListPaymentsFiltered: async (period: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'period' is not null or undefined
            assertParamExists('paymentControllerListPaymentsFiltered', 'period', period)
            const localVarPath = `/payment/list/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Stripe payment as suceeded
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerPaymentSucceeded: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('paymentControllerPaymentSucceeded', 'sessionId', sessionId)
            const localVarPath = `/payment/succeeded`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Stripe credit card reference for a user
         * @param {UpdateCardDto} updateCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerUpdateCardSecret: async (updateCardDto: UpdateCardDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCardDto' is not null or undefined
            assertParamExists('paymentControllerUpdateCardSecret', 'updateCardDto', updateCardDto)
            const localVarPath = `/payment/cards/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restream smartrack events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerActivateSmartrackRestreamFromBroker: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smartrack-restream-from-brocker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop smartrack restream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerDeactivateSmartrackRestreamFromBroker: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smartrack-restream-from-brocker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tracks events publishing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetPublishSmartrackStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/publish-smartrack-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find tracks by positions
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetTrackByPosition: async (trackSearchDto: TrackSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackSearchDto' is not null or undefined
            assertParamExists('peopleTrackerControllerGetTrackByPosition', 'trackSearchDto', trackSearchDto)
            const localVarPath = `/people-tracker/findbyposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve tracks positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetTrackPositions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/track_positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerPublishPeopleTrackerTracks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/publish-people-tracker-tracks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerPublishSmartrackEvent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/publish-smartrack-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset people tracker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerResetPeopleTracker: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/reset-people-tracker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerStopPublishingSmartrackEvent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/publish-smartrack-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stops publishing people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerUnpublishPeopleTrackerTracks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/publish-people-tracker-tracks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerDeleteProductPrice: async (storeId: string, productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('pricingControllerDeleteProductPrice', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('pricingControllerDeleteProductPrice', 'productId', productId)
            const localVarPath = `/catalog/pricing/{storeId}/product/{productId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create store product pricing
         * @param {string} storeId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerInsertProductPrice: async (storeId: string, productPriceDto: ProductPriceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('pricingControllerInsertProductPrice', 'storeId', storeId)
            // verify required parameter 'productPriceDto' is not null or undefined
            assertParamExists('pricingControllerInsertProductPrice', 'productPriceDto', productPriceDto)
            const localVarPath = `/catalog/pricing/{storeId}/product`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerSearchProducts: async (storeId: string, productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('pricingControllerSearchProducts', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('pricingControllerSearchProducts', 'productId', productId)
            const localVarPath = `/catalog/pricing/{storeId}/product/{productId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerUpdateProductPrice: async (storeId: string, productId: string, productPriceDto: ProductPriceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('pricingControllerUpdateProductPrice', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('pricingControllerUpdateProductPrice', 'productId', productId)
            // verify required parameter 'productPriceDto' is not null or undefined
            assertParamExists('pricingControllerUpdateProductPrice', 'productPriceDto', productPriceDto)
            const localVarPath = `/catalog/pricing/{storeId}/product/{productId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPriceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the user profile for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the user profile for the logged-in user
         * @param {UserProfileUpdateDto} userProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerSave: async (userProfileUpdateDto: UserProfileUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileUpdateDto' is not null or undefined
            assertParamExists('profileControllerSave', 'userProfileUpdateDto', userProfileUpdateDto)
            const localVarPath = `/auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the invoicing information for the logged-in user
         * @param {UserProfileInvoiceDto} userProfileInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerSaveInvoice: async (userProfileInvoiceDto: UserProfileInvoiceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileInvoiceDto' is not null or undefined
            assertParamExists('profileControllerSaveInvoice', 'userProfileInvoiceDto', userProfileInvoiceDto)
            const localVarPath = `/auth/profile/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put out of place product
         * @param {OutOfPlaceDto} outOfPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerAddOutOfPlace: async (outOfPlaceDto: OutOfPlaceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outOfPlaceDto' is not null or undefined
            assertParamExists('racksControllerAddOutOfPlace', 'outOfPlaceDto', outOfPlaceDto)
            const localVarPath = `/racks/oop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(outOfPlaceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new rack
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerCreateRack: async (rackDto: RackDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rackDto' is not null or undefined
            assertParamExists('racksControllerCreateRack', 'rackDto', rackDto)
            const localVarPath = `/racks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerDeleteAllRacks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/racks/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerDeleteRackById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('racksControllerDeleteRackById', 'id', id)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetAllRacks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/racks/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get shelf out of place products
         * @param {string} shelfid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetOutOfPlace: async (shelfid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shelfid' is not null or undefined
            assertParamExists('racksControllerGetOutOfPlace', 'shelfid', shelfid)
            const localVarPath = `/racks/oop/{shelfid}`
                .replace(`{${"shelfid"}}`, encodeURIComponent(String(shelfid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find a rack by cell ID
         * @param {string} cellId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetRackByCellId: async (cellId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cellId' is not null or undefined
            assertParamExists('racksControllerGetRackByCellId', 'cellId', cellId)
            const localVarPath = `/racks/getbycellid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cellId !== undefined) {
                localVarQueryParameter['cellId'] = cellId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetRackById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('racksControllerGetRackById', 'id', id)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove out of place product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerRemoveOutOfPlace: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('racksControllerRemoveOutOfPlace', 'id', id)
            const localVarPath = `/racks/oop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset stock status for all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerResetAllRacksStockAvailability: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/racks/all/reset-stocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset stock status for a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerResetStockAvailability: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('racksControllerResetStockAvailability', 'id', id)
            const localVarPath = `/racks/{id}/reset-stocks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for a rack by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerSearchRacks: async (q: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('racksControllerSearchRacks', 'q', q)
            const localVarPath = `/racks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a rack by ID
         * @param {string} id 
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerUpdateRack: async (id: string, rackDto: RackDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('racksControllerUpdateRack', 'id', id)
            // verify required parameter 'rackDto' is not null or undefined
            assertParamExists('racksControllerUpdateRack', 'rackDto', rackDto)
            const localVarPath = `/racks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reader health status by ID
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCheckReaderHealthStatusById: async (readerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('readersControllerCheckReaderHealthStatusById', 'readerId', readerId)
            const localVarPath = `/readers/status/health/{readerId}`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get readers health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCheckReadersHealthStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/status/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCreateReader: async (readerDto: ReaderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerDto' is not null or undefined
            assertParamExists('readersControllerCreateReader', 'readerDto', readerDto)
            const localVarPath = `/readers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerDeleteAllReaders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerDeleteReaderById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readersControllerDeleteReaderById', 'id', id)
            const localVarPath = `/readers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerExportReadersConfigurations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/data/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetAllReaders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calibration data for a reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetCalibrationByReaderId: async (readerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('readersControllerGetCalibrationByReaderId', 'readerId', readerId)
            const localVarPath = `/readers/{readerId}/calibration`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetReaderById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readersControllerGetReaderById', 'id', id)
            const localVarPath = `/readers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerImportReadersConfigurations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/readers/data/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search readers by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerSearchReaders: async (q: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('readersControllerSearchReaders', 'q', q)
            const localVarPath = `/readers/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update calibration data for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CalibDto} calibDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateCellCalib: async (readerId: string, cellId: string, calibDto: CalibDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('readersControllerUpdateCellCalib', 'readerId', readerId)
            // verify required parameter 'cellId' is not null or undefined
            assertParamExists('readersControllerUpdateCellCalib', 'cellId', cellId)
            // verify required parameter 'calibDto' is not null or undefined
            assertParamExists('readersControllerUpdateCellCalib', 'calibDto', calibDto)
            const localVarPath = `/readers/{readerId}/{cellId}/calib`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)))
                .replace(`{${"cellId"}}`, encodeURIComponent(String(cellId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calibDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cell params for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CellExtraParamsDto} cellExtraParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateCellExtraParams: async (readerId: string, cellId: string, cellExtraParamsDto: CellExtraParamsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerId' is not null or undefined
            assertParamExists('readersControllerUpdateCellExtraParams', 'readerId', readerId)
            // verify required parameter 'cellId' is not null or undefined
            assertParamExists('readersControllerUpdateCellExtraParams', 'cellId', cellId)
            // verify required parameter 'cellExtraParamsDto' is not null or undefined
            assertParamExists('readersControllerUpdateCellExtraParams', 'cellExtraParamsDto', cellExtraParamsDto)
            const localVarPath = `/readers/{readerId}/{cellId}/params`
                .replace(`{${"readerId"}}`, encodeURIComponent(String(readerId)))
                .replace(`{${"cellId"}}`, encodeURIComponent(String(cellId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cellExtraParamsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateReader: async (readerDto: ReaderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerDto' is not null or undefined
            assertParamExists('readersControllerUpdateReader', 'readerDto', readerDto)
            const localVarPath = `/readers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordingControllerRemove: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaming/recording`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a new user
         * @param {UserProfileDto} userProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerRegistrationProcess: async (userProfileDto: UserProfileDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileDto' is not null or undefined
            assertParamExists('registrationControllerRegistrationProcess', 'userProfileDto', userProfileDto)
            const localVarPath = `/auth/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerUnregistrationProcess: async (login: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('registrationControllerUnregistrationProcess', 'login', login)
            const localVarPath = `/auth/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start the restream
         * @param {PeopleTrackerTestDto} peopleTrackerTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restreamControllerStart: async (peopleTrackerTestDto: PeopleTrackerTestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'peopleTrackerTestDto' is not null or undefined
            assertParamExists('restreamControllerStart', 'peopleTrackerTestDto', peopleTrackerTestDto)
            const localVarPath = `/admin/people-tracker/restream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peopleTrackerTestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create item in RFID product mapping
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerCreateItem: async (rfidCartDto: RfidCartDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rfidCartDto' is not null or undefined
            assertParamExists('rfidCartControllerCreateItem', 'rfidCartDto', rfidCartDto)
            const localVarPath = `/rfid-cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rfidCartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove item from RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerDeleteItem: async (tagId: string, rfidCartDto: RfidCartDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('rfidCartControllerDeleteItem', 'tagId', tagId)
            // verify required parameter 'rfidCartDto' is not null or undefined
            assertParamExists('rfidCartControllerDeleteItem', 'rfidCartDto', rfidCartDto)
            const localVarPath = `/rfid-cart/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rfidCartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item by RFID tag
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerGetItemByTag: async (tagId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('rfidCartControllerGetItemByTag', 'tagId', tagId)
            const localVarPath = `/rfid-cart/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import batch RFID product mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerImportCatalog: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rfid-cart/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update item in RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerUpdateItem: async (tagId: string, rfidCartDto: RfidCartDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('rfidCartControllerUpdateItem', 'tagId', tagId)
            // verify required parameter 'rfidCartDto' is not null or undefined
            assertParamExists('rfidCartControllerUpdateItem', 'rfidCartDto', rfidCartDto)
            const localVarPath = `/rfid-cart/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rfidCartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove sessions-related items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionLoggerControllerFlush: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/broker-logger/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for sessions-related items
         * @param {SessionLoggerQueryDto} sessionLoggerQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionLoggerControllerSearch: async (sessionLoggerQueryDto: SessionLoggerQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionLoggerQueryDto' is not null or undefined
            assertParamExists('sessionLoggerControllerSearch', 'sessionLoggerQueryDto', sessionLoggerQueryDto)
            const localVarPath = `/broker-logger/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionLoggerQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerAdminSearch: async (sessionFindDto: SessionFindDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionFindDto' is not null or undefined
            assertParamExists('shoppingControllerAdminSearch', 'sessionFindDto', sessionFindDto)
            const localVarPath = `/shopping/admin/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionFindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel all open sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerCancelOpen: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping/admin/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a session
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerCreate: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('shoppingControllerCreate', 'body', body)
            const localVarPath = `/shopping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerDelete: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('shoppingControllerDelete', 'sessionId', sessionId)
            const localVarPath = `/shopping/admin/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerDeleteAll: async (sessionFindDto: SessionFindDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionFindDto' is not null or undefined
            assertParamExists('shoppingControllerDeleteAll', 'sessionFindDto', sessionFindDto)
            const localVarPath = `/shopping/admin/clean`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionFindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a session by ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGet: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('shoppingControllerGet', 'sessionId', sessionId)
            const localVarPath = `/shopping/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetAllSessions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the current open session (if any) for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetCurrentSession: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve session events by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetEvents: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('shoppingControllerGetEvents', 'sessionId', sessionId)
            const localVarPath = `/shopping/{sessionId}/events`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find nearest lost track by position
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetLostTrackByPosition: async (trackSearchDto: TrackSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trackSearchDto' is not null or undefined
            assertParamExists('shoppingControllerGetLostTrackByPosition', 'trackSearchDto', trackSearchDto)
            const localVarPath = `/shopping/nearest_lost_track`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetReceiptsByUserId: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('shoppingControllerGetReceiptsByUserId', 'userId', userId)
            const localVarPath = `/shopping/admin/receipts/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of receipts for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetUserReceipts: async (userSessionFindDto: UserSessionFindDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSessionFindDto' is not null or undefined
            assertParamExists('shoppingControllerGetUserReceipts', 'userSessionFindDto', userSessionFindDto)
            const localVarPath = `/shopping/receipts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSessionFindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the receipts summary available for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetUserReceiptsSummary: async (userSessionFindDto: UserSessionFindDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSessionFindDto' is not null or undefined
            assertParamExists('shoppingControllerGetUserReceiptsSummary', 'userSessionFindDto', userSessionFindDto)
            const localVarPath = `/shopping/receipts/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSessionFindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset store status and checkout all active sessions
         * @param {StoreResetDto} storeResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerResetStore: async (storeResetDto: StoreResetDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeResetDto' is not null or undefined
            assertParamExists('shoppingControllerResetStore', 'storeResetDto', storeResetDto)
            const localVarPath = `/shopping/reset_store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for sessions, constrained by current logged-in user
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSearch: async (sessionFindDto: SessionFindDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionFindDto' is not null or undefined
            assertParamExists('shoppingControllerSearch', 'sessionFindDto', sessionFindDto)
            const localVarPath = `/shopping/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionFindDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {SearchReceiptsDto} searchReceiptsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSearchReceipts: async (searchReceiptsDto: SearchReceiptsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchReceiptsDto' is not null or undefined
            assertParamExists('shoppingControllerSearchReceipts', 'searchReceiptsDto', searchReceiptsDto)
            const localVarPath = `/shopping/admin/receipts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchReceiptsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSetSendTrackSessionUpdateFalse: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping/publish_track_session_updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSetSendTrackSessionUpdateTrue: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopping/publish_track_session_updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a session
         * @param {ShoppingSessionDto} shoppingSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerUpdate: async (shoppingSessionDto: ShoppingSessionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shoppingSessionDto' is not null or undefined
            assertParamExists('shoppingControllerUpdate', 'shoppingSessionDto', shoppingSessionDto)
            const localVarPath = `/shopping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shoppingSessionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export the smartrack background files
         * @param {boolean} zip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerExport: async (zip: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zip' is not null or undefined
            assertParamExists('smartRackControllerExport', 'zip', zip)
            const localVarPath = `/people-tracker/smart-rack/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (zip !== undefined) {
                localVarQueryParameter['zip'] = zip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerExpose: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/expose-smartrack-gui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SmarTrack GUI status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerGuiExposeStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/expose-smartrack-gui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import the smartrack background files
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerImport: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the smartrack background files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerReset: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restart SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerRestart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SmarTrack status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStop: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStopExposing: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/people-tracker/smart-rack/stop-exposing-smartrack-gui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerCreate: async (stockDto: StockDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockDto' is not null or undefined
            assertParamExists('stocksControllerCreate', 'stockDto', stockDto)
            const localVarPath = `/stocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the stock alerts for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerGetAlerts: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('stocksControllerGetAlerts', 'storeId', storeId)
            const localVarPath = `/stocks/{storeId}/alert`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the expiring products (in 7 days) for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerGetExpiring: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('stocksControllerGetExpiring', 'storeId', storeId)
            const localVarPath = `/stocks/{storeId}/expiry`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer a stock
         * @param {MoveStockDto} moveStockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerMoveStock: async (moveStockDto: MoveStockDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'moveStockDto' is not null or undefined
            assertParamExists('stocksControllerMoveStock', 'moveStockDto', moveStockDto)
            const localVarPath = `/stocks/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moveStockDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRead: async (stockId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockId' is not null or undefined
            assertParamExists('stocksControllerRead', 'stockId', stockId)
            const localVarPath = `/stocks/{stockId}`
                .replace(`{${"stockId"}}`, encodeURIComponent(String(stockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemove: async (stockId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockId' is not null or undefined
            assertParamExists('stocksControllerRemove', 'stockId', stockId)
            const localVarPath = `/stocks/{stockId}`
                .replace(`{${"stockId"}}`, encodeURIComponent(String(stockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemoveAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a stock
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemoveByFilter: async (stockSearchDto: StockSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockSearchDto' is not null or undefined
            assertParamExists('stocksControllerRemoveByFilter', 'stockSearchDto', stockSearchDto)
            const localVarPath = `/stocks/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset a stock availability to the total amount
         * @param {StockResetDto} stockResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerResetRackStockAvailability: async (stockResetDto: StockResetDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockResetDto' is not null or undefined
            assertParamExists('stocksControllerResetRackStockAvailability', 'stockResetDto', stockResetDto)
            const localVarPath = `/stocks/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockResetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for stocks
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerSearch: async (stockSearchDto: StockSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockSearchDto' is not null or undefined
            assertParamExists('stocksControllerSearch', 'stockSearchDto', stockSearchDto)
            const localVarPath = `/stocks/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerUpdate: async (stockDto: StockDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stockDto' is not null or undefined
            assertParamExists('stocksControllerUpdate', 'stockDto', stockDto)
            const localVarPath = `/stocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stockDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a store
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerCreate: async (storeDto: StoreDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDto' is not null or undefined
            assertParamExists('storesControllerCreate', 'storeDto', storeDto)
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerGetStatus: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesControllerGetStatus', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}/status`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerRead: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesControllerRead', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerRemove: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesControllerRemove', 'storeId', storeId)
            const localVarPath = `/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set store status
         * @param {string} storeId 
         * @param {StoreStatusDto} storeStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerSetStatus: async (storeId: string, storeStatusDto: StoreStatusDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesControllerSetStatus', 'storeId', storeId)
            // verify required parameter 'storeStatusDto' is not null or undefined
            assertParamExists('storesControllerSetStatus', 'storeStatusDto', storeStatusDto)
            const localVarPath = `/stores/{storeId}/status`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a store
         * @param {string} storeId 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerUpdate: async (storeId: string, storeDto: StoreDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storesControllerUpdate', 'storeId', storeId)
            // verify required parameter 'storeDto' is not null or undefined
            assertParamExists('storesControllerUpdate', 'storeDto', storeDto)
            const localVarPath = `/stores/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a camera stream
         * @param {string} storeId 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamControllerStream: async (storeId: string, cameraId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('streamControllerStream', 'storeId', storeId)
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('streamControllerStream', 'cameraId', cameraId)
            const localVarPath = `/stream/{storeId}/{cameraId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)))
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerClearStreams: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaming/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable a stream for a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerEnableStream: async (streamingRequestDto: StreamingRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'streamingRequestDto' is not null or undefined
            assertParamExists('streamingControllerEnableStream', 'streamingRequestDto', streamingRequestDto)
            const localVarPath = `/streaming/stream/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streamingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve configured sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerGetCameraConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaming/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerGetStreams: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaming/stream`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start record a stream from a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStartRecord: async (streamingRequestDto: StreamingRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'streamingRequestDto' is not null or undefined
            assertParamExists('streamingControllerStartRecord', 'streamingRequestDto', streamingRequestDto)
            const localVarPath = `/streaming/record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streamingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop all active recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStopAllRecords: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/streaming/record`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop recording a stream from a camera
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStopRecord: async (clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('streamingControllerStopRecord', 'clientId', clientId)
            const localVarPath = `/streaming/record/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a stream from a camera
         * @param {string} streamid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerView: async (streamid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'streamid' is not null or undefined
            assertParamExists('streamingControllerView', 'streamid', streamid)
            const localVarPath = `/streaming/stream/view/{streamid}`
                .replace(`{${"streamid"}}`, encodeURIComponent(String(streamid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a product to a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerAddItem: async (sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('virtualCartControllerAddItem', 'sessionId', sessionId)
            // verify required parameter 'virtualCartItemAddDto' is not null or undefined
            assertParamExists('virtualCartControllerAddItem', 'virtualCartItemAddDto', virtualCartItemAddDto)
            const localVarPath = `/virtualcart/{sessionId}/item`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(virtualCartItemAddDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for carts
         * @param {VirtualCartSearchDto} virtualCartSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerAdminSearch: async (virtualCartSearchDto: VirtualCartSearchDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'virtualCartSearchDto' is not null or undefined
            assertParamExists('virtualCartControllerAdminSearch', 'virtualCartSearchDto', virtualCartSearchDto)
            const localVarPath = `/virtualcart/admin/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(virtualCartSearchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerCreateVirtualCart: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('virtualCartControllerCreateVirtualCart', 'sessionId', sessionId)
            const localVarPath = `/virtualcart/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerDeleteVirtualCart: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('virtualCartControllerDeleteVirtualCart', 'sessionId', sessionId)
            const localVarPath = `/virtualcart/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerGetVirtualCart: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('virtualCartControllerGetVirtualCart', 'sessionId', sessionId)
            const localVarPath = `/virtualcart/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a product from a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerRemoveItem: async (sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('virtualCartControllerRemoveItem', 'sessionId', sessionId)
            // verify required parameter 'virtualCartItemAddDto' is not null or undefined
            assertParamExists('virtualCartControllerRemoveItem', 'virtualCartItemAddDto', virtualCartItemAddDto)
            const localVarPath = `/virtualcart/{sessionId}/item`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(virtualCartItemAddDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a webhook
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerCreate: async (webhookDto: WebhookDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookDto' is not null or undefined
            assertParamExists('webhookControllerCreate', 'webhookDto', webhookDto)
            const localVarPath = `/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerRemove: async (webhookId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('webhookControllerRemove', 'webhookId', webhookId)
            const localVarPath = `/webhook/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a webhook
         * @param {string} webhookId 
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerUpdate: async (webhookId: string, webhookDto: WebhookDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookId' is not null or undefined
            assertParamExists('webhookControllerUpdate', 'webhookId', webhookId)
            // verify required parameter 'webhookDto' is not null or undefined
            assertParamExists('webhookControllerUpdate', 'webhookDto', webhookDto)
            const localVarPath = `/webhook/{webhookId}`
                .replace(`{${"webhookId"}}`, encodeURIComponent(String(webhookId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analyze readers signal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerAnalyzeAllReadersSignal: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/weight/reader/analyze_signal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analyze reader signal
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerAnalyzeSignal: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerAnalyzeSignal', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerAnalyzeSignal', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/analyze_signal`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calibrate cell with zero weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCalibCellW0: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerCalibCellW0', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerCalibCellW0', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/calib_w0`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calibrate cell with reference weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCalibCellWRef: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerCalibCellWRef', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerCalibCellWRef', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/calib_wref`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cell state
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCellState: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerCellState', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerCellState', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/state`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a cell status
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCellStatus: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerCellStatus', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerCellStatus', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/status`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cell weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCurrentWeight: async (readerid: string, cellid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerCurrentWeight', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerCurrentWeight', 'cellid', cellid)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/weight`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerDisableStream: async (readerid: string, cellid: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerDisableStream', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerDisableStream', 'cellid', cellid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('weightControllerDisableStream', 'body', body)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/disable_stream`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerEnableStream: async (readerid: string, cellid: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerEnableStream', 'readerid', readerid)
            // verify required parameter 'cellid' is not null or undefined
            assertParamExists('weightControllerEnableStream', 'cellid', cellid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('weightControllerEnableStream', 'body', body)
            const localVarPath = `/weight/reader/{readerid}/cell/{cellid}/enable_stream`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)))
                .replace(`{${"cellid"}}`, encodeURIComponent(String(cellid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a reader status
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerReaderStatus: async (readerid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerReaderStatus', 'readerid', readerid)
            const localVarPath = `/weight/reader/{readerid}/status`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reload readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerRelaodReaders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/weight/reader/reload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStartReader: async (readerid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerStartReader', 'readerid', readerid)
            const localVarPath = `/weight/reader/{readerid}/start`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get readers status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/weight/reader/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stop a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStopReader: async (readerid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'readerid' is not null or undefined
            assertParamExists('weightControllerStopReader', 'readerid', readerid)
            const localVarPath = `/weight/reader/{readerid}/stop`
                .replace(`{${"readerid"}}`, encodeURIComponent(String(readerid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Drop content from specfied collections
         * @param {DropCollection} dropCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDatabaseControllerDrop(dropCollection: DropCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDatabaseControllerDrop(dropCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export a dump of specified collections
         * @param {ExportCollection} exportCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDatabaseControllerExport(exportCollection: ExportCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDatabaseControllerExport(exportCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import records from a file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDatabaseControllerImport(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDatabaseControllerImport(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import records from an env context
         * @param {string} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDatabaseControllerImportEnv(env: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDatabaseControllerImportEnv(env, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger a recording from an env context
         * @param {string} env 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDatabaseControllerTriggerEnv(env: string, recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDatabaseControllerTriggerEnv(env, recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a running playback script
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlaybackControllerCancelScript(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlaybackControllerCancelScript(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel all running playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlaybackControllerCancelScripts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlaybackControllerCancelScripts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlaybackControllerListScripts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybackResultListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlaybackControllerListScripts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Run a playback script
         * @param {PlaybackOptions} playbackOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPlaybackControllerRunScript(playbackOptions: PlaybackOptions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaybackResultMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPlaybackControllerRunScript(playbackOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close user session
         * @param {CloseSessionOptions} closeSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminScriptsControllerCloseSession(closeSessionOptions: CloseSessionOptions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminScriptsControllerCloseSession(closeSessionOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open user session
         * @param {OpenSessionOptions} openSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminScriptsControllerOpenSession(openSessionOptions: OpenSessionOptions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCredentialsListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminScriptsControllerOpenSession(openSessionOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Run a complete user session
         * @param {UserSessionOptions} userSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminScriptsControllerUserSession(userSessionOptions: UserSessionOptions, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCredentialsListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminScriptsControllerUserSession(userSessionOptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an API key
         * @param {ApiKeyDto} apiKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyControllerCreate(apiKeyDto: ApiKeyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKeyDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyControllerCreate(apiKeyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an API key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyControllerDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save an asset to storage
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsControllerSave(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSaveResponseMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerSave(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsControllerServe(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsControllerServe(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new user
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerAddUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminUserCreateResponseDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerAddUser(authAdminUserCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a user token by ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerCreateToken(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerCreateToken(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete users by email or uid
         * @param {number} keepAccount 
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerDeleteUsers(keepAccount: number, authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminUsersListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerDeleteUsers(keepAccount, authAdminUsersListQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable a user by ID
         * @param {string} userId 
         * @param {AuthAdminFirebaseEnableUserDto} authAdminFirebaseEnableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerEnable(userId: string, authAdminFirebaseEnableUserDto: AuthAdminFirebaseEnableUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerEnable(userId, authAdminFirebaseEnableUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a user profile by user ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerGetProfile(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerGetProfile(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all users
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerListUsers(authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminUsersListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerListUsers(authAdminUsersListQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a user by ID
         * @param {string} userId 
         * @param {number} keepAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerRemoveUser(userId: string, keepAccount: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerRemoveUser(userId, keepAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing user. Creates a local account record if missing
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAdminControllerUpdateUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminUserCreateResponseDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAdminControllerUpdateUser(authAdminUserCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the account for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the account for the logged-in user
         * @param {UserAccountDto} userAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUpdateUser(userAccountDto: UserAccountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUpdateUser(userAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedule automatic payment by session ID
         * @param {AutomaticPaymentDto} automaticPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto: AutomaticPaymentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the state for a recording (eg. record, play, pause)
         * @param {string} recordingId 
         * @param {'record' | 'stop' | 'play' | 'flush'} logOperation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerControl(recordingId: string, logOperation: 'record' | 'stop' | 'play' | 'flush', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerControl(recordingId, logOperation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new recording. Also store recording items if provided
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerCreate(logRecordingDto: LogRecordingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRecordingDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerCreate(logRecordingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerDelete(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerDelete(recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerGet(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRecordingDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerGet(recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve items for a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerGetItems(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogItemDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerGetItems(recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List available recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRecordingDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove items from a recording
         * @param {string} recordingId 
         * @param {LogItemRemoveQueryDto} logItemRemoveQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerRemoveItems(recordingId: string, logItemRemoveQueryDto: LogItemRemoveQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerRemoveItems(recordingId, logItemRemoveQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger an event as provided via payload
         * @param {LogItemDto} logItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerTriggerEvent(logItemDto: LogItemDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerTriggerEvent(logItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a recording
         * @param {string} recordingId 
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brokerLoggerControllerUpdate(recordingId: string, logRecordingDto: LogRecordingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRecordingDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brokerLoggerControllerUpdate(recordingId, logRecordingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all products in the catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerDeleteAllProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerDeleteAllProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download catalog as CSV
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerExportCatalog(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerExportCatalog(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all store products in the catalog
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerGetAllProductsByStore(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerGetAllProductsByStore(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a product from the catalog
         * @param {string} storeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerGetProduct(storeId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerGetProduct(storeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import catalog from CSV
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerImportCatalog(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerImportCatalog(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerInsertProduct(productDto: ProductDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerInsertProduct(productDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a product in the catalog
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerRemoveProduct(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerRemoveProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search store products in the catalog by name
         * @param {string} storeId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerSearchProducts(storeId: string, q: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerSearchProducts(storeId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search store products in the catalog
         * @param {string} storeId 
         * @param {ProductSearchDto} productSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerSearchProductsFilter(storeId: string, productSearchDto: ProductSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerSearchProductsFilter(storeId, productSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async catalogControllerUpdateProduct(productDto: ProductDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.catalogControllerUpdateProduct(productDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset the number of people in the store to zero
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinControllerCheckoutAll(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinControllerCheckoutAll(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new access QR code
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinControllerCreateQRcode(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotemTokenMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinControllerCreateQRcode(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve checkin status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinControllerStatus(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckinStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinControllerStatus(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate user provided QR code at checkin
         * @param {TotemToken} totemToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkinControllerValidateQRcode(totemToken: TotemToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkinControllerValidateQRcode(totemToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set a stream source for the camera
         * @param {PlayRequestDto} playRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectorStreamingControllerSetSource(playRequestDto: PlayRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectorStreamingControllerSetSource(playRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a recording on the camera stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectorStreamingControllerStartRecord(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectorStreamingControllerStartRecord(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop a recording instance
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectorStreamingControllerStopRecording(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectorStreamingControllerStopRecording(recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop all recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectorStreamingControllerStopRecordings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectorStreamingControllerStopRecordings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the camera stream
         * @param {string} detector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detectorStreamingControllerStream(detector: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detectorStreamingControllerStream(detector, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the status of a door [open, closed]
         * @param {'entrance' | 'exit'} doorType Door type [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorControllerDoorStatus(doorType: 'entrance' | 'exit', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoorStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorControllerDoorStatus(doorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the status of a door controller
         * @param {string} storeId ID of the store
         * @param {'entrance' | 'exit'} doorType The type of the door [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doorControllerOpenDoor(storeId: string, doorType: 'entrance' | 'exit', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doorControllerOpenDoor(storeId, doorType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login a user by credentials returning an access token
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerGetJWT(login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerGetJWT(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate a new token for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerRefreshToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerRefreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request the password reset for an user
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginControllerResetPassword(reset: Reset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginControllerResetPassword(reset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a job to the worker
         * @param {MLJobRequestDto} mLJobRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLManagerControllerAdd(mLJobRequestDto: MLJobRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLManagerControllerAdd(mLJobRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List worker jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLManagerControllerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLManagerControllerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a job from the worker
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLManagerControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLManagerControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Processes a single image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLManagerControllerUploadFile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLManagerControllerUploadFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerDelDetectorParameters(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerDelDetectorParameters(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerDelDetectorTraining(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerDelDetectorTraining(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerDelEmbedderInference(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerDelEmbedderInference(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerDelEmbedderParameters(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerDelEmbedderParameters(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerDelEmbedderTraining(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerDelEmbedderTraining(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetAllDetectorraining(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderTrainingDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetAllDetectorraining(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetAllEmbedderInference(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderInferenceDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetAllEmbedderInference(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetAllEmbedderTraining(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderTrainingDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetAllEmbedderTraining(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetDetectorParameters(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderTrainingDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetDetectorParameters(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetEmbedderInferenceProductId(productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderInferenceDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetEmbedderInferenceProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetEmbedderParameters(modelid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderParametersDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetEmbedderParameters(modelid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerGetEmbedderProductIdFromLabel(label: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MLEmbedderInferenceDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerGetEmbedderProductIdFromLabel(label, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MLDetectorParametersDto} mLDetectorParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto: MLDetectorParametersDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerPutDetectorTraining(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerPutDetectorTraining(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MLEmbedderInferenceDto} mLEmbedderInferenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto: MLEmbedderInferenceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MLEmbedderParametersDto} mLEmbedderParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto: MLEmbedderParametersDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mLRepositoryControllerPutEmbedderTraining(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mLRepositoryControllerPutEmbedderTraining(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate user ACL on subscription
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mqttAuthControllerGetAcl(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mqttAuthControllerGetAcl(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate user authentication on connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mqttAuthControllerGetUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mqttAuthControllerGetUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set notification to read
         * @param {string} storeId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkAsRead(storeId: string, notificationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkAsRead(storeId, notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete All notifications from store
         * @param {string} storeId ID of the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerRemoveAll(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerRemoveAll(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete All notifications by Type
         * @param {string} storeId ID of the store
         * @param {'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace'} message The type of notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerRemoveAllByType(storeId: string, message: 'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerRemoveAllByType(storeId, message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve notifications for a store
         * @param {string} storeId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerStatus(storeId: string, limit?: number, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerStatus(storeId, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a mock payment method
         * @param {string} userId 
         * @param {number} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminCreateMockPaymentMethod(userId: string, force: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminCreateMockPaymentMethod(userId, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export payments as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export payments as JSON
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentExportDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export sold products as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export user sessions as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for payments
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerAdminSearch(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerAdminSearch(searchPaymentQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle BKM payment result
         * @param {BKMPaymentResultDto} bKMPaymentResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerBkmPaymentResult(bKMPaymentResultDto: BKMPaymentResultDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerBkmPaymentResult(bKMPaymentResultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Handle BKM payment setup result
         * @param {BKMPaymentSetupResultDto} bKMPaymentSetupResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto: BKMPaymentSetupResultDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Setup Stripe to register a credit card for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerCreateCardSecret(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSecretDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerCreateCardSecret(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Stripe payment intent
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerCreatePaymentIntent(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectPaymentDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerCreatePaymentIntent(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a payment methods references
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerDeleteCardSecret(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerDeleteCardSecret(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve Stripe registered payment methods references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerGetCardSecret(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerGetCardSecret(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve payment amount
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerGetPaymentAmount(sessionid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentAmountDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerGetPaymentAmount(sessionid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List payment methods
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerListPayments(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerListPayments(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for payment methods
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerListPaymentsFiltered(period: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentTimeSeriesDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerListPaymentsFiltered(period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Stripe payment as suceeded
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerPaymentSucceeded(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerPaymentSucceeded(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Stripe credit card reference for a user
         * @param {UpdateCardDto} updateCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentControllerUpdateCardSecret(updateCardDto: UpdateCardDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentControllerUpdateCardSecret(updateCardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restream smartrack events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerActivateSmartrackRestreamFromBroker(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerActivateSmartrackRestreamFromBroker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop smartrack restream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tracks events publishing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerGetPublishSmartrackStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerGetPublishSmartrackStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find tracks by positions
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerGetTrackByPosition(trackSearchDto: TrackSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerGetTrackByPosition(trackSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve tracks positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerGetTrackPositions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerGetTrackPositions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerPublishPeopleTrackerTracks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerPublishPeopleTrackerTracks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerPublishSmartrackEvent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerPublishSmartrackEvent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset people tracker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerResetPeopleTracker(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerResetPeopleTracker(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerStopPublishingSmartrackEvent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerStopPublishingSmartrackEvent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stops publishing people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peopleTrackerControllerUnpublishPeopleTrackerTracks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peopleTrackerControllerUnpublishPeopleTrackerTracks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingControllerDeleteProductPrice(storeId: string, productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingControllerDeleteProductPrice(storeId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create store product pricing
         * @param {string} storeId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingControllerInsertProductPrice(storeId: string, productPriceDto: ProductPriceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingControllerInsertProductPrice(storeId, productPriceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingControllerSearchProducts(storeId: string, productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingControllerSearchProducts(storeId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricingControllerUpdateProductPrice(storeId: string, productId: string, productPriceDto: ProductPriceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricingControllerUpdateProductPrice(storeId, productId, productPriceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the user profile for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the user profile for the logged-in user
         * @param {UserProfileUpdateDto} userProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerSave(userProfileUpdateDto: UserProfileUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerSave(userProfileUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the invoicing information for the logged-in user
         * @param {UserProfileInvoiceDto} userProfileInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerSaveInvoice(userProfileInvoiceDto: UserProfileInvoiceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerSaveInvoice(userProfileInvoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put out of place product
         * @param {OutOfPlaceDto} outOfPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerAddOutOfPlace(outOfPlaceDto: OutOfPlaceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerAddOutOfPlace(outOfPlaceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new rack
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerCreateRack(rackDto: RackDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerCreateRack(rackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerDeleteAllRacks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerDeleteAllRacks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerDeleteRackById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerDeleteRackById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerGetAllRacks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerGetAllRacks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get shelf out of place products
         * @param {string} shelfid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerGetOutOfPlace(shelfid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutOfPlaceDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerGetOutOfPlace(shelfid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find a rack by cell ID
         * @param {string} cellId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerGetRackByCellId(cellId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerGetRackByCellId(cellId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerGetRackById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerGetRackById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove out of place product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerRemoveOutOfPlace(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerRemoveOutOfPlace(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset stock status for all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerResetAllRacksStockAvailability(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerResetAllRacksStockAvailability(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset stock status for a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerResetStockAvailability(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerResetStockAvailability(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for a rack by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerSearchRacks(q: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerSearchRacks(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a rack by ID
         * @param {string} id 
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racksControllerUpdateRack(id: string, rackDto: RackDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racksControllerUpdateRack(id, rackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reader health status by ID
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerCheckReaderHealthStatusById(readerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerCheckReaderHealthStatusById(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get readers health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerCheckReadersHealthStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerCheckReadersHealthStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerCreateReader(readerDto: ReaderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerCreateReader(readerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerDeleteAllReaders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerDeleteAllReaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerDeleteReaderById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerDeleteReaderById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerExportReadersConfigurations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerExportReadersConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerGetAllReaders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerGetAllReaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get calibration data for a reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerGetCalibrationByReaderId(readerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerGetCalibrationByReaderId(readerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerGetReaderById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerGetReaderById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerImportReadersConfigurations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerImportReadersConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search readers by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerSearchReaders(q: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerSearchReaders(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update calibration data for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CalibDto} calibDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerUpdateCellCalib(readerId: string, cellId: string, calibDto: CalibDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerUpdateCellCalib(readerId, cellId, calibDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cell params for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CellExtraParamsDto} cellExtraParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerUpdateCellExtraParams(readerId: string, cellId: string, cellExtraParamsDto: CellExtraParamsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerUpdateCellExtraParams(readerId, cellId, cellExtraParamsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readersControllerUpdateReader(readerDto: ReaderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readersControllerUpdateReader(readerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordingControllerRemove(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordingControllerRemove(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a new user
         * @param {UserProfileDto} userProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationControllerRegistrationProcess(userProfileDto: UserProfileDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreatedMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationControllerRegistrationProcess(userProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a user
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationControllerUnregistrationProcess(login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationControllerUnregistrationProcess(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start the restream
         * @param {PeopleTrackerTestDto} peopleTrackerTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restreamControllerStart(peopleTrackerTestDto: PeopleTrackerTestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restreamControllerStart(peopleTrackerTestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create item in RFID product mapping
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rfidCartControllerCreateItem(rfidCartDto: RfidCartDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rfidCartControllerCreateItem(rfidCartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove item from RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rfidCartControllerDeleteItem(tagId: string, rfidCartDto: RfidCartDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rfidCartControllerDeleteItem(tagId, rfidCartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get item by RFID tag
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rfidCartControllerGetItemByTag(tagId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rfidCartControllerGetItemByTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import batch RFID product mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rfidCartControllerImportCatalog(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rfidCartControllerImportCatalog(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update item in RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rfidCartControllerUpdateItem(tagId: string, rfidCartDto: RfidCartDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rfidCartControllerUpdateItem(tagId, rfidCartDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove sessions-related items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionLoggerControllerFlush(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionLoggerControllerFlush(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for sessions-related items
         * @param {SessionLoggerQueryDto} sessionLoggerQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionLoggerControllerSearch(sessionLoggerQueryDto: SessionLoggerQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogItemDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionLoggerControllerSearch(sessionLoggerQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerAdminSearch(sessionFindDto: SessionFindDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerAdminSearch(sessionFindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel all open sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerCancelOpen(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerCancelOpen(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a session
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerCreate(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerDelete(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerDelete(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerDeleteAll(sessionFindDto: SessionFindDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerDeleteAll(sessionFindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a session by ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGet(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGet(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetAllSessions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetAllSessions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the current open session (if any) for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetCurrentSession(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetCurrentSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve session events by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetEvents(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionEventDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetEvents(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find nearest lost track by position
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetLostTrackByPosition(trackSearchDto: TrackSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetLostTrackByPosition(trackSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetReceiptsByUserId(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetReceiptsByUserId(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the list of receipts for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetUserReceipts(userSessionFindDto: UserSessionFindDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetUserReceipts(userSessionFindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the receipts summary available for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerGetUserReceiptsSummary(userSessionFindDto: UserSessionFindDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptSummaryDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerGetUserReceiptsSummary(userSessionFindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset store status and checkout all active sessions
         * @param {StoreResetDto} storeResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerResetStore(storeResetDto: StoreResetDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendTrackSessionUpdateDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerResetStore(storeResetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for sessions, constrained by current logged-in user
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerSearch(sessionFindDto: SessionFindDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingSessionDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerSearch(sessionFindDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {SearchReceiptsDto} searchReceiptsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerSearchReceipts(searchReceiptsDto: SearchReceiptsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerSearchReceipts(searchReceiptsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerSetSendTrackSessionUpdateFalse(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendTrackSessionUpdateDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerSetSendTrackSessionUpdateFalse(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerSetSendTrackSessionUpdateTrue(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendTrackSessionUpdateDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerSetSendTrackSessionUpdateTrue(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a session
         * @param {ShoppingSessionDto} shoppingSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingControllerUpdate(shoppingSessionDto: ShoppingSessionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingControllerUpdate(shoppingSessionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export the smartrack background files
         * @param {boolean} zip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerExport(zip: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerExport(zip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerExpose(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerExpose(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SmarTrack GUI status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerGuiExposeStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuiStatusMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerGuiExposeStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import the smartrack background files
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerImport(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerImport(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the smartrack background files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerReset(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessHandlerStatusMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerReset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restart SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerRestart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerRestart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerStart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerStart(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SmarTrack status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessHandlerStatusMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerStop(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerStop(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smartRackControllerStopExposing(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smartRackControllerStopExposing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerCreate(stockDto: StockDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerCreate(stockDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the stock alerts for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerGetAlerts(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerGetAlerts(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the expiring products (in 7 days) for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerGetExpiring(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerGetExpiring(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer a stock
         * @param {MoveStockDto} moveStockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerMoveStock(moveStockDto: MoveStockDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerMoveStock(moveStockDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerRead(stockId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerRead(stockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerRemove(stockId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerRemove(stockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerRemoveAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerRemoveAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a stock
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerRemoveByFilter(stockSearchDto: StockSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerRemoveByFilter(stockSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset a stock availability to the total amount
         * @param {StockResetDto} stockResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerResetRackStockAvailability(stockResetDto: StockResetDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerResetRackStockAvailability(stockResetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for stocks
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerSearch(stockSearchDto: StockSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerSearch(stockSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksControllerUpdate(stockDto: StockDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stocksControllerUpdate(stockDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a store
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerCreate(storeDto: StoreDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerCreate(storeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerGetStatus(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerGetStatus(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerRead(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerRead(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerRemove(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerRemove(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set store status
         * @param {string} storeId 
         * @param {StoreStatusDto} storeStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerSetStatus(storeId: string, storeStatusDto: StoreStatusDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerSetStatus(storeId, storeStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a store
         * @param {string} storeId 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storesControllerUpdate(storeId: string, storeDto: StoreDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storesControllerUpdate(storeId, storeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a camera stream
         * @param {string} storeId 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamControllerStream(storeId: string, cameraId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamControllerStream(storeId, cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerClearStreams(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerClearStreams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable a stream for a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerEnableStream(streamingRequestDto: StreamingRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamingResponseDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerEnableStream(streamingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve configured sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerGetCameraConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerGetCameraConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerGetStreams(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamingResponseDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerGetStreams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start record a stream from a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerStartRecord(streamingRequestDto: StreamingRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerStartRecord(streamingRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop all active recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerStopAllRecords(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerStopAllRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop recording a stream from a camera
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerStopRecord(clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerStopRecord(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a stream from a camera
         * @param {string} streamid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamingControllerView(streamid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamingControllerView(streamid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a product to a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerAddItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerAddItem(sessionId, virtualCartItemAddDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for carts
         * @param {VirtualCartSearchDto} virtualCartSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerAdminSearch(virtualCartSearchDto: VirtualCartSearchDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VirtualCartResponseDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerAdminSearch(virtualCartSearchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerCreateVirtualCart(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VirtualCartCreateDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerCreateVirtualCart(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerDeleteVirtualCart(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerDeleteVirtualCart(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerGetVirtualCart(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VirtualCartResponseDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerGetVirtualCart(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a product from a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async virtualCartControllerRemoveItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.virtualCartControllerRemoveItem(sessionId, virtualCartItemAddDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a webhook
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerCreate(webhookDto: WebhookDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerCreate(webhookDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List available webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerRemove(webhookId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerRemove(webhookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a webhook
         * @param {string} webhookId 
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookControllerUpdate(webhookId: string, webhookDto: WebhookDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookDtoListMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookControllerUpdate(webhookId, webhookDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analyze readers signal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerAnalyzeAllReadersSignal(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerAnalyzeAllReadersSignal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Analyze reader signal
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerAnalyzeSignal(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerAnalyzeSignal(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calibrate cell with zero weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerCalibCellW0(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalibResultDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerCalibCellW0(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calibrate cell with reference weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerCalibCellWRef(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalibResultDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerCalibCellWRef(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get cell state
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerCellState(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellStateDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerCellState(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a cell status
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerCellStatus(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CellStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerCellStatus(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get cell weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerCurrentWeight(readerid: string, cellid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeightDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerCurrentWeight(readerid, cellid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerDisableStream(readerid: string, cellid: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerDisableStream(readerid, cellid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerEnableStream(readerid: string, cellid: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerEnableStream(readerid, cellid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a reader status
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerReaderStatus(readerid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerReaderStatus(readerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reload readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerRelaodReaders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerRelaodReaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerStartReader(readerid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerStartReader(readerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get readers status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stop a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weightControllerStopReader(readerid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReaderStatusDtoMimexResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weightControllerStopReader(readerid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Drop content from specfied collections
         * @param {DropCollection} dropCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerDrop(dropCollection: DropCollection, options?: any): AxiosPromise<void> {
            return localVarFp.adminDatabaseControllerDrop(dropCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export a dump of specified collections
         * @param {ExportCollection} exportCollection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerExport(exportCollection: ExportCollection, options?: any): AxiosPromise<void> {
            return localVarFp.adminDatabaseControllerExport(exportCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import records from a file
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerImport(file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.adminDatabaseControllerImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import records from an env context
         * @param {string} env 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerImportEnv(env: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDatabaseControllerImportEnv(env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger a recording from an env context
         * @param {string} env 
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDatabaseControllerTriggerEnv(env: string, recordingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminDatabaseControllerTriggerEnv(env, recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a running playback script
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerCancelScript(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminPlaybackControllerCancelScript(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel all running playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerCancelScripts(options?: any): AxiosPromise<void> {
            return localVarFp.adminPlaybackControllerCancelScripts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List playback scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerListScripts(options?: any): AxiosPromise<PlaybackResultListMimexResponse> {
            return localVarFp.adminPlaybackControllerListScripts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run a playback script
         * @param {PlaybackOptions} playbackOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPlaybackControllerRunScript(playbackOptions: PlaybackOptions, options?: any): AxiosPromise<PlaybackResultMimexResponse> {
            return localVarFp.adminPlaybackControllerRunScript(playbackOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close user session
         * @param {CloseSessionOptions} closeSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerCloseSession(closeSessionOptions: CloseSessionOptions, options?: any): AxiosPromise<void> {
            return localVarFp.adminScriptsControllerCloseSession(closeSessionOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open user session
         * @param {OpenSessionOptions} openSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerOpenSession(openSessionOptions: OpenSessionOptions, options?: any): AxiosPromise<UserCredentialsListMimexResponse> {
            return localVarFp.adminScriptsControllerOpenSession(openSessionOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run a complete user session
         * @param {UserSessionOptions} userSessionOptions 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminScriptsControllerUserSession(userSessionOptions: UserSessionOptions, options?: any): AxiosPromise<UserCredentialsListMimexResponse> {
            return localVarFp.adminScriptsControllerUserSession(userSessionOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an API key
         * @param {ApiKeyDto} apiKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyControllerCreate(apiKeyDto: ApiKeyDto, options?: any): AxiosPromise<ApiKeyDtoMimexResponse> {
            return localVarFp.apiKeyControllerCreate(apiKeyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an API key
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeyControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save an asset to storage
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerSave(file?: any, options?: any): AxiosPromise<AssetSaveResponseMimexResponse> {
            return localVarFp.assetsControllerSave(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an asset from storage
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsControllerServe(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetsControllerServe(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new user
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerAddUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any): AxiosPromise<AuthAdminUserCreateResponseDtoMimexResponse> {
            return localVarFp.authAdminControllerAddUser(authAdminUserCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a user token by ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerCreateToken(userId: string, options?: any): AxiosPromise<JwtResponseMimexResponse> {
            return localVarFp.authAdminControllerCreateToken(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete users by email or uid
         * @param {number} keepAccount 
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerDeleteUsers(keepAccount: number, authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any): AxiosPromise<AuthAdminUsersListMimexResponse> {
            return localVarFp.authAdminControllerDeleteUsers(keepAccount, authAdminUsersListQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable a user by ID
         * @param {string} userId 
         * @param {AuthAdminFirebaseEnableUserDto} authAdminFirebaseEnableUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerEnable(userId: string, authAdminFirebaseEnableUserDto: AuthAdminFirebaseEnableUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.authAdminControllerEnable(userId, authAdminFirebaseEnableUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a user profile by user ID
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerGetProfile(userId: string, options?: any): AxiosPromise<UserProfileDtoMimexResponse> {
            return localVarFp.authAdminControllerGetProfile(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all users
         * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerListUsers(authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any): AxiosPromise<AuthAdminUsersListMimexResponse> {
            return localVarFp.authAdminControllerListUsers(authAdminUsersListQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user by ID
         * @param {string} userId 
         * @param {number} keepAccount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerRemoveUser(userId: string, keepAccount: number, options?: any): AxiosPromise<void> {
            return localVarFp.authAdminControllerRemoveUser(userId, keepAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing user. Creates a local account record if missing
         * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAdminControllerUpdateUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any): AxiosPromise<AuthAdminUserCreateResponseDtoMimexResponse> {
            return localVarFp.authAdminControllerUpdateUser(authAdminUserCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the account for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetUser(options?: any): AxiosPromise<UserAccountDtoMimexResponse> {
            return localVarFp.authControllerGetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the account for the logged-in user
         * @param {UserAccountDto} userAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdateUser(userAccountDto: UserAccountDto, options?: any): AxiosPromise<UserAccountDtoMimexResponse> {
            return localVarFp.authControllerUpdateUser(userAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedule automatic payment by session ID
         * @param {AutomaticPaymentDto} automaticPaymentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto: AutomaticPaymentDto, options?: any): AxiosPromise<void> {
            return localVarFp.automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the state for a recording (eg. record, play, pause)
         * @param {string} recordingId 
         * @param {'record' | 'stop' | 'play' | 'flush'} logOperation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerControl(recordingId: string, logOperation: 'record' | 'stop' | 'play' | 'flush', options?: any): AxiosPromise<void> {
            return localVarFp.brokerLoggerControllerControl(recordingId, logOperation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new recording. Also store recording items if provided
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerCreate(logRecordingDto: LogRecordingDto, options?: any): AxiosPromise<LogRecordingDtoMimexResponse> {
            return localVarFp.brokerLoggerControllerCreate(logRecordingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerDelete(recordingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.brokerLoggerControllerDelete(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerGet(recordingId: string, options?: any): AxiosPromise<LogRecordingDtoMimexResponse> {
            return localVarFp.brokerLoggerControllerGet(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve items for a recording
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerGetItems(recordingId: string, options?: any): AxiosPromise<LogItemDtoListMimexResponse> {
            return localVarFp.brokerLoggerControllerGetItems(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List available recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerList(options?: any): AxiosPromise<LogRecordingDtoListMimexResponse> {
            return localVarFp.brokerLoggerControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove items from a recording
         * @param {string} recordingId 
         * @param {LogItemRemoveQueryDto} logItemRemoveQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerRemoveItems(recordingId: string, logItemRemoveQueryDto: LogItemRemoveQueryDto, options?: any): AxiosPromise<void> {
            return localVarFp.brokerLoggerControllerRemoveItems(recordingId, logItemRemoveQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger an event as provided via payload
         * @param {LogItemDto} logItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerTriggerEvent(logItemDto: LogItemDto, options?: any): AxiosPromise<void> {
            return localVarFp.brokerLoggerControllerTriggerEvent(logItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a recording
         * @param {string} recordingId 
         * @param {LogRecordingDto} logRecordingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brokerLoggerControllerUpdate(recordingId: string, logRecordingDto: LogRecordingDto, options?: any): AxiosPromise<LogRecordingDtoMimexResponse> {
            return localVarFp.brokerLoggerControllerUpdate(recordingId, logRecordingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all products in the catalog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerDeleteAllProducts(options?: any): AxiosPromise<void> {
            return localVarFp.catalogControllerDeleteAllProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download catalog as CSV
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerExportCatalog(storeId: string, options?: any): AxiosPromise<MimexResponse> {
            return localVarFp.catalogControllerExportCatalog(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all store products in the catalog
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerGetAllProductsByStore(storeId: string, options?: any): AxiosPromise<ProductDtoListMimexResponse> {
            return localVarFp.catalogControllerGetAllProductsByStore(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a product from the catalog
         * @param {string} storeId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerGetProduct(storeId: string, id: string, options?: any): AxiosPromise<ProductDtoMimexResponse> {
            return localVarFp.catalogControllerGetProduct(storeId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import catalog from CSV
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerImportCatalog(file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.catalogControllerImportCatalog(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerInsertProduct(productDto: ProductDto, options?: any): AxiosPromise<ProductDtoMimexResponse> {
            return localVarFp.catalogControllerInsertProduct(productDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a product in the catalog
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerRemoveProduct(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.catalogControllerRemoveProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search store products in the catalog by name
         * @param {string} storeId 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerSearchProducts(storeId: string, q: string, options?: any): AxiosPromise<ProductDtoListMimexResponse> {
            return localVarFp.catalogControllerSearchProducts(storeId, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search store products in the catalog
         * @param {string} storeId 
         * @param {ProductSearchDto} productSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerSearchProductsFilter(storeId: string, productSearchDto: ProductSearchDto, options?: any): AxiosPromise<ProductDtoListMimexResponse> {
            return localVarFp.catalogControllerSearchProductsFilter(storeId, productSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a product in the catalog
         * @param {ProductDto} productDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        catalogControllerUpdateProduct(productDto: ProductDto, options?: any): AxiosPromise<ProductDtoMimexResponse> {
            return localVarFp.catalogControllerUpdateProduct(productDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset the number of people in the store to zero
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerCheckoutAll(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkinControllerCheckoutAll(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new access QR code
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerCreateQRcode(storeId: string, options?: any): AxiosPromise<TotemTokenMimexResponse> {
            return localVarFp.checkinControllerCreateQRcode(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve checkin status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerStatus(storeId: string, options?: any): AxiosPromise<CheckinStatusDtoMimexResponse> {
            return localVarFp.checkinControllerStatus(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate user provided QR code at checkin
         * @param {TotemToken} totemToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkinControllerValidateQRcode(totemToken: TotemToken, options?: any): AxiosPromise<void> {
            return localVarFp.checkinControllerValidateQRcode(totemToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a stream source for the camera
         * @param {PlayRequestDto} playRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerSetSource(playRequestDto: PlayRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.detectorStreamingControllerSetSource(playRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a recording on the camera stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStartRecord(options?: any): AxiosPromise<void> {
            return localVarFp.detectorStreamingControllerStartRecord(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop a recording instance
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStopRecording(recordingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.detectorStreamingControllerStopRecording(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop all recording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStopRecordings(options?: any): AxiosPromise<void> {
            return localVarFp.detectorStreamingControllerStopRecordings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the camera stream
         * @param {string} detector 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detectorStreamingControllerStream(detector: string, options?: any): AxiosPromise<void> {
            return localVarFp.detectorStreamingControllerStream(detector, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the status of a door [open, closed]
         * @param {'entrance' | 'exit'} doorType Door type [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorControllerDoorStatus(doorType: 'entrance' | 'exit', options?: any): AxiosPromise<DoorStatusDtoMimexResponse> {
            return localVarFp.doorControllerDoorStatus(doorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the status of a door controller
         * @param {string} storeId ID of the store
         * @param {'entrance' | 'exit'} doorType The type of the door [entrance, exit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doorControllerOpenDoor(storeId: string, doorType: 'entrance' | 'exit', options?: any): AxiosPromise<void> {
            return localVarFp.doorControllerOpenDoor(storeId, doorType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login a user by credentials returning an access token
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerGetJWT(login: Login, options?: any): AxiosPromise<JwtResponseMimexResponse> {
            return localVarFp.loginControllerGetJWT(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a new token for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerRefreshToken(options?: any): AxiosPromise<JwtResponseMimexResponse> {
            return localVarFp.loginControllerRefreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request the password reset for an user
         * @param {Reset} reset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginControllerResetPassword(reset: Reset, options?: any): AxiosPromise<void> {
            return localVarFp.loginControllerResetPassword(reset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a job to the worker
         * @param {MLJobRequestDto} mLJobRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerAdd(mLJobRequestDto: MLJobRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.mLManagerControllerAdd(mLJobRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List worker jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerList(options?: any): AxiosPromise<void> {
            return localVarFp.mLManagerControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a job from the worker
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLManagerControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes a single image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLManagerControllerUploadFile(options?: any): AxiosPromise<void> {
            return localVarFp.mLManagerControllerUploadFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelDetectorParameters(modelid: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerDelDetectorParameters(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelDetectorTraining(modelid: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerDelDetectorTraining(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderInference(modelid: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerDelEmbedderInference(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderParameters(modelid: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerDelEmbedderParameters(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerDelEmbedderTraining(modelid: string, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerDelEmbedderTraining(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllDetectorraining(modelid: string, options?: any): AxiosPromise<MLEmbedderTrainingDtoListMimexResponse> {
            return localVarFp.mLRepositoryControllerGetAllDetectorraining(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllEmbedderInference(modelid: string, options?: any): AxiosPromise<MLEmbedderInferenceDtoListMimexResponse> {
            return localVarFp.mLRepositoryControllerGetAllEmbedderInference(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetAllEmbedderTraining(modelid: string, options?: any): AxiosPromise<MLEmbedderTrainingDtoListMimexResponse> {
            return localVarFp.mLRepositoryControllerGetAllEmbedderTraining(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetDetectorParameters(modelid: string, options?: any): AxiosPromise<MLEmbedderTrainingDtoMimexResponse> {
            return localVarFp.mLRepositoryControllerGetDetectorParameters(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderInferenceProductId(productId: string, options?: any): AxiosPromise<MLEmbedderInferenceDtoListMimexResponse> {
            return localVarFp.mLRepositoryControllerGetEmbedderInferenceProductId(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} modelid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderParameters(modelid: string, options?: any): AxiosPromise<MLEmbedderParametersDtoMimexResponse> {
            return localVarFp.mLRepositoryControllerGetEmbedderParameters(modelid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} label 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerGetEmbedderProductIdFromLabel(label: number, options?: any): AxiosPromise<MLEmbedderInferenceDtoListMimexResponse> {
            return localVarFp.mLRepositoryControllerGetEmbedderProductIdFromLabel(label, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MLDetectorParametersDto} mLDetectorParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto: MLDetectorParametersDto, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutDetectorTraining(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerPutDetectorTraining(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MLEmbedderInferenceDto} mLEmbedderInferenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto: MLEmbedderInferenceDto, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MLEmbedderParametersDto} mLEmbedderParametersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto: MLEmbedderParametersDto, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mLRepositoryControllerPutEmbedderTraining(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.mLRepositoryControllerPutEmbedderTraining(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate user ACL on subscription
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mqttAuthControllerGetAcl(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.mqttAuthControllerGetAcl(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate user authentication on connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mqttAuthControllerGetUser(options?: any): AxiosPromise<void> {
            return localVarFp.mqttAuthControllerGetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set notification to read
         * @param {string} storeId 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAsRead(storeId: string, notificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerMarkAsRead(storeId, notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete All notifications from store
         * @param {string} storeId ID of the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRemoveAll(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerRemoveAll(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete All notifications by Type
         * @param {string} storeId ID of the store
         * @param {'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace'} message The type of notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerRemoveAllByType(storeId: string, message: 'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace', options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerRemoveAllByType(storeId, message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve notifications for a store
         * @param {string} storeId 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerStatus(storeId: string, limit?: number, skip?: number, options?: any): AxiosPromise<NotificationDtoListMimexResponse> {
            return localVarFp.notificationControllerStatus(storeId, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a mock payment method
         * @param {string} userId 
         * @param {number} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminCreateMockPaymentMethod(userId: string, force: number, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerAdminCreateMockPaymentMethod(userId, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export payments as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): AxiosPromise<MimexResponse> {
            return localVarFp.paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export payments as JSON
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): AxiosPromise<PaymentExportDtoListMimexResponse> {
            return localVarFp.paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export sold products as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): AxiosPromise<MimexResponse> {
            return localVarFp.paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export user sessions as CSV
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): AxiosPromise<MimexResponse> {
            return localVarFp.paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for payments
         * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAdminSearch(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any): AxiosPromise<PaymentDtoListMimexResponse> {
            return localVarFp.paymentControllerAdminSearch(searchPaymentQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle BKM payment result
         * @param {BKMPaymentResultDto} bKMPaymentResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerBkmPaymentResult(bKMPaymentResultDto: BKMPaymentResultDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerBkmPaymentResult(bKMPaymentResultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Handle BKM payment setup result
         * @param {BKMPaymentSetupResultDto} bKMPaymentSetupResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto: BKMPaymentSetupResultDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Setup Stripe to register a credit card for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreateCardSecret(options?: any): AxiosPromise<ClientSecretDtoMimexResponse> {
            return localVarFp.paymentControllerCreateCardSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Stripe payment intent
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerCreatePaymentIntent(sessionId: string, options?: any): AxiosPromise<DirectPaymentDtoMimexResponse> {
            return localVarFp.paymentControllerCreatePaymentIntent(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a payment methods references
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerDeleteCardSecret(id: string, options?: any): AxiosPromise<PaymentMethodDtoListMimexResponse> {
            return localVarFp.paymentControllerDeleteCardSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve Stripe registered payment methods references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetCardSecret(options?: any): AxiosPromise<PaymentMethodDtoListMimexResponse> {
            return localVarFp.paymentControllerGetCardSecret(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve payment amount
         * @param {string} sessionid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetPaymentAmount(sessionid: string, options?: any): AxiosPromise<PaymentAmountDtoListMimexResponse> {
            return localVarFp.paymentControllerGetPaymentAmount(sessionid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List payment methods
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerListPayments(sessionId: string, options?: any): AxiosPromise<PaymentDtoListMimexResponse> {
            return localVarFp.paymentControllerListPayments(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for payment methods
         * @param {string} period 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerListPaymentsFiltered(period: string, options?: any): AxiosPromise<PaymentTimeSeriesDtoListMimexResponse> {
            return localVarFp.paymentControllerListPaymentsFiltered(period, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Stripe payment as suceeded
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerPaymentSucceeded(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerPaymentSucceeded(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Stripe credit card reference for a user
         * @param {UpdateCardDto} updateCardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerUpdateCardSecret(updateCardDto: UpdateCardDto, options?: any): AxiosPromise<void> {
            return localVarFp.paymentControllerUpdateCardSecret(updateCardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restream smartrack events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerActivateSmartrackRestreamFromBroker(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerActivateSmartrackRestreamFromBroker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop smartrack restream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tracks events publishing status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetPublishSmartrackStatus(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerGetPublishSmartrackStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find tracks by positions
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetTrackByPosition(trackSearchDto: TrackSearchDto, options?: any): AxiosPromise<TrackDtoMimexResponse> {
            return localVarFp.peopleTrackerControllerGetTrackByPosition(trackSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve tracks positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerGetTrackPositions(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerGetTrackPositions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerPublishPeopleTrackerTracks(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerPublishPeopleTrackerTracks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerPublishSmartrackEvent(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerPublishSmartrackEvent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset people tracker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerResetPeopleTracker(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerResetPeopleTracker(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable tracks events publishing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerStopPublishingSmartrackEvent(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerStopPublishingSmartrackEvent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stops publishing people tracker tracks over NATS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peopleTrackerControllerUnpublishPeopleTrackerTracks(options?: any): AxiosPromise<void> {
            return localVarFp.peopleTrackerControllerUnpublishPeopleTrackerTracks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerDeleteProductPrice(storeId: string, productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pricingControllerDeleteProductPrice(storeId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create store product pricing
         * @param {string} storeId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerInsertProductPrice(storeId: string, productPriceDto: ProductPriceDto, options?: any): AxiosPromise<void> {
            return localVarFp.pricingControllerInsertProductPrice(storeId, productPriceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerSearchProducts(storeId: string, productId: string, options?: any): AxiosPromise<ProductPriceDtoMimexResponse> {
            return localVarFp.pricingControllerSearchProducts(storeId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update store product pricing
         * @param {string} storeId 
         * @param {string} productId 
         * @param {ProductPriceDto} productPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricingControllerUpdateProductPrice(storeId: string, productId: string, productPriceDto: ProductPriceDto, options?: any): AxiosPromise<void> {
            return localVarFp.pricingControllerUpdateProductPrice(storeId, productId, productPriceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the user profile for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerRead(options?: any): AxiosPromise<UserProfileDtoMimexResponse> {
            return localVarFp.profileControllerRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the user profile for the logged-in user
         * @param {UserProfileUpdateDto} userProfileUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerSave(userProfileUpdateDto: UserProfileUpdateDto, options?: any): AxiosPromise<UserProfileDtoMimexResponse> {
            return localVarFp.profileControllerSave(userProfileUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the invoicing information for the logged-in user
         * @param {UserProfileInvoiceDto} userProfileInvoiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerSaveInvoice(userProfileInvoiceDto: UserProfileInvoiceDto, options?: any): AxiosPromise<UserProfileDtoMimexResponse> {
            return localVarFp.profileControllerSaveInvoice(userProfileInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put out of place product
         * @param {OutOfPlaceDto} outOfPlaceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerAddOutOfPlace(outOfPlaceDto: OutOfPlaceDto, options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerAddOutOfPlace(outOfPlaceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new rack
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerCreateRack(rackDto: RackDto, options?: any): AxiosPromise<RackDtoMimexResponse> {
            return localVarFp.racksControllerCreateRack(rackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerDeleteAllRacks(options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerDeleteAllRacks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerDeleteRackById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerDeleteRackById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetAllRacks(options?: any): AxiosPromise<RackDtoListMimexResponse> {
            return localVarFp.racksControllerGetAllRacks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get shelf out of place products
         * @param {string} shelfid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetOutOfPlace(shelfid: string, options?: any): AxiosPromise<OutOfPlaceDtoListMimexResponse> {
            return localVarFp.racksControllerGetOutOfPlace(shelfid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find a rack by cell ID
         * @param {string} cellId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetRackByCellId(cellId: string, options?: any): AxiosPromise<RackDtoMimexResponse> {
            return localVarFp.racksControllerGetRackByCellId(cellId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerGetRackById(id: string, options?: any): AxiosPromise<RackDtoMimexResponse> {
            return localVarFp.racksControllerGetRackById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove out of place product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerRemoveOutOfPlace(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerRemoveOutOfPlace(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset stock status for all racks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerResetAllRacksStockAvailability(options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerResetAllRacksStockAvailability(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset stock status for a rack by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerResetStockAvailability(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.racksControllerResetStockAvailability(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for a rack by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerSearchRacks(q: string, options?: any): AxiosPromise<RackDtoListMimexResponse> {
            return localVarFp.racksControllerSearchRacks(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a rack by ID
         * @param {string} id 
         * @param {RackDto} rackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racksControllerUpdateRack(id: string, rackDto: RackDto, options?: any): AxiosPromise<RackDtoMimexResponse> {
            return localVarFp.racksControllerUpdateRack(id, rackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reader health status by ID
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCheckReaderHealthStatusById(readerId: string, options?: any): AxiosPromise<ReaderStatusDtoMimexResponse> {
            return localVarFp.readersControllerCheckReaderHealthStatusById(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get readers health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCheckReadersHealthStatus(options?: any): AxiosPromise<StatusDtoMimexResponse> {
            return localVarFp.readersControllerCheckReadersHealthStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerCreateReader(readerDto: ReaderDto, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerCreateReader(readerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerDeleteAllReaders(options?: any): AxiosPromise<void> {
            return localVarFp.readersControllerDeleteAllReaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerDeleteReaderById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.readersControllerDeleteReaderById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerExportReadersConfigurations(options?: any): AxiosPromise<MimexResponse> {
            return localVarFp.readersControllerExportReadersConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetAllReaders(options?: any): AxiosPromise<ReaderDtoListMimexResponse> {
            return localVarFp.readersControllerGetAllReaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calibration data for a reader
         * @param {string} readerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetCalibrationByReaderId(readerId: string, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerGetCalibrationByReaderId(readerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reader by ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerGetReaderById(id: string, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerGetReaderById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload readers configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerImportReadersConfigurations(options?: any): AxiosPromise<void> {
            return localVarFp.readersControllerImportReadersConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search readers by name
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerSearchReaders(q: string, options?: any): AxiosPromise<ReaderDtoListMimexResponse> {
            return localVarFp.readersControllerSearchReaders(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update calibration data for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CalibDto} calibDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateCellCalib(readerId: string, cellId: string, calibDto: CalibDto, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerUpdateCellCalib(readerId, cellId, calibDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cell params for a reader
         * @param {string} readerId 
         * @param {string} cellId 
         * @param {CellExtraParamsDto} cellExtraParamsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateCellExtraParams(readerId: string, cellId: string, cellExtraParamsDto: CellExtraParamsDto, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerUpdateCellExtraParams(readerId, cellId, cellExtraParamsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update reader
         * @param {ReaderDto} readerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readersControllerUpdateReader(readerDto: ReaderDto, options?: any): AxiosPromise<ReaderDtoMimexResponse> {
            return localVarFp.readersControllerUpdateReader(readerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordingControllerRemove(options?: any): AxiosPromise<void> {
            return localVarFp.recordingControllerRemove(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a new user
         * @param {UserProfileDto} userProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerRegistrationProcess(userProfileDto: UserProfileDto, options?: any): AxiosPromise<UserCreatedMimexResponse> {
            return localVarFp.registrationControllerRegistrationProcess(userProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationControllerUnregistrationProcess(login: Login, options?: any): AxiosPromise<void> {
            return localVarFp.registrationControllerUnregistrationProcess(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start the restream
         * @param {PeopleTrackerTestDto} peopleTrackerTestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restreamControllerStart(peopleTrackerTestDto: PeopleTrackerTestDto, options?: any): AxiosPromise<void> {
            return localVarFp.restreamControllerStart(peopleTrackerTestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create item in RFID product mapping
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerCreateItem(rfidCartDto: RfidCartDto, options?: any): AxiosPromise<void> {
            return localVarFp.rfidCartControllerCreateItem(rfidCartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove item from RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerDeleteItem(tagId: string, rfidCartDto: RfidCartDto, options?: any): AxiosPromise<void> {
            return localVarFp.rfidCartControllerDeleteItem(tagId, rfidCartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item by RFID tag
         * @param {string} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerGetItemByTag(tagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rfidCartControllerGetItemByTag(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import batch RFID product mapping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerImportCatalog(options?: any): AxiosPromise<void> {
            return localVarFp.rfidCartControllerImportCatalog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update item in RFID product mapping
         * @param {string} tagId 
         * @param {RfidCartDto} rfidCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rfidCartControllerUpdateItem(tagId: string, rfidCartDto: RfidCartDto, options?: any): AxiosPromise<void> {
            return localVarFp.rfidCartControllerUpdateItem(tagId, rfidCartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove sessions-related items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionLoggerControllerFlush(options?: any): AxiosPromise<void> {
            return localVarFp.sessionLoggerControllerFlush(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for sessions-related items
         * @param {SessionLoggerQueryDto} sessionLoggerQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionLoggerControllerSearch(sessionLoggerQueryDto: SessionLoggerQueryDto, options?: any): AxiosPromise<LogItemDtoListMimexResponse> {
            return localVarFp.sessionLoggerControllerSearch(sessionLoggerQueryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerAdminSearch(sessionFindDto: SessionFindDto, options?: any): AxiosPromise<ShoppingSessionDtoListMimexResponse> {
            return localVarFp.shoppingControllerAdminSearch(sessionFindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel all open sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerCancelOpen(options?: any): AxiosPromise<void> {
            return localVarFp.shoppingControllerCancelOpen(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a session
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerCreate(body: object, options?: any): AxiosPromise<ShoppingSessionDtoMimexResponse> {
            return localVarFp.shoppingControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a session
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerDelete(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.shoppingControllerDelete(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all sessions
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerDeleteAll(sessionFindDto: SessionFindDto, options?: any): AxiosPromise<void> {
            return localVarFp.shoppingControllerDeleteAll(sessionFindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a session by ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGet(sessionId: string, options?: any): AxiosPromise<ShoppingSessionDtoMimexResponse> {
            return localVarFp.shoppingControllerGet(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetAllSessions(options?: any): AxiosPromise<ShoppingSessionDtoListMimexResponse> {
            return localVarFp.shoppingControllerGetAllSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the current open session (if any) for the logged-in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetCurrentSession(options?: any): AxiosPromise<ShoppingSessionDtoMimexResponse> {
            return localVarFp.shoppingControllerGetCurrentSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve session events by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetEvents(sessionId: string, options?: any): AxiosPromise<SessionEventDtoListMimexResponse> {
            return localVarFp.shoppingControllerGetEvents(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find nearest lost track by position
         * @param {TrackSearchDto} trackSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetLostTrackByPosition(trackSearchDto: TrackSearchDto, options?: any): AxiosPromise<TrackDtoMimexResponse> {
            return localVarFp.shoppingControllerGetLostTrackByPosition(trackSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetReceiptsByUserId(userId: string, options?: any): AxiosPromise<ReceiptDtoListMimexResponse> {
            return localVarFp.shoppingControllerGetReceiptsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the list of receipts for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetUserReceipts(userSessionFindDto: UserSessionFindDto, options?: any): AxiosPromise<ReceiptDtoListMimexResponse> {
            return localVarFp.shoppingControllerGetUserReceipts(userSessionFindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the receipts summary available for the logged-in user
         * @param {UserSessionFindDto} userSessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerGetUserReceiptsSummary(userSessionFindDto: UserSessionFindDto, options?: any): AxiosPromise<ReceiptSummaryDtoListMimexResponse> {
            return localVarFp.shoppingControllerGetUserReceiptsSummary(userSessionFindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset store status and checkout all active sessions
         * @param {StoreResetDto} storeResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerResetStore(storeResetDto: StoreResetDto, options?: any): AxiosPromise<SendTrackSessionUpdateDtoMimexResponse> {
            return localVarFp.shoppingControllerResetStore(storeResetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for sessions, constrained by current logged-in user
         * @param {SessionFindDto} sessionFindDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSearch(sessionFindDto: SessionFindDto, options?: any): AxiosPromise<ShoppingSessionDtoListMimexResponse> {
            return localVarFp.shoppingControllerSearch(sessionFindDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the list of receipts for a user
         * @param {SearchReceiptsDto} searchReceiptsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSearchReceipts(searchReceiptsDto: SearchReceiptsDto, options?: any): AxiosPromise<ReceiptDtoListMimexResponse> {
            return localVarFp.shoppingControllerSearchReceipts(searchReceiptsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSetSendTrackSessionUpdateFalse(options?: any): AxiosPromise<SendTrackSessionUpdateDtoMimexResponse> {
            return localVarFp.shoppingControllerSetSendTrackSessionUpdateFalse(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start publishing update events regarding the association between sessions tracks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerSetSendTrackSessionUpdateTrue(options?: any): AxiosPromise<SendTrackSessionUpdateDtoMimexResponse> {
            return localVarFp.shoppingControllerSetSendTrackSessionUpdateTrue(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a session
         * @param {ShoppingSessionDto} shoppingSessionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingControllerUpdate(shoppingSessionDto: ShoppingSessionDto, options?: any): AxiosPromise<void> {
            return localVarFp.shoppingControllerUpdate(shoppingSessionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export the smartrack background files
         * @param {boolean} zip 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerExport(zip: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerExport(zip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerExpose(options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerExpose(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SmarTrack GUI status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerGuiExposeStatus(options?: any): AxiosPromise<GuiStatusMimexResponse> {
            return localVarFp.smartRackControllerGuiExposeStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import the smartrack background files
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerImport(file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerImport(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the smartrack background files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerReset(options?: any): AxiosPromise<ProcessHandlerStatusMimexResponse> {
            return localVarFp.smartRackControllerReset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restart SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerRestart(options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerRestart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStart(options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerStart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SmarTrack status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStatus(options?: any): AxiosPromise<ProcessHandlerStatusMimexResponse> {
            return localVarFp.smartRackControllerStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop SmarTrack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStop(options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerStop(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop SmarTrack GUI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smartRackControllerStopExposing(options?: any): AxiosPromise<void> {
            return localVarFp.smartRackControllerStopExposing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerCreate(stockDto: StockDto, options?: any): AxiosPromise<StockDtoMimexResponse> {
            return localVarFp.stocksControllerCreate(stockDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the stock alerts for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerGetAlerts(storeId: string, options?: any): AxiosPromise<StockDtoListMimexResponse> {
            return localVarFp.stocksControllerGetAlerts(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the expiring products (in 7 days) for a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerGetExpiring(storeId: string, options?: any): AxiosPromise<StockDtoListMimexResponse> {
            return localVarFp.stocksControllerGetExpiring(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer a stock
         * @param {MoveStockDto} moveStockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerMoveStock(moveStockDto: MoveStockDto, options?: any): AxiosPromise<StockDtoMimexResponse> {
            return localVarFp.stocksControllerMoveStock(moveStockDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRead(stockId: string, options?: any): AxiosPromise<StockDtoMimexResponse> {
            return localVarFp.stocksControllerRead(stockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a stock by ID
         * @param {string} stockId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemove(stockId: string, options?: any): AxiosPromise<void> {
            return localVarFp.stocksControllerRemove(stockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all stocks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemoveAll(options?: any): AxiosPromise<void> {
            return localVarFp.stocksControllerRemoveAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a stock
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerRemoveByFilter(stockSearchDto: StockSearchDto, options?: any): AxiosPromise<void> {
            return localVarFp.stocksControllerRemoveByFilter(stockSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset a stock availability to the total amount
         * @param {StockResetDto} stockResetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerResetRackStockAvailability(stockResetDto: StockResetDto, options?: any): AxiosPromise<void> {
            return localVarFp.stocksControllerResetRackStockAvailability(stockResetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for stocks
         * @param {StockSearchDto} stockSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerSearch(stockSearchDto: StockSearchDto, options?: any): AxiosPromise<StockDtoListMimexResponse> {
            return localVarFp.stocksControllerSearch(stockSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a stock
         * @param {StockDto} stockDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksControllerUpdate(stockDto: StockDto, options?: any): AxiosPromise<StockDtoMimexResponse> {
            return localVarFp.stocksControllerUpdate(stockDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a store
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerCreate(storeDto: StoreDto, options?: any): AxiosPromise<StoreDtoMimexResponse> {
            return localVarFp.storesControllerCreate(storeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store status
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerGetStatus(storeId: string, options?: any): AxiosPromise<StoreStatusDtoMimexResponse> {
            return localVarFp.storesControllerGetStatus(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerList(options?: any): AxiosPromise<StoreDtoListMimexResponse> {
            return localVarFp.storesControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerRead(storeId: string, options?: any): AxiosPromise<StoreDtoMimexResponse> {
            return localVarFp.storesControllerRead(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a store
         * @param {string} storeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerRemove(storeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.storesControllerRemove(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set store status
         * @param {string} storeId 
         * @param {StoreStatusDto} storeStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerSetStatus(storeId: string, storeStatusDto: StoreStatusDto, options?: any): AxiosPromise<StoreStatusDtoMimexResponse> {
            return localVarFp.storesControllerSetStatus(storeId, storeStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a store
         * @param {string} storeId 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storesControllerUpdate(storeId: string, storeDto: StoreDto, options?: any): AxiosPromise<StoreDtoMimexResponse> {
            return localVarFp.storesControllerUpdate(storeId, storeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a camera stream
         * @param {string} storeId 
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamControllerStream(storeId: string, cameraId: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamControllerStream(storeId, cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerClearStreams(options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerClearStreams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable a stream for a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerEnableStream(streamingRequestDto: StreamingRequestDto, options?: any): AxiosPromise<StreamingResponseDtoMimexResponse> {
            return localVarFp.streamingControllerEnableStream(streamingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve configured sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerGetCameraConfig(options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerGetCameraConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List streams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerGetStreams(options?: any): AxiosPromise<StreamingResponseDtoListMimexResponse> {
            return localVarFp.streamingControllerGetStreams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start record a stream from a camera
         * @param {StreamingRequestDto} streamingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStartRecord(streamingRequestDto: StreamingRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerStartRecord(streamingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop all active recordings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStopAllRecords(options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerStopAllRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop recording a stream from a camera
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerStopRecord(clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerStopRecord(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a stream from a camera
         * @param {string} streamid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamingControllerView(streamid: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamingControllerView(streamid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a product to a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerAddItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any): AxiosPromise<void> {
            return localVarFp.virtualCartControllerAddItem(sessionId, virtualCartItemAddDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for carts
         * @param {VirtualCartSearchDto} virtualCartSearchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerAdminSearch(virtualCartSearchDto: VirtualCartSearchDto, options?: any): AxiosPromise<VirtualCartResponseDtoListMimexResponse> {
            return localVarFp.virtualCartControllerAdminSearch(virtualCartSearchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerCreateVirtualCart(sessionId: string, options?: any): AxiosPromise<VirtualCartCreateDtoMimexResponse> {
            return localVarFp.virtualCartControllerCreateVirtualCart(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerDeleteVirtualCart(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.virtualCartControllerDeleteVirtualCart(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a cart by session ID
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerGetVirtualCart(sessionId: string, options?: any): AxiosPromise<VirtualCartResponseDtoMimexResponse> {
            return localVarFp.virtualCartControllerGetVirtualCart(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a product from a cart by session ID
         * @param {string} sessionId 
         * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        virtualCartControllerRemoveItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any): AxiosPromise<void> {
            return localVarFp.virtualCartControllerRemoveItem(sessionId, virtualCartItemAddDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a webhook
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerCreate(webhookDto: WebhookDto, options?: any): AxiosPromise<WebhookDtoListMimexResponse> {
            return localVarFp.webhookControllerCreate(webhookDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List available webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerList(options?: any): AxiosPromise<WebhookDtoListMimexResponse> {
            return localVarFp.webhookControllerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a webhook
         * @param {string} webhookId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerRemove(webhookId: string, options?: any): AxiosPromise<void> {
            return localVarFp.webhookControllerRemove(webhookId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a webhook
         * @param {string} webhookId 
         * @param {WebhookDto} webhookDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookControllerUpdate(webhookId: string, webhookDto: WebhookDto, options?: any): AxiosPromise<WebhookDtoListMimexResponse> {
            return localVarFp.webhookControllerUpdate(webhookId, webhookDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analyze readers signal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerAnalyzeAllReadersSignal(options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerAnalyzeAllReadersSignal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analyze reader signal
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerAnalyzeSignal(readerid: string, cellid: string, options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerAnalyzeSignal(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calibrate cell with zero weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCalibCellW0(readerid: string, cellid: string, options?: any): AxiosPromise<CalibResultDtoMimexResponse> {
            return localVarFp.weightControllerCalibCellW0(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calibrate cell with reference weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCalibCellWRef(readerid: string, cellid: string, options?: any): AxiosPromise<CalibResultDtoMimexResponse> {
            return localVarFp.weightControllerCalibCellWRef(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cell state
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCellState(readerid: string, cellid: string, options?: any): AxiosPromise<CellStateDtoMimexResponse> {
            return localVarFp.weightControllerCellState(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a cell status
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCellStatus(readerid: string, cellid: string, options?: any): AxiosPromise<CellStatusDtoMimexResponse> {
            return localVarFp.weightControllerCellStatus(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cell weight
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerCurrentWeight(readerid: string, cellid: string, options?: any): AxiosPromise<WeightDtoMimexResponse> {
            return localVarFp.weightControllerCurrentWeight(readerid, cellid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerDisableStream(readerid: string, cellid: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerDisableStream(readerid, cellid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable reader stream
         * @param {string} readerid 
         * @param {string} cellid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerEnableStream(readerid: string, cellid: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerEnableStream(readerid, cellid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a reader status
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerReaderStatus(readerid: string, options?: any): AxiosPromise<ReaderStatusDtoMimexResponse> {
            return localVarFp.weightControllerReaderStatus(readerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reload readers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerRelaodReaders(options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerRelaodReaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStartReader(readerid: string, options?: any): AxiosPromise<void> {
            return localVarFp.weightControllerStartReader(readerid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get readers status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStatus(options?: any): AxiosPromise<StatusDtoMimexResponse> {
            return localVarFp.weightControllerStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stop a reader
         * @param {string} readerid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weightControllerStopReader(readerid: string, options?: any): AxiosPromise<ReaderStatusDtoMimexResponse> {
            return localVarFp.weightControllerStopReader(readerid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Drop content from specfied collections
     * @param {DropCollection} dropCollection 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDatabaseControllerDrop(dropCollection: DropCollection, options?: any) {
        return DefaultApiFp(this.configuration).adminDatabaseControllerDrop(dropCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export a dump of specified collections
     * @param {ExportCollection} exportCollection 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDatabaseControllerExport(exportCollection: ExportCollection, options?: any) {
        return DefaultApiFp(this.configuration).adminDatabaseControllerExport(exportCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import records from a file
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDatabaseControllerImport(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).adminDatabaseControllerImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import records from an env context
     * @param {string} env 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDatabaseControllerImportEnv(env: string, options?: any) {
        return DefaultApiFp(this.configuration).adminDatabaseControllerImportEnv(env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger a recording from an env context
     * @param {string} env 
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminDatabaseControllerTriggerEnv(env: string, recordingId: string, options?: any) {
        return DefaultApiFp(this.configuration).adminDatabaseControllerTriggerEnv(env, recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a running playback script
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminPlaybackControllerCancelScript(uuid: string, options?: any) {
        return DefaultApiFp(this.configuration).adminPlaybackControllerCancelScript(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel all running playback scripts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminPlaybackControllerCancelScripts(options?: any) {
        return DefaultApiFp(this.configuration).adminPlaybackControllerCancelScripts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List playback scripts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminPlaybackControllerListScripts(options?: any) {
        return DefaultApiFp(this.configuration).adminPlaybackControllerListScripts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run a playback script
     * @param {PlaybackOptions} playbackOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminPlaybackControllerRunScript(playbackOptions: PlaybackOptions, options?: any) {
        return DefaultApiFp(this.configuration).adminPlaybackControllerRunScript(playbackOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close user session
     * @param {CloseSessionOptions} closeSessionOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminScriptsControllerCloseSession(closeSessionOptions: CloseSessionOptions, options?: any) {
        return DefaultApiFp(this.configuration).adminScriptsControllerCloseSession(closeSessionOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open user session
     * @param {OpenSessionOptions} openSessionOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminScriptsControllerOpenSession(openSessionOptions: OpenSessionOptions, options?: any) {
        return DefaultApiFp(this.configuration).adminScriptsControllerOpenSession(openSessionOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run a complete user session
     * @param {UserSessionOptions} userSessionOptions 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminScriptsControllerUserSession(userSessionOptions: UserSessionOptions, options?: any) {
        return DefaultApiFp(this.configuration).adminScriptsControllerUserSession(userSessionOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an API key
     * @param {ApiKeyDto} apiKeyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiKeyControllerCreate(apiKeyDto: ApiKeyDto, options?: any) {
        return DefaultApiFp(this.configuration).apiKeyControllerCreate(apiKeyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an API key
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiKeyControllerDelete(id: string, options?: any) {
        return DefaultApiFp(this.configuration).apiKeyControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an asset from storage
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsControllerRemove(id: string, options?: any) {
        return DefaultApiFp(this.configuration).assetsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save an asset to storage
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsControllerSave(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).assetsControllerSave(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an asset from storage
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public assetsControllerServe(id: string, options?: any) {
        return DefaultApiFp(this.configuration).assetsControllerServe(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new user
     * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerAddUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerAddUser(authAdminUserCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a user token by ID
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerCreateToken(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerCreateToken(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete users by email or uid
     * @param {number} keepAccount 
     * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerDeleteUsers(keepAccount: number, authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerDeleteUsers(keepAccount, authAdminUsersListQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable a user by ID
     * @param {string} userId 
     * @param {AuthAdminFirebaseEnableUserDto} authAdminFirebaseEnableUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerEnable(userId: string, authAdminFirebaseEnableUserDto: AuthAdminFirebaseEnableUserDto, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerEnable(userId, authAdminFirebaseEnableUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a user profile by user ID
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerGetProfile(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerGetProfile(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all users
     * @param {AuthAdminUsersListQuery} authAdminUsersListQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerListUsers(authAdminUsersListQuery: AuthAdminUsersListQuery, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerListUsers(authAdminUsersListQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user by ID
     * @param {string} userId 
     * @param {number} keepAccount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerRemoveUser(userId: string, keepAccount: number, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerRemoveUser(userId, keepAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing user. Creates a local account record if missing
     * @param {AuthAdminUserCreateDto} authAdminUserCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAdminControllerUpdateUser(authAdminUserCreateDto: AuthAdminUserCreateDto, options?: any) {
        return DefaultApiFp(this.configuration).authAdminControllerUpdateUser(authAdminUserCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the account for the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerGetUser(options?: any) {
        return DefaultApiFp(this.configuration).authControllerGetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the account for the logged-in user
     * @param {UserAccountDto} userAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerUpdateUser(userAccountDto: UserAccountDto, options?: any) {
        return DefaultApiFp(this.configuration).authControllerUpdateUser(userAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedule automatic payment by session ID
     * @param {AutomaticPaymentDto} automaticPaymentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto: AutomaticPaymentDto, options?: any) {
        return DefaultApiFp(this.configuration).automaticPaymentControllerStartAutomaticPayment(automaticPaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the state for a recording (eg. record, play, pause)
     * @param {string} recordingId 
     * @param {'record' | 'stop' | 'play' | 'flush'} logOperation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerControl(recordingId: string, logOperation: 'record' | 'stop' | 'play' | 'flush', options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerControl(recordingId, logOperation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new recording. Also store recording items if provided
     * @param {LogRecordingDto} logRecordingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerCreate(logRecordingDto: LogRecordingDto, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerCreate(logRecordingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a recording
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerDelete(recordingId: string, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerDelete(recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a recording
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerGet(recordingId: string, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerGet(recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve items for a recording
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerGetItems(recordingId: string, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerGetItems(recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List available recordings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerList(options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove items from a recording
     * @param {string} recordingId 
     * @param {LogItemRemoveQueryDto} logItemRemoveQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerRemoveItems(recordingId: string, logItemRemoveQueryDto: LogItemRemoveQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerRemoveItems(recordingId, logItemRemoveQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger an event as provided via payload
     * @param {LogItemDto} logItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerTriggerEvent(logItemDto: LogItemDto, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerTriggerEvent(logItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a recording
     * @param {string} recordingId 
     * @param {LogRecordingDto} logRecordingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public brokerLoggerControllerUpdate(recordingId: string, logRecordingDto: LogRecordingDto, options?: any) {
        return DefaultApiFp(this.configuration).brokerLoggerControllerUpdate(recordingId, logRecordingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all products in the catalog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerDeleteAllProducts(options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerDeleteAllProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download catalog as CSV
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerExportCatalog(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerExportCatalog(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all store products in the catalog
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerGetAllProductsByStore(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerGetAllProductsByStore(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a product from the catalog
     * @param {string} storeId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerGetProduct(storeId: string, id: string, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerGetProduct(storeId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import catalog from CSV
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerImportCatalog(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerImportCatalog(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a product in the catalog
     * @param {ProductDto} productDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerInsertProduct(productDto: ProductDto, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerInsertProduct(productDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a product in the catalog
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerRemoveProduct(id: string, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerRemoveProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search store products in the catalog by name
     * @param {string} storeId 
     * @param {string} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerSearchProducts(storeId: string, q: string, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerSearchProducts(storeId, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search store products in the catalog
     * @param {string} storeId 
     * @param {ProductSearchDto} productSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerSearchProductsFilter(storeId: string, productSearchDto: ProductSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerSearchProductsFilter(storeId, productSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a product in the catalog
     * @param {ProductDto} productDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public catalogControllerUpdateProduct(productDto: ProductDto, options?: any) {
        return DefaultApiFp(this.configuration).catalogControllerUpdateProduct(productDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset the number of people in the store to zero
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkinControllerCheckoutAll(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).checkinControllerCheckoutAll(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new access QR code
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkinControllerCreateQRcode(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).checkinControllerCreateQRcode(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve checkin status
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkinControllerStatus(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).checkinControllerStatus(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate user provided QR code at checkin
     * @param {TotemToken} totemToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkinControllerValidateQRcode(totemToken: TotemToken, options?: any) {
        return DefaultApiFp(this.configuration).checkinControllerValidateQRcode(totemToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a stream source for the camera
     * @param {PlayRequestDto} playRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detectorStreamingControllerSetSource(playRequestDto: PlayRequestDto, options?: any) {
        return DefaultApiFp(this.configuration).detectorStreamingControllerSetSource(playRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a recording on the camera stream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detectorStreamingControllerStartRecord(options?: any) {
        return DefaultApiFp(this.configuration).detectorStreamingControllerStartRecord(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop a recording instance
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detectorStreamingControllerStopRecording(recordingId: string, options?: any) {
        return DefaultApiFp(this.configuration).detectorStreamingControllerStopRecording(recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop all recording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detectorStreamingControllerStopRecordings(options?: any) {
        return DefaultApiFp(this.configuration).detectorStreamingControllerStopRecordings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the camera stream
     * @param {string} detector 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public detectorStreamingControllerStream(detector: string, options?: any) {
        return DefaultApiFp(this.configuration).detectorStreamingControllerStream(detector, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the status of a door [open, closed]
     * @param {'entrance' | 'exit'} doorType Door type [entrance, exit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public doorControllerDoorStatus(doorType: 'entrance' | 'exit', options?: any) {
        return DefaultApiFp(this.configuration).doorControllerDoorStatus(doorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the status of a door controller
     * @param {string} storeId ID of the store
     * @param {'entrance' | 'exit'} doorType The type of the door [entrance, exit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public doorControllerOpenDoor(storeId: string, doorType: 'entrance' | 'exit', options?: any) {
        return DefaultApiFp(this.configuration).doorControllerOpenDoor(storeId, doorType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login a user by credentials returning an access token
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginControllerGetJWT(login: Login, options?: any) {
        return DefaultApiFp(this.configuration).loginControllerGetJWT(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a new token for the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginControllerRefreshToken(options?: any) {
        return DefaultApiFp(this.configuration).loginControllerRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request the password reset for an user
     * @param {Reset} reset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginControllerResetPassword(reset: Reset, options?: any) {
        return DefaultApiFp(this.configuration).loginControllerResetPassword(reset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a job to the worker
     * @param {MLJobRequestDto} mLJobRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLManagerControllerAdd(mLJobRequestDto: MLJobRequestDto, options?: any) {
        return DefaultApiFp(this.configuration).mLManagerControllerAdd(mLJobRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List worker jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLManagerControllerList(options?: any) {
        return DefaultApiFp(this.configuration).mLManagerControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a job from the worker
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLManagerControllerRemove(id: string, options?: any) {
        return DefaultApiFp(this.configuration).mLManagerControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes a single image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLManagerControllerUploadFile(options?: any) {
        return DefaultApiFp(this.configuration).mLManagerControllerUploadFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerDelDetectorParameters(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerDelDetectorParameters(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerDelDetectorTraining(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerDelDetectorTraining(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerDelEmbedderInference(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerDelEmbedderInference(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerDelEmbedderParameters(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerDelEmbedderParameters(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerDelEmbedderTraining(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerDelEmbedderTraining(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetAllDetectorraining(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetAllDetectorraining(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetAllEmbedderInference(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetAllEmbedderInference(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetAllEmbedderTraining(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetAllEmbedderTraining(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetDetectorParameters(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetDetectorParameters(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetEmbedderInferenceProductId(productId: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetEmbedderInferenceProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} modelid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetEmbedderParameters(modelid: string, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetEmbedderParameters(modelid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} label 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerGetEmbedderProductIdFromLabel(label: number, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerGetEmbedderProductIdFromLabel(label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MLDetectorParametersDto} mLDetectorParametersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto: MLDetectorParametersDto, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerPutDetectorParameters(mLDetectorParametersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerPutDetectorTraining(requestBody: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerPutDetectorTraining(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MLEmbedderInferenceDto} mLEmbedderInferenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto: MLEmbedderInferenceDto, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerPutEmbedderInference(mLEmbedderInferenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MLEmbedderParametersDto} mLEmbedderParametersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto: MLEmbedderParametersDto, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerPutEmbedderParameters(mLEmbedderParametersDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mLRepositoryControllerPutEmbedderTraining(requestBody: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).mLRepositoryControllerPutEmbedderTraining(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate user ACL on subscription
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mqttAuthControllerGetAcl(body: object, options?: any) {
        return DefaultApiFp(this.configuration).mqttAuthControllerGetAcl(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate user authentication on connection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mqttAuthControllerGetUser(options?: any) {
        return DefaultApiFp(this.configuration).mqttAuthControllerGetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set notification to read
     * @param {string} storeId 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerMarkAsRead(storeId: string, notificationId: string, options?: any) {
        return DefaultApiFp(this.configuration).notificationControllerMarkAsRead(storeId, notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete All notifications from store
     * @param {string} storeId ID of the store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerRemoveAll(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).notificationControllerRemoveAll(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete All notifications by Type
     * @param {string} storeId ID of the store
     * @param {'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace'} message The type of notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerRemoveAllByType(storeId: string, message: 'ShelfOutOfStock' | 'StockMismatch' | 'OpenPurchaseOrderDelivery' | 'UpcomingExpiryDate' | 'OutOfPlace', options?: any) {
        return DefaultApiFp(this.configuration).notificationControllerRemoveAllByType(storeId, message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve notifications for a store
     * @param {string} storeId 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerStatus(storeId: string, limit?: number, skip?: number, options?: any) {
        return DefaultApiFp(this.configuration).notificationControllerStatus(storeId, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a mock payment method
     * @param {string} userId 
     * @param {number} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminCreateMockPaymentMethod(userId: string, force: number, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminCreateMockPaymentMethod(userId, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export payments as CSV
     * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminExportPaymentsCSV(searchPaymentQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export payments as JSON
     * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminExportPaymentsJSON(searchPaymentQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export sold products as CSV
     * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminExportSoldProductsCSV(searchPaymentQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export user sessions as CSV
     * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminExportUserSessionsCSV(searchPaymentQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for payments
     * @param {SearchPaymentQueryDto} searchPaymentQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerAdminSearch(searchPaymentQueryDto: SearchPaymentQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerAdminSearch(searchPaymentQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle BKM payment result
     * @param {BKMPaymentResultDto} bKMPaymentResultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerBkmPaymentResult(bKMPaymentResultDto: BKMPaymentResultDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerBkmPaymentResult(bKMPaymentResultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Handle BKM payment setup result
     * @param {BKMPaymentSetupResultDto} bKMPaymentSetupResultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto: BKMPaymentSetupResultDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerBkmPaymentSetupResult(bKMPaymentSetupResultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Setup Stripe to register a credit card for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerCreateCardSecret(options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerCreateCardSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Stripe payment intent
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerCreatePaymentIntent(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerCreatePaymentIntent(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a payment methods references
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerDeleteCardSecret(id: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerDeleteCardSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve Stripe registered payment methods references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerGetCardSecret(options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerGetCardSecret(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve payment amount
     * @param {string} sessionid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerGetPaymentAmount(sessionid: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerGetPaymentAmount(sessionid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List payment methods
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerListPayments(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerListPayments(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for payment methods
     * @param {string} period 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerListPaymentsFiltered(period: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerListPaymentsFiltered(period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Stripe payment as suceeded
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerPaymentSucceeded(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerPaymentSucceeded(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Stripe credit card reference for a user
     * @param {UpdateCardDto} updateCardDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentControllerUpdateCardSecret(updateCardDto: UpdateCardDto, options?: any) {
        return DefaultApiFp(this.configuration).paymentControllerUpdateCardSecret(updateCardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restream smartrack events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerActivateSmartrackRestreamFromBroker(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerActivateSmartrackRestreamFromBroker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop smartrack restream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerDeactivateSmartrackRestreamFromBroker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tracks events publishing status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerGetPublishSmartrackStatus(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerGetPublishSmartrackStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find tracks by positions
     * @param {TrackSearchDto} trackSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerGetTrackByPosition(trackSearchDto: TrackSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerGetTrackByPosition(trackSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve tracks positions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerGetTrackPositions(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerGetTrackPositions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish people tracker tracks over NATS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerPublishPeopleTrackerTracks(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerPublishPeopleTrackerTracks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable tracks events publishing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerPublishSmartrackEvent(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerPublishSmartrackEvent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset people tracker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerResetPeopleTracker(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerResetPeopleTracker(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable tracks events publishing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerStopPublishingSmartrackEvent(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerStopPublishingSmartrackEvent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stops publishing people tracker tracks over NATS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public peopleTrackerControllerUnpublishPeopleTrackerTracks(options?: any) {
        return DefaultApiFp(this.configuration).peopleTrackerControllerUnpublishPeopleTrackerTracks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove store product pricing
     * @param {string} storeId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pricingControllerDeleteProductPrice(storeId: string, productId: string, options?: any) {
        return DefaultApiFp(this.configuration).pricingControllerDeleteProductPrice(storeId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create store product pricing
     * @param {string} storeId 
     * @param {ProductPriceDto} productPriceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pricingControllerInsertProductPrice(storeId: string, productPriceDto: ProductPriceDto, options?: any) {
        return DefaultApiFp(this.configuration).pricingControllerInsertProductPrice(storeId, productPriceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store product pricing
     * @param {string} storeId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pricingControllerSearchProducts(storeId: string, productId: string, options?: any) {
        return DefaultApiFp(this.configuration).pricingControllerSearchProducts(storeId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update store product pricing
     * @param {string} storeId 
     * @param {string} productId 
     * @param {ProductPriceDto} productPriceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pricingControllerUpdateProductPrice(storeId: string, productId: string, productPriceDto: ProductPriceDto, options?: any) {
        return DefaultApiFp(this.configuration).pricingControllerUpdateProductPrice(storeId, productId, productPriceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the user profile for the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profileControllerRead(options?: any) {
        return DefaultApiFp(this.configuration).profileControllerRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the user profile for the logged-in user
     * @param {UserProfileUpdateDto} userProfileUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profileControllerSave(userProfileUpdateDto: UserProfileUpdateDto, options?: any) {
        return DefaultApiFp(this.configuration).profileControllerSave(userProfileUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the invoicing information for the logged-in user
     * @param {UserProfileInvoiceDto} userProfileInvoiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profileControllerSaveInvoice(userProfileInvoiceDto: UserProfileInvoiceDto, options?: any) {
        return DefaultApiFp(this.configuration).profileControllerSaveInvoice(userProfileInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put out of place product
     * @param {OutOfPlaceDto} outOfPlaceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerAddOutOfPlace(outOfPlaceDto: OutOfPlaceDto, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerAddOutOfPlace(outOfPlaceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new rack
     * @param {RackDto} rackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerCreateRack(rackDto: RackDto, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerCreateRack(rackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all racks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerDeleteAllRacks(options?: any) {
        return DefaultApiFp(this.configuration).racksControllerDeleteAllRacks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a rack by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerDeleteRackById(id: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerDeleteRackById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all racks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerGetAllRacks(options?: any) {
        return DefaultApiFp(this.configuration).racksControllerGetAllRacks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get shelf out of place products
     * @param {string} shelfid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerGetOutOfPlace(shelfid: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerGetOutOfPlace(shelfid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find a rack by cell ID
     * @param {string} cellId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerGetRackByCellId(cellId: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerGetRackByCellId(cellId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a rack by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerGetRackById(id: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerGetRackById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove out of place product
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerRemoveOutOfPlace(id: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerRemoveOutOfPlace(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset stock status for all racks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerResetAllRacksStockAvailability(options?: any) {
        return DefaultApiFp(this.configuration).racksControllerResetAllRacksStockAvailability(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset stock status for a rack by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerResetStockAvailability(id: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerResetStockAvailability(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for a rack by name
     * @param {string} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerSearchRacks(q: string, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerSearchRacks(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a rack by ID
     * @param {string} id 
     * @param {RackDto} rackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public racksControllerUpdateRack(id: string, rackDto: RackDto, options?: any) {
        return DefaultApiFp(this.configuration).racksControllerUpdateRack(id, rackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reader health status by ID
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerCheckReaderHealthStatusById(readerId: string, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerCheckReaderHealthStatusById(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get readers health status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerCheckReadersHealthStatus(options?: any) {
        return DefaultApiFp(this.configuration).readersControllerCheckReadersHealthStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create reader
     * @param {ReaderDto} readerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerCreateReader(readerDto: ReaderDto, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerCreateReader(readerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerDeleteAllReaders(options?: any) {
        return DefaultApiFp(this.configuration).readersControllerDeleteAllReaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove reader by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerDeleteReaderById(id: string, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerDeleteReaderById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export readers configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerExportReadersConfigurations(options?: any) {
        return DefaultApiFp(this.configuration).readersControllerExportReadersConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerGetAllReaders(options?: any) {
        return DefaultApiFp(this.configuration).readersControllerGetAllReaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calibration data for a reader
     * @param {string} readerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerGetCalibrationByReaderId(readerId: string, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerGetCalibrationByReaderId(readerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reader by ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerGetReaderById(id: string, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerGetReaderById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload readers configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerImportReadersConfigurations(options?: any) {
        return DefaultApiFp(this.configuration).readersControllerImportReadersConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search readers by name
     * @param {string} q 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerSearchReaders(q: string, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerSearchReaders(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update calibration data for a reader
     * @param {string} readerId 
     * @param {string} cellId 
     * @param {CalibDto} calibDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerUpdateCellCalib(readerId: string, cellId: string, calibDto: CalibDto, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerUpdateCellCalib(readerId, cellId, calibDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cell params for a reader
     * @param {string} readerId 
     * @param {string} cellId 
     * @param {CellExtraParamsDto} cellExtraParamsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerUpdateCellExtraParams(readerId: string, cellId: string, cellExtraParamsDto: CellExtraParamsDto, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerUpdateCellExtraParams(readerId, cellId, cellExtraParamsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update reader
     * @param {ReaderDto} readerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public readersControllerUpdateReader(readerDto: ReaderDto, options?: any) {
        return DefaultApiFp(this.configuration).readersControllerUpdateReader(readerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all recordings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public recordingControllerRemove(options?: any) {
        return DefaultApiFp(this.configuration).recordingControllerRemove(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a new user
     * @param {UserProfileDto} userProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registrationControllerRegistrationProcess(userProfileDto: UserProfileDto, options?: any) {
        return DefaultApiFp(this.configuration).registrationControllerRegistrationProcess(userProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public registrationControllerUnregistrationProcess(login: Login, options?: any) {
        return DefaultApiFp(this.configuration).registrationControllerUnregistrationProcess(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start the restream
     * @param {PeopleTrackerTestDto} peopleTrackerTestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public restreamControllerStart(peopleTrackerTestDto: PeopleTrackerTestDto, options?: any) {
        return DefaultApiFp(this.configuration).restreamControllerStart(peopleTrackerTestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create item in RFID product mapping
     * @param {RfidCartDto} rfidCartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rfidCartControllerCreateItem(rfidCartDto: RfidCartDto, options?: any) {
        return DefaultApiFp(this.configuration).rfidCartControllerCreateItem(rfidCartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove item from RFID product mapping
     * @param {string} tagId 
     * @param {RfidCartDto} rfidCartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rfidCartControllerDeleteItem(tagId: string, rfidCartDto: RfidCartDto, options?: any) {
        return DefaultApiFp(this.configuration).rfidCartControllerDeleteItem(tagId, rfidCartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item by RFID tag
     * @param {string} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rfidCartControllerGetItemByTag(tagId: string, options?: any) {
        return DefaultApiFp(this.configuration).rfidCartControllerGetItemByTag(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import batch RFID product mapping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rfidCartControllerImportCatalog(options?: any) {
        return DefaultApiFp(this.configuration).rfidCartControllerImportCatalog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update item in RFID product mapping
     * @param {string} tagId 
     * @param {RfidCartDto} rfidCartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rfidCartControllerUpdateItem(tagId: string, rfidCartDto: RfidCartDto, options?: any) {
        return DefaultApiFp(this.configuration).rfidCartControllerUpdateItem(tagId, rfidCartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove sessions-related items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sessionLoggerControllerFlush(options?: any) {
        return DefaultApiFp(this.configuration).sessionLoggerControllerFlush(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for sessions-related items
     * @param {SessionLoggerQueryDto} sessionLoggerQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sessionLoggerControllerSearch(sessionLoggerQueryDto: SessionLoggerQueryDto, options?: any) {
        return DefaultApiFp(this.configuration).sessionLoggerControllerSearch(sessionLoggerQueryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for sessions
     * @param {SessionFindDto} sessionFindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerAdminSearch(sessionFindDto: SessionFindDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerAdminSearch(sessionFindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel all open sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerCancelOpen(options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerCancelOpen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a session
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerCreate(body: object, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a session
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerDelete(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerDelete(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all sessions
     * @param {SessionFindDto} sessionFindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerDeleteAll(sessionFindDto: SessionFindDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerDeleteAll(sessionFindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a session by ID
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGet(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGet(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetAllSessions(options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetAllSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the current open session (if any) for the logged-in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetCurrentSession(options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetCurrentSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve session events by session ID
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetEvents(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetEvents(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find nearest lost track by position
     * @param {TrackSearchDto} trackSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetLostTrackByPosition(trackSearchDto: TrackSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetLostTrackByPosition(trackSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the list of receipts for a user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetReceiptsByUserId(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetReceiptsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the list of receipts for the logged-in user
     * @param {UserSessionFindDto} userSessionFindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetUserReceipts(userSessionFindDto: UserSessionFindDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetUserReceipts(userSessionFindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the receipts summary available for the logged-in user
     * @param {UserSessionFindDto} userSessionFindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerGetUserReceiptsSummary(userSessionFindDto: UserSessionFindDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerGetUserReceiptsSummary(userSessionFindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset store status and checkout all active sessions
     * @param {StoreResetDto} storeResetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerResetStore(storeResetDto: StoreResetDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerResetStore(storeResetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for sessions, constrained by current logged-in user
     * @param {SessionFindDto} sessionFindDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerSearch(sessionFindDto: SessionFindDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerSearch(sessionFindDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the list of receipts for a user
     * @param {SearchReceiptsDto} searchReceiptsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerSearchReceipts(searchReceiptsDto: SearchReceiptsDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerSearchReceipts(searchReceiptsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End publishing update events regarding the association between sessions tracks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerSetSendTrackSessionUpdateFalse(options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerSetSendTrackSessionUpdateFalse(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start publishing update events regarding the association between sessions tracks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerSetSendTrackSessionUpdateTrue(options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerSetSendTrackSessionUpdateTrue(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a session
     * @param {ShoppingSessionDto} shoppingSessionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shoppingControllerUpdate(shoppingSessionDto: ShoppingSessionDto, options?: any) {
        return DefaultApiFp(this.configuration).shoppingControllerUpdate(shoppingSessionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export the smartrack background files
     * @param {boolean} zip 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerExport(zip: boolean, options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerExport(zip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable SmarTrack GUI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerExpose(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerExpose(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SmarTrack GUI status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerGuiExposeStatus(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerGuiExposeStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import the smartrack background files
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerImport(file?: any, options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerImport(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the smartrack background files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerReset(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerReset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restart SmarTrack
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerRestart(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerRestart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start SmarTrack
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerStart(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerStart(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SmarTrack status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerStatus(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop SmarTrack
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerStop(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerStop(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop SmarTrack GUI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smartRackControllerStopExposing(options?: any) {
        return DefaultApiFp(this.configuration).smartRackControllerStopExposing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new stock
     * @param {StockDto} stockDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerCreate(stockDto: StockDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerCreate(stockDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the stock alerts for a store
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerGetAlerts(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerGetAlerts(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the expiring products (in 7 days) for a store
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerGetExpiring(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerGetExpiring(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer a stock
     * @param {MoveStockDto} moveStockDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerMoveStock(moveStockDto: MoveStockDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerMoveStock(moveStockDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a stock by ID
     * @param {string} stockId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerRead(stockId: string, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerRead(stockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a stock by ID
     * @param {string} stockId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerRemove(stockId: string, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerRemove(stockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all stocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerRemoveAll(options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerRemoveAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a stock
     * @param {StockSearchDto} stockSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerRemoveByFilter(stockSearchDto: StockSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerRemoveByFilter(stockSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset a stock availability to the total amount
     * @param {StockResetDto} stockResetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerResetRackStockAvailability(stockResetDto: StockResetDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerResetRackStockAvailability(stockResetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for stocks
     * @param {StockSearchDto} stockSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerSearch(stockSearchDto: StockSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerSearch(stockSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a stock
     * @param {StockDto} stockDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stocksControllerUpdate(stockDto: StockDto, options?: any) {
        return DefaultApiFp(this.configuration).stocksControllerUpdate(stockDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a store
     * @param {StoreDto} storeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerCreate(storeDto: StoreDto, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerCreate(storeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store status
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerGetStatus(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerGetStatus(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List stores
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerList(options?: any) {
        return DefaultApiFp(this.configuration).storesControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a store
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerRead(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerRead(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a store
     * @param {string} storeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerRemove(storeId: string, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerRemove(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set store status
     * @param {string} storeId 
     * @param {StoreStatusDto} storeStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerSetStatus(storeId: string, storeStatusDto: StoreStatusDto, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerSetStatus(storeId, storeStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a store
     * @param {string} storeId 
     * @param {StoreDto} storeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storesControllerUpdate(storeId: string, storeDto: StoreDto, options?: any) {
        return DefaultApiFp(this.configuration).storesControllerUpdate(storeId, storeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a camera stream
     * @param {string} storeId 
     * @param {string} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamControllerStream(storeId: string, cameraId: string, options?: any) {
        return DefaultApiFp(this.configuration).streamControllerStream(storeId, cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove streams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerClearStreams(options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerClearStreams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable a stream for a camera
     * @param {StreamingRequestDto} streamingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerEnableStream(streamingRequestDto: StreamingRequestDto, options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerEnableStream(streamingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve configured sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerGetCameraConfig(options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerGetCameraConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List streams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerGetStreams(options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerGetStreams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start record a stream from a camera
     * @param {StreamingRequestDto} streamingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerStartRecord(streamingRequestDto: StreamingRequestDto, options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerStartRecord(streamingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop all active recordings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerStopAllRecords(options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerStopAllRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop recording a stream from a camera
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerStopRecord(clientId: string, options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerStopRecord(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a stream from a camera
     * @param {string} streamid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public streamingControllerView(streamid: string, options?: any) {
        return DefaultApiFp(this.configuration).streamingControllerView(streamid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a product to a cart by session ID
     * @param {string} sessionId 
     * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerAddItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerAddItem(sessionId, virtualCartItemAddDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for carts
     * @param {VirtualCartSearchDto} virtualCartSearchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerAdminSearch(virtualCartSearchDto: VirtualCartSearchDto, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerAdminSearch(virtualCartSearchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a cart by session ID
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerCreateVirtualCart(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerCreateVirtualCart(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a cart by session ID
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerDeleteVirtualCart(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerDeleteVirtualCart(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a cart by session ID
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerGetVirtualCart(sessionId: string, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerGetVirtualCart(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a product from a cart by session ID
     * @param {string} sessionId 
     * @param {VirtualCartItemAddDto} virtualCartItemAddDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public virtualCartControllerRemoveItem(sessionId: string, virtualCartItemAddDto: VirtualCartItemAddDto, options?: any) {
        return DefaultApiFp(this.configuration).virtualCartControllerRemoveItem(sessionId, virtualCartItemAddDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a webhook
     * @param {WebhookDto} webhookDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webhookControllerCreate(webhookDto: WebhookDto, options?: any) {
        return DefaultApiFp(this.configuration).webhookControllerCreate(webhookDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List available webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webhookControllerList(options?: any) {
        return DefaultApiFp(this.configuration).webhookControllerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a webhook
     * @param {string} webhookId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webhookControllerRemove(webhookId: string, options?: any) {
        return DefaultApiFp(this.configuration).webhookControllerRemove(webhookId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a webhook
     * @param {string} webhookId 
     * @param {WebhookDto} webhookDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webhookControllerUpdate(webhookId: string, webhookDto: WebhookDto, options?: any) {
        return DefaultApiFp(this.configuration).webhookControllerUpdate(webhookId, webhookDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analyze readers signal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerAnalyzeAllReadersSignal(options?: any) {
        return DefaultApiFp(this.configuration).weightControllerAnalyzeAllReadersSignal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analyze reader signal
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerAnalyzeSignal(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerAnalyzeSignal(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calibrate cell with zero weight
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerCalibCellW0(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerCalibCellW0(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calibrate cell with reference weight
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerCalibCellWRef(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerCalibCellWRef(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cell state
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerCellState(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerCellState(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a cell status
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerCellStatus(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerCellStatus(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cell weight
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerCurrentWeight(readerid: string, cellid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerCurrentWeight(readerid, cellid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable reader stream
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerDisableStream(readerid: string, cellid: string, body: object, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerDisableStream(readerid, cellid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable reader stream
     * @param {string} readerid 
     * @param {string} cellid 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerEnableStream(readerid: string, cellid: string, body: object, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerEnableStream(readerid, cellid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a reader status
     * @param {string} readerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerReaderStatus(readerid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerReaderStatus(readerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reload readers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerRelaodReaders(options?: any) {
        return DefaultApiFp(this.configuration).weightControllerRelaodReaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start a reader
     * @param {string} readerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerStartReader(readerid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerStartReader(readerid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get readers status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerStatus(options?: any) {
        return DefaultApiFp(this.configuration).weightControllerStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stop a reader
     * @param {string} readerid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weightControllerStopReader(readerid: string, options?: any) {
        return DefaultApiFp(this.configuration).weightControllerStopReader(readerid, options).then((request) => request(this.axios, this.basePath));
    }
}


