import axios from 'axios';
import { emitter, getUserToken, UserStoreChanged, UserStoreOperation, UserStoreType } from '../helpers/userStore';
import { createLogger } from "../logger";
import { APP, AppConfig } from "../config";
import { Configuration, DefaultApi } from "../sdk/index";

const logger = createLogger({ component: 'api-client' })

const defaultBasePath = (): string => process.env.REACT_APP_BACKEND_URL || ''
let overrideBasePath: string = null

emitter.on(UserStoreType.configLoader, (ev: UserStoreChanged) => {

    if (ev.op !== UserStoreOperation.UPDATED) {
        return
    }
    
    const appConfig = ev.value as AppConfig
    if (appConfig.backendUrl) {
        logger.info(`Using API URL ${appConfig.backendUrl}`)
        overrideBasePath = appConfig.backendUrl
    }

    initClient()
})

let __client: DefaultApi = new DefaultApi(undefined, defaultBasePath())

const initClient = (basePath?: string) => {

    if (localStorage.backendUrl) {
        logger.warn(`Using backend url from localStorage.backendUrl=${localStorage.backendUrl}`)
    }

    const baseApiPath = localStorage.backendUrl || overrideBasePath || basePath || defaultBasePath() || APP.BACKEND_URL

    const accessToken = getUserToken() || ''

    const config = new Configuration({
        basePath: baseApiPath,
        apiKey: accessToken,
        accessToken: accessToken,
    })

    const axiosInstance = axios.create({
        baseURL: baseApiPath,
    });

    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    const client = new DefaultApi(config, baseApiPath, axiosInstance)

    logger.debug(`Initialized client basePath=${baseApiPath}`)

    __client = client
    return client
}


export const createClient = (basePath?: string): DefaultApi => {
    emitter.on(UserStoreType.userToken, (ev: UserStoreChanged) => {
        initClient(basePath)
    })
    return initClient(basePath)
}

export const getClient = (): DefaultApi => {
    return __client
}
