import React, { useCallback, useEffect, useState } from 'react'
import {
    IonButton,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonThumbnail,
    IonTitle, IonToolbar
} from '@ionic/react'
import { PaymentMethod } from '@stripe/stripe-js'
import { Trans, useTranslation } from 'react-i18next'
import { getPaymentCardSecretHandler, createCardSecretHandler, deletePaymentCardSecretHandler } from 'mimex-libs/api/endpoints'
import { createLogger } from 'mimex-libs/logger'
import TopToolbar from '../../components/TopToolbar'
import { GENERIC_ERROR } from 'mimex-libs/helpers/constants'
import CreditCardAdd from './components/CreditCardAdd'
import CreditCardList from './components/CreditCardList'

import './ManagePaymentMethod.css'
import { memoryStore, UserStoreType } from 'mimex-libs/helpers/userStore'
import { getCustomerAppConfig } from 'mimex-libs/config'
import { useHistory } from "react-router-dom";
import TabBarContainer from 'src/components/TabBarContainer'
import BKMCardList from './components/BKMCardList'
import { BASE_URL } from "mimex-libs/helpers/constants";

const logger = createLogger({ component: 'ManagePaymentMethod' })

interface ManagePaymentMethodProps {
    paymentMethods?: PaymentMethod[]
    onCardSaved: () => void
}

const ManagePaymentMethod: React.FC<ManagePaymentMethodProps> = (props) => {
    const { t } = useTranslation(['translation'])
    const [error, setError] = useState('')
    const [changePaymentMethod, setChangePaymentMethod] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>()
    const [loading, setLoading] = useState(false)
    const config = getCustomerAppConfig()
    const history = useHistory()

    const getPaymentMethods = useCallback(() => {
        if (paymentMethods !== undefined) return
        if (loading) return
        setLoading(true)
        getPaymentCardSecretHandler()
            .then((paymentMethods: PaymentMethod[]) => {
                memoryStore.set(UserStoreType.paymentMethod, (paymentMethods && paymentMethods.length > 0))
                if (!paymentMethods) {
                    setError('')
                    setPaymentMethods([])
                    return
                }
                setError('')
                setPaymentMethods(paymentMethods)
            })
            .catch((error: any) => {
                logger.error(error, { section: 'getPaymentMethods' })
                setError(GENERIC_ERROR)
                setPaymentMethods([])
                memoryStore.set(UserStoreType.paymentMethod, false)
            })
            .finally(() => setLoading(false))
    }, [])

    const onChangePaymentMethod = () => {
        setChangePaymentMethod(true)
    }

    const onDeletePaymentMethod = (id: string) => {
        console.log('Delete payment method:', id)
        if (confirm('Confirm deleting the payment method?')) {
            setLoading(true)
            deletePaymentCardSecretHandler(id)
                .then(() => {
                    setPaymentMethods([])
                })
                .catch((error: any) => {
                    logger.error(error, { section: 'getPaymentMethods' })
                    setError(GENERIC_ERROR)
                })
                .finally(() => setLoading(false))
        }
    }

    const onCardSaved = () => {
        setChangePaymentMethod(false)
        getPaymentMethods()
        props.onCardSaved()
    }

    const checkBKMPayment = async (): Promise<void> => {
        setLoading(true)
        try {
            const result = await createCardSecretHandler()
            if (result.redirectUrl) {
                window.location.href = result.redirectUrl
            }
        } catch (e: any) {
            logger.error(e)
            setError(GENERIC_ERROR)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (paymentMethods !== undefined) return
        if (!props.paymentMethods) return
        setPaymentMethods(props.paymentMethods)
    }, [getPaymentMethods, props.paymentMethods])

    useEffect(() => {
        if (paymentMethods !== undefined) return
        if (props.paymentMethods) return
        getPaymentMethods()
    })

    return (
        <IonPage data-testid="manage-payment-method">
            <TopToolbar title={t("Payment method")} />
            <IonContent fullscreen>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">
                            {t("Payment method")}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>

                {loading ?
                    <IonLoading
                        isOpen={loading}
                        message={t('Loading')}
                    />
                    :

                    <IonCardContent>
                        {error !== '' ? (
                            <IonItem lines="none">
                                <IonLabel color="danger">
                                    <Trans>{error}</Trans>
                                </IonLabel>
                            </IonItem>
                        ) : (
                            (config.PAYMENT_SERVICE && config.PAYMENT_SERVICE === 'bkm') ? (
                                (paymentMethods && paymentMethods.length) ? (
                                    <BKMCardList
                                        data-testid="payment-method-list"
                                        paymentMethods={paymentMethods}
                                        deletePaymentMethod={onDeletePaymentMethod}
                                    />
                                ) : (
                                    <IonItem lines="none">
                                        <IonButton
                                            expand="block"
                                            color="primary"
                                            type="submit"
                                            fill="clear"
                                            onClick={(e) => checkBKMPayment()}
                                        >
                                            <IonThumbnail slot="start" className="bkm-add-img">
                                                <img alt="BKM Express" src={`${BASE_URL}/assets/images/bkm-logo.png`} />
                                            </IonThumbnail>
                                            {t("Add Card with BKM Express")}
                                        </IonButton>
                                    </IonItem>
                                )
                            ) : (
                                (changePaymentMethod || (!paymentMethods || !paymentMethods.length)) ? (
                                    <CreditCardAdd onCardSaved={onCardSaved} data-testid="add-payment" />
                                ) : (
                                    <CreditCardList
                                        data-testid="payment-method-list"
                                        paymentMethods={paymentMethods}
                                        changePaymentMethod={onChangePaymentMethod}
                                    />
                                )
                            )
                        )}
                    </IonCardContent>
                }

                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-align-self-start"></IonCol>
                        <IonCol className="ion-align-self-end"></IonCol>
                    </IonRow>
                </IonGrid>


            </IonContent>
            <TabBarContainer />
        </IonPage>
    )
}

export default React.memo(ManagePaymentMethod)


