import { ProductImage } from 'apps/catalog/src/catalog.dto'

export enum CartStatusSource {
  Weight = 'WEIGHT',
  Rfid = 'RFID',
  User = 'USER',
  Shelf = 'SHELF',
  Vision = 'VISION'
}

export enum CartStatusOperation {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export interface SourceDto {
  src: CartStatusSource;
  op: CartStatusOperation;
  ref: string;
}

export interface VirtualCartItemDto {
  productId: string;
  quantity: number;
  name: string;
  description: string;
  unitPrice: number;
  currency: string;
  images: ProductImage[];
  weight: number;
  source: Array<SourceDto>;

}

export interface CartEvent{
    action: string
    quantity: number
    shelfId: string
    product: VirtualCartItemDto
    timestamp: number
}

export enum CartEventStatus {
  UPDATE = 'UPDATE'
}