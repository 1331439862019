import React, { useCallback } from "react";
import { IonCol, IonPage, IonContent, IonGrid, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useProfileInfo from "../CustomHooks/useProfileInfo";
import { BASE_URL } from "mimex-libs/helpers/constants";
import { AccountFooter } from '../pages/account/components/AccountFooter';
import TabBarContainer from "./TabBarContainer";
import TopToolbar from './TopToolbar';

import "./PaymentStatus.css";
interface PaymentStatusProps {
  isEmptyCart: boolean
  totalPrice: string
  currency: string
}

const PaymentStatus = (props: PaymentStatusProps) => {
  const { isEmptyCart, totalPrice, currency } = props

  const history = useHistory()
  const { t } = useTranslation(["translation"]);

  const inputs = useProfileInfo()

  const onSubmit = useCallback(() => {
    history.push("/")
  }, [history])

  return (
    <IonPage data-testid="payment-status">
      <TopToolbar />
      <IonContent>
        <IonGrid fixed={true}>
          <IonRow className="content-layout">
            <IonCol className="payment-status">
              <img
                src={`${BASE_URL}/assets/images/payment-done.svg`} alt="MIMEX"
                className="payment-logo"
              />
            </IonCol>
          </IonRow>
          {isEmptyCart ?
            <IonRow className="content-layout">
              <IonCol>
                <h1 className="no-payment">{`${t('Thank you')} ${inputs?.name} ${t('for visiting the micro market.')}`}</h1>
              </IonCol>
            </IonRow>
            :
            <div>
              <IonRow className="content-layout">
                <IonCol>
                  <h1 className="payment-name">{`${t('Thank you')} ${inputs?.name}`}</h1>
                </IonCol>
              </IonRow>
              <IonRow className="content-layout">
                <IonCol>
                  <h1 className="payment-amount">{`${currency} ${totalPrice}`}</h1>
                </IonCol>
              </IonRow>
              <IonRow className="content-layout">
                <IonCol>
                  <h4 className="payment-charge">{t("will be charged from your card")}</h4>
                </IonCol>
              </IonRow>
            </div>
          }
        </IonGrid>
      </IonContent>
      <AccountFooter buttonClick={onSubmit} buttonLabel={t('Return to home')} disabled={false} />
      <TabBarContainer />
    </IonPage>
  )
}

export default PaymentStatus

