
import { IonIcon, IonLabel, IonTabBar, IonTabButton, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { cartOutline, compassOutline, receiptOutline, storefrontOutline } from "ionicons/icons";
import React from "react";
import { PATH_RECEIPTS, PATH_STORES, PATH_VIRTUAL_CART } from "mimex-libs/helpers/constants";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from 'react-router-dom';
interface TabsCustomEvent extends CustomEvent {
    detail: { tab: string };
    target: HTMLIonTabsElement;
}
const TabBarContainer: React.FC = () => {
    const { t } = useTranslation(["translation"]);
    const [selectedTab, setSelectedTab] = React.useState(PATH_VIRTUAL_CART);
    const tabBarList = [
        {
            label: t("Cart"),
            tab: "cart",
            href: PATH_VIRTUAL_CART,
            icon: cartOutline,
        },
        {
            label: t("Stores"),
            tab: "stores",
            href: PATH_STORES,
            icon: storefrontOutline,
        },
        {
            label: t("Receipts"),
            tab: "receipts",
            href: PATH_RECEIPTS,
            icon: receiptOutline,
        },

    ];

    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        setSelectedTab(location.pathname);
    }, [location.pathname, setSelectedTab, history]);



    return (
        <IonTabBar slot="bottom" data-testid="tab-container" >
            {tabBarList.map((item) => (
                <IonTabButton
                    key={item.tab}
                    data-testid={item.tab}
                    tab={item.tab}
                    href={item.href}
                >
                    <IonIcon icon={item.icon} />
                    <IonLabel>{item.label}</IonLabel>
                </IonTabButton>

            ))}
        </IonTabBar>
    );
}


export default React.memo(TabBarContainer);