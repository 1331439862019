import React from 'react'

import './MapInfoWindow.css'

const MapInfoWindow = (props:any) => {
    const {store}=props
    return (
    <div className="info-window-main" data-testid="map-info">
       <div className="container">
       <img className="store-image"
                  src={store.image}
                  alt={store.name}/>
    </div>
    <div className="container">
      <h5>{store.name}</h5>
    </div>
    <div className="container">
     <p>{store.address}</p>
    </div>
</div>          

    )
}

export default MapInfoWindow
