import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonThumbnail } from '@ionic/react';
import { basketOutline } from "ionicons/icons";
import { parseCurrency } from "mimex-libs/helpers/currency";
import { VirtualCartItemDto } from 'mimex-libs/sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AssetImage from './AssetImage';
import './ItemsList.css';

interface ItemListProps {
    items: VirtualCartItemDto[],
    showQuantity: boolean,
    showEmpty: boolean,
    addItem: CallableFunction,
    removeItem?: CallableFunction,
    selectedId: string,
    selected: CallableFunction,
    updating: boolean
}

const NoItems = () => {
    const { t } = useTranslation()
    return (
        <IonItem lines="none" className="ion-margin-top ion-float-center ion-text-center"><IonCol>
            <IonIcon icon={basketOutline} color="primary" size="large"></IonIcon>
            <IonLabel className="ion-text-wrap">
                <p><b>{t('Your cart is empty')}</b></p>
                <p>{t('Add the products you need to the cart and complete your shopping.')}</p>
            </IonLabel>
        </IonCol></IonItem>
    )
}

const ItemsList = (props: ItemListProps) => {
    return (
        <IonList data-testid="item-list" lines="none"> {(((!props.items || props.items.length === 0) && props.showEmpty) ? <NoItems /> : props.items?.filter(f => f.quantity > 0).map((item) =>
            <IonItem id="item-id" class="tap-item" key={item.productId}
                onClick={(e: any) => { e.persist(); props.selected(e, item) }} color={props.selectedId === item.productId ? 'primary' : ''}>
                <IonThumbnail slot="start" class="productThumb">
                    <AssetImage testId="user-account-avatar" alt="?" src={item.images as any} />
                </IonThumbnail>
                <IonLabel>
                    <h2>{item.name}</h2>
                    <p>{parseCurrency(item.currency)} {item.unitPrice.toFixed(2)}</p>
                </IonLabel>
                {props.showQuantity && (
                    <IonLabel slot="end" class="ion-text-end">
                        <IonRow class="ion-justify-content-between ion-align-items-center add-remove-slot">
                            <IonButton data-testid="remove-item-button" disabled={props.updating} expand="full" shape="round" class="add-remove-btn"
                                onClick={(e: any) => { e.persist(); props.removeItem && props.removeItem(item) }}>-</IonButton>
                            <IonLabel class="ion-padding-end ion-padding-start"><b>{item.quantity}</b></IonLabel>
                            <IonButton data-testid="add-item-button" disabled={props.updating} expand="full" shape="round" class="add-remove-btn"
                                onClick={(e: any) => { e.persist(); props.addItem(item) }}>+</IonButton>
                        </IonRow>
                    </IonLabel>
                )}
            </IonItem>
        ))}
        </IonList>)

}

export default ItemsList
