import { IonHeader, IonImg } from '@ionic/react';
import { BASE_URL } from 'mimex-libs/helpers/constants';
import React from 'react';
import './Account.css';
import { getCustomerAppConfig } from "mimex-libs/config";


interface AccountHeaderProps {
}
let dep = 'prod-tr'
export function AccountHeader(props: AccountHeaderProps) {
    const config = getCustomerAppConfig()

    return (
        <IonHeader
            data-testid="account-header"
            // onClick={() => history.push(PATH_LOGIN)} 
            className="ion-text-center ion-no-border">
            <IonImg
                data-testid="account-header-img"
                src={config.LOGO_URL || `${BASE_URL}/assets/images/logo.svg`} alt="MIMEX"
                className="header-logo"
            />
        </IonHeader>
    )
}
