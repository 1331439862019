import { getAppVersion, setAppVersion } from 'mimex-libs/helpers/userStore';
import { useEffect } from 'react';

export const VersionChecker = (props: any) => {

    // const history = useHistory()

    const checkVersion = (versionA: string, versionB: string) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            if (a === b) continue;
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    }
    const refreshCacheAndReload = () => {
        if (caches) {
            //Service worker cache should be cleared with caches.delete()
            caches.keys().then(names => {
                for (let name of names) caches.delete(name);
            })
        }
        // delete browser cache and hard reload
        console.log("New version catch: swipe out cache and reload!")
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    useEffect(() => {
        fetch('meta.json')
            .then((res) => res.json())
            .then((meta) => {
                const latestVersion = meta.version
                const currentVersion = getAppVersion()
                if (!currentVersion) {
                    setAppVersion(latestVersion)
                    return
                }

                const reload = checkVersion(latestVersion, currentVersion)
                if (reload) {
                    refreshCacheAndReload()
                    setAppVersion(latestVersion)
                }
            })
    }, [])


    return (
        <></>
    )
}