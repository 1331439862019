import {
    IonButton, IonCardContent, IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonCol, IonFooter, IonGrid, IonItem, IonLabel, IonRow, useIonLoading
} from '@ionic/react';
import {
    CardCvcElement,
    CardExpiryElement, CardNumberElement, useElements, useStripe
} from '@stripe/react-stripe-js';
import { PaymentMethod, StripeCardNumberElement } from '@stripe/stripe-js';
import {
    createCardSecretHandler,
    updateCardSecretHandler
} from 'mimex-libs/api/endpoints';
import { GENERIC_ERROR } from 'mimex-libs/helpers/constants';
import { createLogger } from 'mimex-libs/logger';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOptions from '../../../CustomHooks/useOptions';
import './CreditCardAdd.css';

interface CreditCardAddProps {
    onCardSaved: CallableFunction
}

const initFocus = {
    isNumber: false,
    isName: false,
    isExpiryDate: false,
    isCvc: false,
}
const logger = createLogger({ component: 'CreditCardAdd' })

const CreditCardAdd = (props: CreditCardAddProps) => {
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const { t } = useTranslation(["translation"]);
    const [present, dismiss] = useIonLoading()
    const [error, setError] = useState('')
    const [billingDetails, setBillingDetails] = useState({
        name: '',
    })
    const [cardOnFocus, setCardOnFocus] = useState(initFocus)

    const getSecret = async (): Promise<string | null> => {
        try {
            const result = await createCardSecretHandler()
            setError('')
            return result.secret
        } catch (e: any) {
            logger.error(e)
            setError(GENERIC_ERROR)
            return null
        }
    }

    const updatePaymentMethod = (paymentMethodId: string) => {
        logger.info(`update payment id=${paymentMethodId}`, {
            section: 'updatePaymentMethod',
        })
        return updateCardSecretHandler(paymentMethodId)
            .then(() => {
                setError('')
                props.onCardSaved()
            })
            .catch((e) => {
                logger.error(e, { section: 'updatePaymentMethod' })
                setError(GENERIC_ERROR)
            })
    }

    const handleCardSave = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        present({
            duration: 3000,
            keyboardClose: true,
        })

        const secret = await getSecret()
        if (!secret) {
            logger.debug('getSecret failed', { section: 'saveCard' })
            dismiss()
            return
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            logger.warn('stripe || elements not avail', { section: 'saveCard' })
            dismiss()
            return
        }

        const cardNumberElement: StripeCardNumberElement | null | undefined =
            elements?.getElement(CardNumberElement)
        // const cardElement: StripeCardElement | null | undefined =
        //     elements?.getElement(CardElement)
        if (cardNumberElement === null) {
            logger.error('CardElement not available', { section: 'saveCard' })
            setError(GENERIC_ERROR)
            dismiss()
            return
        }

        try {
            logger.debug('confirmCardSetup()', { section: 'saveCard' })
            const result = await stripe?.confirmCardSetup(secret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: billingDetails,
                },
            })

            // show error
            if (result.error) {
                const msg = result?.error?.message
                setError(msg || GENERIC_ERROR)
                dismiss()
                return
            }
            if (result.setupIntent?.payment_method) {
                const pm = result.setupIntent?.payment_method
                await updatePaymentMethod(
                    typeof pm === "string" ? pm as string : (pm as PaymentMethod).id
                )
            }
        } catch (e: any) {
            logger.error(e, { section: 'saveCard', ref: 'updatePaymentMethod' })
            setError(GENERIC_ERROR)
        } finally {
            dismiss()
        }
    }

    const onReady = () => {
        setCardOnFocus(initFocus)
    }
    const onBlur = () => {
        setCardOnFocus(initFocus)
    }

    const onFocus = (focusParam: string) => {
        setCardOnFocus((prevState: any) => ({
            ...prevState,
            [focusParam]: true,
        }))
    }

    const onChange = (event: any) => {
        // console.log('CardNumberElement [change]', event)
    }
    const cardFocus = 'card-focus'
    const cardElement = 'card-element'

    return (
        <>
            <IonCardHeader data-testid="add-card">
                <IonCardTitle className="add-card-title">
                    {t("Add a payment method")}
                </IonCardTitle>
                <IonCardSubtitle>
                    {t("Enter your credit card information")}
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <form data-testid="save-card" onSubmit={(ev) => handleCardSave(ev)}>
                    {error && (
                        <IonItem>
                            <IonLabel color="danger">
                                {t(error)}
                            </IonLabel>
                        </IonItem>
                    )}
                    <span className="card-container">
                        <div className="left-field">
                            <label>
                                {t("Card number")}
                                <CardNumberElement
                                    data-testid="card-number"
                                    className={
                                        cardOnFocus.isNumber
                                            ? cardFocus
                                            : cardElement
                                    }
                                    id="isNumber"
                                    options={options}
                                    onReady={onReady}
                                    onBlur={onBlur}
                                    onFocus={() => onFocus('isNumber')}
                                    onChange={(event: any) => onChange(event)}
                                />
                            </label>
                        </div>
                        <div className="right-field">
                            <label>
                                {t("Name on card")}
                                <div>
                                    <input
                                        className={
                                            cardOnFocus.isName
                                                ? cardFocus
                                                : 'card-name'
                                        }
                                        id="isName"
                                        type="text"
                                        placeholder="Jane Doe"
                                        required
                                        autoComplete="name"
                                        value={billingDetails.name}
                                        onFocus={() => onFocus('isName')}
                                        onChange={(e) => {
                                            setBillingDetails({
                                                ...billingDetails,
                                                name: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            </label>
                        </div>
                    </span>
                    <span className="card-container">
                        <div className="left-field">
                            <label>
                                {t("Expiration date")}
                                <CardExpiryElement
                                    className={
                                        cardOnFocus.isExpiryDate
                                            ? cardFocus
                                            : cardElement
                                    }
                                    id="isExpiryDate"
                                    options={options}
                                    onReady={onReady}
                                    onBlur={onBlur}
                                    onFocus={() => onFocus('isExpiryDate')}
                                    onChange={(event: any) => onChange(event)}
                                />
                            </label>
                        </div>
                        <div className="right-field">
                            <label>
                                {t("CVC code")}
                                <CardCvcElement
                                    className={
                                        cardOnFocus.isCvc
                                            ? cardFocus
                                            : cardElement
                                    }
                                    id="isCvc"
                                    options={options}
                                    onReady={onReady}
                                    onBlur={onBlur}
                                    onFocus={() => onFocus('isCvc')}
                                    onChange={(event: any) => onChange(event)}
                                />
                            </label>
                        </div>
                    </span>
                    <IonFooter className="ion-no-border add-card-button-group">
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton
                                        expand="block"
                                        color="primary"
                                        type="submit"
                                        disabled={!stripe}
                                        fill="solid"
                                    >
                                        {t("Add your card")}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonFooter>
                </form>
            </IonCardContent>
        </>
    )
}

export default CreditCardAdd
