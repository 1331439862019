import React from 'react'
import { IonButton, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonFooter, IonGrid, IonRow } from '@ionic/react'
import { PaymentMethod } from '@stripe/stripe-js'
import { Trans, useTranslation } from 'react-i18next'

import './CreditCardList.css'

interface CreditCardListProp {
    paymentMethods: PaymentMethod[]
    changePaymentMethod: CallableFunction
}

const CreditCardList = (props: CreditCardListProp) => {
    const { t } = useTranslation(['translation'])

    const changePaymentMethod = () => {
        props.changePaymentMethod()
    }
    return (
        <>
            <IonCardHeader data-testid="card-list">
                <IonCardSubtitle>{t("Payment method information")}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                {props.paymentMethods &&
                    props.paymentMethods.map((paymentMethod: PaymentMethod) => {
                        return (
                            <div
                                className="card-view-container"
                                key={paymentMethod.id}
                            >
                                <div className="left-field">
                                    <label>
                                        {t("Card number")}
                                        <div>
                                            <input
                                                className="background-white card-name"
                                                id="card-number"
                                                type="text"
                                                disabled
                                                value={`${t("ending with")} ${paymentMethod.card?.last4}`}
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="right-field">
                                    <label>
                                        {t("Name on card")}
                                        <div>
                                            <input
                                                className="background-white card-name"
                                                id="card-name"
                                                type="text"
                                                disabled
                                                value={
                                                    paymentMethod
                                                        .billing_details
                                                        ?.name || ''
                                                }
                                            />
                                        </div>
                                    </label>
                                </div>

                                <div className="left-field">
                                    <label>
                                        {t("Expiration date")}
                                        <div>
                                            <input
                                                className="background-white card-name"
                                                id="card-expiry"
                                                type="text"
                                                disabled
                                                value={
                                                    paymentMethod.card
                                                        ?.exp_month +
                                                    '/' +
                                                    paymentMethod.card?.exp_year
                                                }
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="right-field">
                                    <label>
                                        {t("Brand")}
                                        <div>
                                            <input
                                                className="background-white card-name"
                                                id="card-cvc"
                                                type="text"
                                                disabled
                                                value={
                                                    paymentMethod.card?.brand
                                                }
                                            />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        )
                    })}
                <IonFooter className="ion-no-border add-card-button-group">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonButton
                                    expand="block"
                                    color="primary"
                                    fill="solid"
                                    onClick={(e) => changePaymentMethod()}
                                >
                                    {t("Change Card")}
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonFooter>
            </IonCardContent>
        </>
    )
}

export default CreditCardList
