import { APP } from "../config"

export const APP_NAME = APP.NAME
export const BASE_URL = APP.BASEURL

export const PATH_ONBOARDING = "/onboarding"
export const PATH_HOME = "/"
export const PATH_USER_ACCOUNT = "/account"
export const PATH_LOGIN = "/login"
export const PATH_LOGOUT = "/logout"
export const PATH_REGISTRATION = "/registration"
export const PATH_VERIFY_EMAIL = "/verify-email"
export const PATH_PASSWORD_RESET = "/password-reset"
export const PATH_PAYMENT_METHODS = "/payment/credit-cards"
export const PATH_BILLING_ADDRESS = "/billing-address"
export const PATH_PAYMENT = "/payment"
export const PATH_QRCODE = "/qrcode"
export const PATH_VIRTUAL_CART = "/virtual-cart"
export const PATH_SETTINGS = "/settings"
export const PATH_RECEIPTS = "/receipts"
export const PATH_RECEIPT_DETAILS = "/receipts/:id"
export const PATH_STORES = "/stores"
export const PATH_HELP = "/help"

export const MAX_INPUT_LENGTH = 25

export const GENERIC_ERROR = 'An error occurred, please try later'
export const SUCCESS = 'success'
export const ERROR = 'danger'
export const WARNING = 'warning'
export const LIGHT = 'light'
export const DARK = 'dark'


export const LANGUAGES = [
    {
        code: 'en',
        name: 'EN',
    },

    {
        code: 'it',
        name: 'IT',
    },

    {
        code: 'es',
        name: 'ES',
    },

    {
        code: 'tr',
        name: 'TR',
    },
]


export const slideContent = [
    {
        image: '/customer-app/assets/images/logo.svg',
        title: '',
        mainSlide: true,
        id:"onboarding-slide-1"
    },
    {
        image: '/customer-app/assets/images/slide1.svg',
        title: 'Sign in if you have already an account or sign up',
        secondSlide: true,
        paginationText: 'Swipe to continue',
        id:"onboarding-slide-2"
    },
    {
        image: '/customer-app/assets/images/slide2.svg',
        title: 'Add a payment method',
        thirdSlide: true,
        id:"onboarding-slide-3"
    },
    {
        image: '/customer-app/assets/images/slide3.svg',
        title: 'Scan QR code and enjoy Mimex experience',
        finalSlide: true,
        id:"onboarding-slide-4"
        
    },
]

export const MonthsList: Record<string, string> = {
  "-1": "Recent receipts",
  "0":  "January",
  "1":  "February",
  "2":  "March",
  "3":  "April",
  "4":  "May",
  "5":  "June",
  "6":  "July",
  "7":  "August",
  "8":  "September",
  "9":  "October",
  "10": "November",
  "11": "December",
}

export const PROFILE_LIST = [
  // {
  //   "id": "0",
  //   "title": "account",
  //   "path": PATH_USER_ACCOUNT,
  //   "props": "account props",
  //   "disabled": true
  // },
  {
    "id": "1",
    "title": "payment",
    "path": PATH_PAYMENT,
    "props": "payment props",
    "disabled": false
  },
  {
    "id": "2",
    "title": "settings",
    "path": PATH_SETTINGS,
    "props": "settings props",
    "disabled": false
  },
  {
    "id": "3",
    "title": "Help and Support",
    "path": PATH_HELP,
    "props": "help props",
    "disabled": false
  },
  {
    "id": "4",
    "title": "logout",
    "path": PATH_LOGOUT,
    "props": "logout props",
    "disabled": false
  }
]

export const PAYMENT_LIST = [
  {
    "id": "0",
    "title": "Payment method",
    "path": PATH_PAYMENT_METHODS,
    "props": "account props",
    "disabled": false
  },
  {
    "id": "1",
    "title": "Billing address",
    "path": PATH_BILLING_ADDRESS,
    "props": "payment props",
    "disabled": false
  },
]

export const HELP_LIST = [
  {
   id: 1,
   transI: "faq1-item", //Translation item
   transN: "faq1-name", //Translation name
   name: 'What is mimex',
   //item: 'An innovative shopping concept that allows you to enjoy a  barrier-free cashier-less shopping experience. A smart shop that makes purchasing as safe and fast as buying online!',
   show: false
  },
  {
    id: 2,
    transI: "faq2-item", //Translation item
    transN: "faq2-name", //Translation name
    name: 'How to shop mimex',
   // item: 'Instead of text we can have the either visual animation, or ppt about steps, instead of text, first we can clear the path, and steps once the shopper will have experienced in store. We should have definitive steps in shopping, we can clarify those steps and under each of those, we can add short text later we can transform text to informative visuals, pictograms. We should specify overall user experience first, I assume, out of test bed experience you have approach step by step what shoppers will face in store, again, we can keep the text short, more illustrative approach is needed with visuals payment&receipt; we can type official name of the payment provider and how to proceed receipt in mimex, both are not clear yet, then we can add quick summary how to handle payment',
    show: false
   },
   {
    id: 3,
    transI: "faq3-item", //Translation item
    transN: "faq3-name", //Translation name
    name: 'Legal information',
    //item: 'Please specify, which legal information we need to add agree or accept terms, data privacy monitoring authorization etc. contact us; info@mimex.shopping.com our official e-mail address, we also add phone and if we are going to offer customer service we need to also add detail, thus, official entity address',
    show: false
   },
]

type BillingAddressField = {
  id: string,
  dataTestId: string,
  type: string,
  autocomplete: any,
  placeholder: string,
  name: string,
  maxLength: number,
  value: string,
}

export const BILLING_ADDRESS_FIELDS : BillingAddressField[] = [
    {   id: "fullName",
        dataTestId: "billing-fullName",
        type:"text",
        autocomplete:"billing name",
        placeholder:"Full name",
        name:"fullName",
        maxLength:MAX_INPUT_LENGTH,
        value: "fullName",
    },
    {   id: "address",
        dataTestId: "billing-address",
        type:"text",
        autocomplete:"billing street-address",
        placeholder:"Address",
        name:"address",
        maxLength:MAX_INPUT_LENGTH,
        value:"address",
    },
    {   id: "phone",
        dataTestId:"billing-phone",
        type:"text",
        autocomplete:"billing tel",
        placeholder:"Phone",
        name:"phone",
        maxLength:MAX_INPUT_LENGTH,
        value:"phone",
    },
    {   id: "country",
        dataTestId:"billing-country",
        type:"text",
        autocomplete:"billing country",
        placeholder:"Country",
        name:"country",
        maxLength:MAX_INPUT_LENGTH,
        value:"country",
    },
    {   id: "city",
        dataTestId:"billing-city",
        type:"text",
        autocomplete:"billing locality address-level2",
        placeholder:"City",
        name:"city",
        maxLength:MAX_INPUT_LENGTH,
        value:"city",
    },
    {   id: "taxCode",
        dataTestId:"billing-taxCode",
        type:"text",
        autocomplete:"on",
        placeholder:"Tax code",
        name:"taxCode",
        maxLength:MAX_INPUT_LENGTH,
        value:"taxCode",
    },
    {   id: "zipCode",
        dataTestId:"billing-zipCode",
        type:"text",
        autocomplete:"billing postal-code",
        placeholder:"Zip code",
        name:"zipCode",
        maxLength:MAX_INPUT_LENGTH,
        value: "zipCode",
    }     
]


