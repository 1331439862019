import {
    IonContent,
    IonGrid,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRouterLink,
    IonSelect,
    IonSelectOption
} from '@ionic/react'
import { LANGUAGES, PATH_USER_ACCOUNT } from 'mimex-libs/helpers/constants'
import { getSelectedLanguage } from 'mimex-libs/helpers/userStore'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TopToolbar from '../components/TopToolbar'
import { BASE_URL, PATH_PASSWORD_RESET } from "mimex-libs/helpers/constants";
import TabBarContainer from 'src/components/TabBarContainer'


const Settings: React.FC = (props) => {

    const { t, i18n } = useTranslation(['translation'])

    const history = useHistory()

    const [language, setLanguage] = React.useState(getSelectedLanguage())
    const changeLanguage = (event: any) => {
        const selectedLanguage = event.target.value
        setLanguage(selectedLanguage as string)
        i18n.changeLanguage(selectedLanguage)
    }

    return (
        <IonPage data-testid="settings-page">
            <TopToolbar data-testid="top-toolbar" title="Settings" />
            <IonContent fullscreen>
                <IonGrid>
                    <IonList>
                        <IonItem>
                            <IonLabel>{t("Language")}</IonLabel>
                            <IonSelect
                                data-testid="language-select"
                                placeholder="Language"
                                value={language}
                                onIonChange={changeLanguage}
                            >
                                {LANGUAGES.map(({ code, name }) => (
                                    <IonSelectOption data-testid="select-option" key={code} value={code}>
                                        {name}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonRouterLink href={BASE_URL + PATH_PASSWORD_RESET}>{t("Reset password")}</IonRouterLink>
                        </IonItem>
                    </IonList>
                </IonGrid>
            </IonContent>
            <TabBarContainer />
        </IonPage >
    )
}

export default Settings
