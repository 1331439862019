import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  IonButton, IonCheckbox, IonCol, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonModal, IonRouterLink, IonRow, IonText, useIonLoading
} from '@ionic/react';
import moment from 'moment';
import passwordValidator from 'password-validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { GENERIC_ERROR, SUCCESS, ERROR, MAX_INPUT_LENGTH } from "mimex-libs/helpers/constants";
import { registrationProcessHandler } from "mimex-libs/api/endpoints";
import useToast from '../../CustomHooks/useToast';
import BillingAddressInsert from '../BillingAddressInsert';
import GDPRModal from './modals/gdprModal';
import TermsAndConditionsModal from './modals/TermsAndConditionsModal';
import './Registration.css';
import { APP, getCustomerAppConfig } from "mimex-libs/config";
import { eye, eyeOff } from "ionicons/icons"

interface RegistrationProps {
  onRegistrationSuccess: () => void
  setDisabled: (value: boolean) => void
  submit: boolean
}

const phoneRegex = "^(([+]{0,1}(([(][0-9]{1,4}[)])|([0-9]{1,4})))|([(][+]{0,1}(([(][0-9]{1,4}[)])|([0-9]{1,4}))[)])){0,1}[- \s \. 0-9]*$"
interface InputProps {
  address: string
  birthDate: string
  city: string
  country: string
  email: string
  emailConfirmation: string
  name: string
  password: string
  passwordConfirmation: string
  phone: string
  surname: string
  taxCode: string
  zipCode: string
  fullName: string
}

const initialValuesWithBillingDetails = {
  fullName: "",
  address: "",
  birthDate: "",
  city: "",
  country: "",
  email: "",
  emailConfirmation: "",
  name: "",
  password: "",
  passwordConfirmation: "",
  phone: "",
  surname: "",
  taxCode: "",
  zipCode: "",
}

const useFocus = (): [any, () => void] => {
  const htmlElRef: MutableRefObject<any> = useRef(null);
  const setFocus = (): void => {
    htmlElRef?.current?.setFocus?.()
  };

  return [htmlElRef, setFocus];
};

export function Registration(props: RegistrationProps) {

  const { t } = useTranslation(["translation"]);

  const [checkedBillingAddress, setCheckedBillingAddress] = useState(false);
  const [showProgress, hideProgress] = useIonLoading();
  const [renderToast] = useToast()
  const [inputs, setInputs] = useState<InputProps | any>(initialValuesWithBillingDetails);

  const config = useMemo(() => getCustomerAppConfig(), [])
  const customDeployment = useMemo(() => localStorage.getItem("customDeployment") || APP.DEPLOYMENT, [])

  const handleInputChange = useCallback(
    (event: any) => {
      const name = event.target.name
      const value = event.target.value
      setInputs((prevState: any) => ({
        ...prevState,
        [name]: value,
      }))
    },
    [setInputs]
  )
  const [nameRef, setNameFocus] = useFocus()
  const [surnameRef, setSurnameFocus] = useFocus()
  const [phoneRef, setPhoneFocus] = useFocus()
  const [birthDateRef, setBirthDateFocus] = useFocus()
  const [emailRef, setEmailFocus] = useFocus()
  const [emailConfirmationRef, setEmailConfirmationFocus] = useFocus()
  const [passwordRef, setPasswordFocus] = useFocus()
  const [passwordConfirmationRef, setPasswordConfirmationFocus] = useFocus()
  const [addressRef, setAddressFocus] = useFocus()
  const [countryRef, setCountryFocus] = useFocus()
  const [cityRef, setCityFocus] = useFocus()
  const [taxCodeRef, setTaxCodeFocus] = useFocus()
  const [zipCodeRef, setZipCodeFocus] = useFocus()
  const [fullNameRef, setFullNameFocus] = useFocus()

  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedGDPR, setCheckedGDPR] = useState(false)
  const [checkedCaptcha, setCheckedCaptcha] = useState('')

  const [visiblePassword, setVisiblePassword] = useState(false)
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false)

  //Modals for terms and Conditions and GDPR
  const [showGDPRModal, setShowGDPRModal] = useState(false)
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false)

  useEffect(() => {
    if (customDeployment !== 'prod-tr') return
    setCheckedGDPR(true)
    setCheckedTerms(true)
  }, [customDeployment])

  const onChange = (value: any) => {
    setCheckedCaptcha(value || '')
  }

  const isUserAdult = useCallback(() => {
    const years = moment().diff(inputs.birthDate, 'years', true)
    if (years >= 18) return true
    return false
  }, [inputs.birthDate])

  const checkEqualEmail = useCallback(() => {
    return inputs.email === inputs.emailConfirmation
  }, [inputs.email, inputs.emailConfirmation])

  const checkEqualPassword = useCallback(() => {
    return inputs.password === inputs.passwordConfirmation
  }, [inputs.password, inputs.passwordConfirmation])

  const onBillingAddressChecked = useCallback((e: any) => {
    const isChecked = e.detail.checked
    setCheckedBillingAddress(e.detail.checked)

    if (isChecked) {
      //TODO: handle form elements props, when checked
      console.log("Checked", isChecked)
    } else {
      console.log("Unchecked", isChecked)
      setInputs((prevState: any) => ({
        ...prevState,
        fullName: "",
        address: "",
        taxCode: "",
        zipCode: "",
        city: "",
        country: "",
      }))

    }
  }, [setCheckedBillingAddress])

  useEffect(() => {
    if (checkedBillingAddress) {
      props.setDisabled(!inputs.name || !inputs.surname || !inputs.fullName || !inputs.birthDate || !inputs.address || !inputs.country || !inputs.city || !inputs.taxCode || !inputs.zipCode || !inputs.phone || !inputs.email || !inputs.emailConfirmation
        || !inputs.password || !inputs.passwordConfirmation || !checkedTerms || !checkedCaptcha || !checkedGDPR)
    }
    else {
      props.setDisabled(!inputs.name || !inputs.surname || !inputs.birthDate || !inputs.phone || !inputs.email || !inputs.emailConfirmation
        || !inputs.password || !inputs.passwordConfirmation || !checkedTerms || !checkedCaptcha || !checkedGDPR)
    }
  }, [inputs, checkedCaptcha, checkedGDPR, checkedTerms, props, checkedBillingAddress])

  const createUser = useCallback(async () => {
    const inputValues = {
      email: inputs.email,
      emailConfirmation: inputs.emailConfirmation,
      password: inputs.password,
      passwordConfirmation: inputs.passwordConfirmation,
      name: inputs.name,
      surname: inputs.surname,
      birthDate: inputs.birthDate,
      phone: inputs.phone,
      termsAndConditions: checkedTerms,
      gdpr: checkedGDPR,
      captcha: checkedCaptcha,
      invoice: false
    }
    const inputValuesWithBillingDetails = {
      fullName: inputs.fullName,
      email: inputs.email,
      emailConfirmation: inputs.emailConfirmation,
      password: inputs.password,
      passwordConfirmation: inputs.passwordConfirmation,
      name: inputs.name,
      surname: inputs.surname,
      birthDate: inputs.birthDate,
      phone: inputs.phone,
      address: inputs.address,
      country: inputs.country,
      city: inputs.city,
      taxCode: inputs.taxCode,
      zipCode: inputs.zipCode,
      termsAndConditions: checkedTerms,
      gdpr: checkedGDPR,
      captcha: checkedCaptcha,
      invoice: true
    }

    const registrationPayload = checkedBillingAddress ? inputValuesWithBillingDetails : inputValues

    try {
      showProgress(t('Loading'), 5000)
      console.log("registrationPayload", registrationPayload)
      await registrationProcessHandler(registrationPayload);
      props.onRegistrationSuccess()
      renderToast(SUCCESS, t('check your email to activate your account'))
      setInputs(initialValuesWithBillingDetails)
    } catch (e: any) {
      if (typeof e.response.data.error !== "undefined") {
        renderToast(ERROR, e.response.data.error.message)
      } else {
        renderToast(ERROR, GENERIC_ERROR)
      }
    }
    finally {
      hideProgress()
    }
  }, [inputs.email, inputs.emailConfirmation, inputs.password, inputs.passwordConfirmation, inputs.name, inputs.surname, inputs.birthDate, inputs.phone, inputs.fullName, inputs.address, inputs.country, inputs.city, inputs.taxCode, inputs.zipCode, checkedTerms, checkedGDPR, checkedCaptcha, checkedBillingAddress, showProgress, t, props, renderToast, hideProgress])

  const submit = useCallback(() => {
    if (!inputs.name || inputs.name.length < 3) {
      renderToast(ERROR, "Name is empty")
      setNameFocus()
      return
    }
    if (!inputs.surname || inputs.surname.length < 3) {
      renderToast(ERROR, "Surname is empty")
      setSurnameFocus()
      return
    }

    const regexRes = inputs.phone.match(new RegExp(phoneRegex, 'g'))
    const regexEval = regexRes && regexRes[0].length > 0 ? true : false

    if (!inputs.phone || inputs.phone.length < 5 || !regexEval) {
      renderToast(ERROR, "Phone number is empty or not conform")
      setPhoneFocus()
      return
    }
    if (!inputs.birthDate) {
      renderToast(ERROR, "Birthday is empty")
      setBirthDateFocus()
      return
    }
    if (!isUserAdult()) {
      renderToast(ERROR, "You must be at least 18 years old")
      setBirthDateFocus()
      return
    }

    if (!inputs.email) {
      renderToast(ERROR, "Email is empty")
      setEmailFocus()
      return
    }
    if (!inputs.emailConfirmation) {
      renderToast(ERROR, "Email confirmation is empty")
      setEmailConfirmationFocus()
      return
    }
    if (!checkEqualEmail()) {
      renderToast(ERROR, "Your email and confirmation email must match")
      setEmailFocus()
      return
    }

    if (!inputs.password) {
      renderToast(ERROR, "Password is empty")
      setPasswordFocus()
      return
    }

    if (inputs.password.match(new RegExp(inputs.name, 'gi')) || inputs.password.match(new RegExp(inputs.surname, 'gi'))) {
      renderToast(ERROR, "Password must not contain your name or surname")
      setPasswordFocus()
      return
    }

    if (!inputs.passwordConfirmation) {
      renderToast(ERROR, "Password confirmation is empty")
      setPasswordConfirmationFocus()
      return
    }
    if (!checkEqualPassword()) {
      renderToast(ERROR, "password confirmation does not match")
      setPasswordFocus()
      return
    }

    if (checkedBillingAddress) {
      if (!inputs.address || inputs.address.length < 3) {
        renderToast(ERROR, "Address is empty")
        setAddressFocus()
        return
      }

      if (!inputs.country || inputs.country.length < 2) {
        renderToast(ERROR, "Country is empty")
        setCountryFocus()
        return
      }

      if (!inputs.city || inputs.city.length < 3) {
        renderToast(ERROR, "City is empty")
        setCityFocus()
        return
      }

      if (!inputs.taxCode || inputs.taxCode.length < 3) {
        renderToast(ERROR, "Tax Code is empty")
        setTaxCodeFocus()
        return
      }

      if (!inputs.zipCode || inputs.zipCode.length < 3) {
        renderToast(ERROR, "Zip code is empty")
        setZipCodeFocus()
        return
      }
      if (!inputs.fullName) {
        renderToast(ERROR, "Full name is empty")
        setFullNameFocus()
        return
      }

    }
    const schema = new passwordValidator()
    schema
      .is().min(8)                                    // Minimum length 8
      .is().max(20)                                   // Maximum length 20
      .has().symbols(1)                               // Must have at least 1 symbols
      .has().digits(1)                                // Must have at least 1 digits
      .has().not().spaces()                           // Should not have spaces
      .is().not().oneOf([inputs.name, inputs.surname, inputs.email]) // Blacklist these values

    // const validator = schema.validate(inputs.password, { list: true })
    const validator = schema.validate(inputs.password)

    if (!validator) {
      //Password not strong enough
      renderToast(ERROR, "Password does not match security criteria")
      setPasswordFocus()
      return
    }
    //check if Terms is not empty. Should never happen
    if (!checkedTerms) {
      renderToast(ERROR, "Terms and Conditions must be checked")
      return
    }

    if (!checkedGDPR) {
      renderToast(ERROR, "GDPR must be checked")
      return
    }

    //Create user
    createUser()
  }, [inputs.name, inputs.surname, inputs.phone, inputs.birthDate, inputs.email, inputs.emailConfirmation, inputs.password, inputs.passwordConfirmation, inputs.address, inputs.country, inputs.city, inputs.taxCode, inputs.zipCode, inputs.fullName, isUserAdult, checkEqualEmail, checkEqualPassword, checkedBillingAddress, checkedTerms, checkedGDPR, createUser, renderToast, setNameFocus, setSurnameFocus, setPhoneFocus, setBirthDateFocus, setEmailFocus, setEmailConfirmationFocus, setPasswordFocus, setPasswordConfirmationFocus, setAddressFocus, setCountryFocus, setCityFocus, setTaxCodeFocus, setZipCodeFocus, setFullNameFocus])

  const dismissGDPRModal = () => {
    setShowGDPRModal(false)
    setCheckedGDPR(false)
  }

  const dismissGDPRModalWithAcceptance = () => {
    setShowGDPRModal(false)
    setCheckedGDPR(true)
  }

  const clickGDPRLink = (event: any) => {
    event.preventDefault()
    //Show GDPR modal
    setShowGDPRModal(true)
  }

  const dismissTermsAndConditionsModal = () => {
    setShowTermsAndConditionsModal(false)
    setCheckedTerms(false)
  }

  const dismissTermsAndConditionsModalWithAcceptance = () => {
    setShowTermsAndConditionsModal(false)
    setCheckedTerms(true)
  }

  const clickTermsAndConditionsLink = (event: any) => {
    event.preventDefault()
    //Show terms and conditions modal
    setShowTermsAndConditionsModal(true)
  }

  useEffect(() => {
    if (!props.submit) return
    submit()
  }, [props.submit, submit])


  const onSubmit = (e: any) => {
    e.preventDefault();
    submit();
  }

  return (
    <div data-testid="registration-page">
      <IonModal isOpen={showGDPRModal} data-testid='registration-modal'>
        <GDPRModal />
        <IonButton slot="end" onClick={() => dismissGDPRModal()}>
          {t("Cancel")}
        </IonButton>
        <IonButton
          className="no-left-padding"
          slot="end"
          onClick={() => dismissGDPRModalWithAcceptance()}
        >
          {t("Accept")}
        </IonButton>
      </IonModal>

      <IonModal isOpen={showTermsAndConditionsModal}>
        <TermsAndConditionsModal />
        <IonButton
          slot="end"
          onClick={() => dismissTermsAndConditionsModal()}
        >
          {t("Cancel")}
        </IonButton>
        <IonButton
          data-testid="accept-terms-and-conditions"
          id="accept-term"
          className="no-left-padding"
          slot="end"
          onClick={() => dismissTermsAndConditionsModalWithAcceptance()}
        >
          {t("Accept")}
        </IonButton>
      </IonModal>

      <form onSubmit={onSubmit} data-testid="submit-form" autoComplete="on">
        <IonGrid>
          <IonRow>
            <IonCol size="12">

              <IonItemGroup class="ion-margin">
                <IonItemDivider>
                  <IonLabel>{t("Personal Information")}</IonLabel>
                </IonItemDivider>

                <IonRow>
                  <IonCol>
                    <IonInput
                      required
                      data-testid="name-input"
                      id="name"
                      autocomplete='given-name'
                      placeholder={t("Name")}
                      name="name"
                      maxlength={MAX_INPUT_LENGTH}
                      value={inputs.name}
                      onIonInput={(e: any) => handleInputChange(e)}
                      ref={nameRef}
                    />
                  </IonCol>
                  <IonCol>
                    <IonInput
                      required
                      data-testid="surname-input"
                      autocomplete='family-name'
                      placeholder={t("Surname")}
                      name="surname"
                      value={inputs.surname}
                      onIonInput={(e: any) => handleInputChange(e)}
                      ref={surnameRef}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="birthday-field">
                      <IonInput
                        required
                        hour-cycle="h24"
                        id="birthday-field"
                        type="date"
                        data-testid="birth-date-input"
                        placeholder={t("BirthDate")}
                        name="birthDate"
                        value={inputs.birthDate}
                        onIonChange={(e: any) => handleInputChange(e)}
                        ref={birthDateRef}
                        className='birthday-card'
                        autocomplete="bday"
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <IonInput
                      required
                      data-testid="phone-input"
                      autocomplete='tel'
                      placeholder={t("Phone")}
                      type="tel"
                      name="phone"
                      value={inputs.phone}
                      onIonInput={(e: any) => handleInputChange(e)}
                      ref={phoneRef}
                    />
                  </IonCol>
                </IonRow>
              </IonItemGroup>

              <IonItemGroup class="ion-margin">
                <IonItemDivider>
                  <IonLabel>{t("Email Information")}</IonLabel>
                </IonItemDivider>
                <IonRow>
                  <IonCol>
                    <IonInput
                      required
                      data-testid="email-input"
                      autocomplete='email'
                      placeholder={t("Email")}
                      type="email"
                      name="email"
                      value={inputs.email}
                      onIonInput={(e: any) => handleInputChange(e)}
                      ref={emailRef}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      required
                      data-testid="email-confirm-input"
                      type="email"
                      autocomplete='email'
                      placeholder={t("Confirm email")}
                      name="emailConfirmation"
                      value={inputs.emailConfirmation}
                      onIonInput={(e: any) => handleInputChange(e)}
                      ref={emailConfirmationRef}
                    />
                  </IonCol>
                </IonRow>
              </IonItemGroup>
              <IonItemGroup class="ion-margin">
                <IonItemDivider>
                  <IonLabel>{t("Password Information")}</IonLabel>
                </IonItemDivider>
                <IonRow>
                  <IonCol>
                    <IonLabel color="dark" className="ion-text-wrap">
                      <small className="hint">
                        {t("Password should have at least 8 chars, 1 symbol, 1 digit, no spaces. Please do not use your name, surname or email")}
                      </small>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      required
                      placeholder={t('Password')}
                      data-testid="password-input"
                      type={visiblePassword ? 'text' : 'password'}
                      autocomplete='new-password'
                      name="password"
                      value={inputs.password}
                      ref={passwordRef}
                      onIonInput={(e: any) => handleInputChange(e)}
                      clear-on-edit="false"
                    >
                      {inputs.password !== "" && (
                        <IonButton slot="end" shape="round" color="medium" fill="clear"
                          onClick={() => visiblePassword ? setVisiblePassword(false) : setVisiblePassword(true)}><IonIcon icon={visiblePassword ? eye : eyeOff}></IonIcon></IonButton>
                      )}
                    </IonInput>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput
                      required
                      placeholder={t('Confirm password')}
                      data-testid="password-confirm-input"
                      type={visibleConfirmPassword ? 'text' : 'password'}
                      autocomplete='new-password'
                      name="passwordConfirmation"
                      value={inputs.passwordConfirmation}
                      ref={passwordConfirmationRef}
                      onIonInput={(e: any) => handleInputChange(e)}
                      clear-on-edit="false"
                    >
                      {inputs.passwordConfirmation !== "" && (
                        <IonButton slot="end" shape="round" color="medium" fill="clear"
                          onClick={() => visibleConfirmPassword ? setVisibleConfirmPassword(false) : setVisibleConfirmPassword(true)}><IonIcon icon={visibleConfirmPassword ? eye : eyeOff}></IonIcon></IonButton>
                      )}
                    </IonInput>
                  </IonCol>
                </IonRow>
              </IonItemGroup>
              <IonItem>
                <IonCheckbox data-testid="checkbox-input" name="billingAddress" slot="end" color="primary" checked={checkedBillingAddress} onIonChange={(e: any) => onBillingAddressChecked(e)} />
                <IonLabel>{t("Do you need invoices?")}</IonLabel>
              </IonItem>
              {checkedBillingAddress &&
                <BillingAddressInsert
                  inputs={inputs}
                  setInputs={setInputs}
                  addressRef={addressRef}
                  phoneRef={phoneRef}
                  countryRef={countryRef}
                  cityRef={cityRef}
                  taxCodeRef={taxCodeRef}
                  zipCodeRef={zipCodeRef}
                  fullNameRef={fullNameRef}
                />}
            </IonCol>
          </IonRow>
          {
            (customDeployment === 'prod-tr') ?
              <iframe src={`${APP.BASEURL}/files/prod-tr/privacy.html`} style={{
                width: "100%",
                border: "1px solid #EFEFEF",
                backgroundColor: "#EFEFEF",
                margin: "0.5em 0 0.5em 0",
                padding: "0.5em"
              }} />
              : (<>
                <div data-testid="terms-checkbox">
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonLabel>
                          <IonRouterLink
                            href="#"
                            onClick={(e) => clickTermsAndConditionsLink(e)}
                          >
                            <IonText>{t("I agree to the Terms and Conditions")}</IonText>
                          </IonRouterLink>
                        </IonLabel>
                        <IonCheckbox
                          id="terms-checkbox"
                          checked={checkedTerms}
                          onIonChange={(e: any) =>
                            setCheckedTerms(e.detail.checked)
                          }
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel>
                        <IonRouterLink data-testid="link-input" href="#" onClick={(e) => clickGDPRLink(e)}>
                          <IonText>{t("I agree to the Privacy Policy")}</IonText>
                        </IonRouterLink>
                      </IonLabel>
                      <IonCheckbox
                        data-testid="gdpr-checkbox"
                        checked={checkedGDPR}
                        onIonChange={(e: any) => setCheckedGDPR(e.detail.checked)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </>)}
          <IonRow>
            <IonCol className="form_group_recaptcha" data-testid="recaptcha-checkbox">
              <ReCAPTCHA
                size="normal"
                sitekey={config.RECAPTCHA_KEY!}
                onChange={onChange}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </div >
  );
}
