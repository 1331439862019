import React, { useMemo, useState } from 'react';
import { IonButton, IonContent, IonFooter, IonModal, IonSearchbar, IonToolbar } from '@ionic/react';
import { searchProductsHandler } from "mimex-libs/api/endpoints";
import { createLogger } from 'mimex-libs/logger';
import { ProductDto, ProductDtoStatusEnum, VirtualCartItemDto } from 'mimex-libs/sdk';
import ItemsList from '../components/ItemsList';
import { getStoreId } from 'mimex-libs/helpers/userStore';
import { useTranslation } from 'react-i18next';

const logger = createLogger({ component: 'SearchItemModal' })

interface SearchItemProps {
  addItem: CallableFunction,
  showSearchItemModal: boolean,
  hideModal: CallableFunction
}

export default function SearchItemModal(props: SearchItemProps) {

  const [items, setItems] = useState<VirtualCartItemDto[]>()
  const [selectedItem, setSelectedItem] = useState<VirtualCartItemDto>()
  const [addBtnDisabled, setAddBtnDisabled] = useState(true)
  const storeId = useMemo(() => getStoreId(), [])
  const { t } = useTranslation()

  const searchItem = (s: string) => {

    if (s === '') {
      console.log('clear')
      setItems([])
      return
    }

    if (!storeId) return
    if (s.length < 2) return
    console.log('search', s)



    searchProductsHandler(storeId, s)
      .then((response: any) => {
        setItems(response.filter((p: ProductDto) => p.status !== ProductDtoStatusEnum.Archived))
      })
      .catch((e) => {
        logger.error(e, { ref: 'searchProductsHandlerFromUserStore' })
      });

  }

  const onSelectedItem = (event: any, item: VirtualCartItemDto) => {
    console.log('selected', item.productId)
    setSelectedItem(item)
    setAddBtnDisabled(false)
  }

  const addItem = () => {
    props.addItem(selectedItem)
    //reset state
    setSelectedItem(undefined)
    setAddBtnDisabled(true)
  }

  return (
    <div data-testid="search-item-modal">
      <IonModal isOpen={props.showSearchItemModal}>
        <IonContent className="ion-padding">
          <IonSearchbar id="search-input" mode="ios" placeholder={t("Search catalog")} onIonChange={e => searchItem(e.detail.value!)}></IonSearchbar>
          <ItemsList items={items || []} addItem={addItem} selected={onSelectedItem} updating={false} showQuantity={false}
            showEmpty={false} selectedId={selectedItem ? selectedItem.productId : ''} />
        </IonContent>
        <IonFooter>
          <IonToolbar class="ion-padding-start ion-padding-end">
            <IonButton id='cancel-search' slot="start" color="light" onClick={() => props.hideModal(false)}>
              {t('Cancel')}
            </IonButton>
            <IonButton id='add-search' slot="end" class="no-left-padding" disabled={addBtnDisabled} onClick={() => addItem()}>
              {t('Add selected')}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </div>
  )
}

