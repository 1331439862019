import { useEffect, useState } from 'react'
import { UserProfileDto } from 'mimex-libs/sdk';
import { getUserId } from 'mimex-libs/helpers/userStore';
import { getCurrentUserInfo } from 'mimex-libs/api/endpoints';
import { createLogger } from "mimex-libs/logger";

interface InputProps {
  fullName: string
  country: string
  city: string
  name: string
  phone: string
  surname: string
  taxCode: string
  zipCode: string
}

const logger = createLogger({ component: 'Dashboard' })

export default function useProfileInfo() {
  const [inputs, setInputs] = useState<InputProps>()

  useEffect(() => {
    const userId = getUserId()
    if (!userId) return
    getCurrentUserInfo(userId).then((user: UserProfileDto) => {
      setInputs(user)
      logger.debug(`user info retrieved`)
    })
      .catch((e: any) => {
        logger.error(`Error in retrieving info about the user: ${e.message}`, { ref: 'getCurrentUserInfo' })
      })
  }, [])


  return inputs
}
