import {
    IonAvatar,
    IonButton,
    IonButtons, IonHeader, IonIcon, IonImg, IonRouterLink,
    IonText,
    IonToolbar
} from '@ionic/react';
import { notificationsOutline, refresh, search } from 'ionicons/icons';
import { BASE_URL, PATH_HOME, PATH_USER_ACCOUNT } from "mimex-libs/helpers/constants";
import { getUserToken } from 'mimex-libs/helpers/userStore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import "./TopToolbar.css";
import { getCustomerAppConfig } from "mimex-libs/config";

const TopToolbar = (props: any) => {

    const history = useHistory()
    const { t } = useTranslation(["translation"]);
    const config = getCustomerAppConfig()

    return (
        <IonHeader class="ion-no-border" data-testid="top-toolbar">
            <IonToolbar className="top-toolbar">
                <IonButtons slot="start">
                    <IonButton className="top-buttons"
                        slot="primary"
                        fill="clear"
                        href={BASE_URL + PATH_HOME}
                        onClick={(ev) => {
                            ev.preventDefault()
                            history.push(PATH_HOME)
                        }}
                    >
                        <IonImg
                            data-testid="user-account-avatar"
                            src={config.LOGO_URL || `${BASE_URL}/assets/images/logo.svg`} alt="MIMEX"
                            className="top-logo"
                        />
                    </IonButton>
                </IonButtons>

                {getUserToken() && (
                    <IonButtons slot="end" class="ion-padding-end">
                        {/* <IonButton className="top-buttons"
                            slot="primary"
                            color="primary"
                            shape="round"
                            fill="clear"
                        >
                            <IonIcon slot="icon-only" icon={notificationsOutline} />
                        </IonButton> */}
                        <IonButton className=""
                            slot="secondary"
                            color="primary"
                            shape="round"
                            href={BASE_URL + PATH_USER_ACCOUNT}
                            onClick={(ev) => {
                                ev.preventDefault()
                                history.push(PATH_USER_ACCOUNT)
                            }}
                            fill="clear"
                        >
                            <IonAvatar slot="icon-only" className="avatar">
                                <IonImg src={`${BASE_URL}/assets/images/user.png`} className="avatar-image" />
                            </IonAvatar>
                        </IonButton>
                    </IonButtons>
                )}

                {props.registration && (
                    <IonText className="ion-padding" slot="end">
                        <IonRouterLink href={`${BASE_URL}/login`}>
                            {t("Sign in")}
                        </IonRouterLink>
                    </IonText>
                )}
            </IonToolbar>
            <IonToolbar>
                <IonText className="ion-text-left" mode="ios">
                    <div className="ion-padding-start">
                        <h1>{t(props.title)}</h1>
                    </div>
                </IonText>
                {props.session && (
                    <IonButtons slot="end">
                        <IonButton
                            slot="secondary"
                            color="primary"
                            size="small"
                            onClick={() => props.fetch(props.session)}
                            fill="clear"
                        >
                            <IonIcon slot="icon-only" icon={refresh} />
                        </IonButton>

                        {props.virtualcart && (
                            <IonButton
                                slot="primary"
                                color="primary"
                                size="small"
                                onClick={() => props.search(true)}
                                fill="clear"
                                id="search-button"
                            >
                                <IonIcon slot="icon-only" icon={search} />
                            </IonButton>
                        )}
                    </IonButtons>
                )}
            </IonToolbar>
        </IonHeader>
    )
}

export default TopToolbar
