
export const parseCurrency = (c: string):string => {
  if (c === "try") return "₺";
  return "€";
};


export const formatTotalPrice = (grandTotal:number, isCents=true, rounded = false): string => {
  grandTotal = isCents ? grandTotal/100 : grandTotal
  const total = (Math.floor(grandTotal * 100) / 100).toString()
  const [a, b] = total.split('.')
  let decimal = b === undefined ? 
    rounded ? '' : '00' : 
    (b.length < 2 ? `${b}0` : b)
  return `${a}.${decimal}`
}