import React from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next'
import { SelectChangeEventDetail } from '@ionic/core/components';
import { ReceiptSummary } from './dto/receipts';

interface ReceiptSelectionProps {
  Months: ReceiptSummary[]
  onMonthSelection: (value: ReceiptSummary) => void
  selectedMonth: ReceiptSummary
}

export const ReceiptSelection: React.FC<any> = (props: ReceiptSelectionProps) => {

  const { Months, onMonthSelection, selectedMonth } = props
  const { t } = useTranslation(["translation"]);

  return (
    <IonItem className="session-option" data-testid="receipt-select">
      <IonLabel>{t("Months")}</IonLabel>
      <IonSelect
        data-testid="month-select"
        value={selectedMonth}
        onIonChange={(event: CustomEvent<SelectChangeEventDetail<ReceiptSummary>>) => {
          if (!event.detail || !event.detail.value) return
          onMonthSelection(event.detail.value)
        }}>
        {Months.map((month, index) => {
          return (
            <IonSelectOption data-testid="month-option" key={index} value={month}>{t(month.label)} {month.year || ""}{month.count ? ' (' + month.count + ')' : ''}</IonSelectOption>
          )
        })}
      </IonSelect>
    </IonItem>
  );
};