
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { BASE_URL } from '../helpers/constants'
import { setSelectedLanguage } from '../helpers/userStore'

interface InitOptions {
}

interface i18n {
    on: (ev: string, cb: (ev: unknown) => void) => void
    use: (p: any) => this
    init(InitOptions): Promise<unknown>
}

export const configureI18N = (i18n: i18n) => {
    //To load the translation files
    i18n.on('languageChanged', (lng: string) => {
        console.log(`set language ${lng}`)
        setSelectedLanguage(lng)
        localStorage.setItem('lng', lng)
    })

    i18n
        .use(LanguageDetector)
        .use(I18NextHttpBackend)
        .use(initReactI18next)
        .init({
            debug: process.env.NODE_ENV === 'production' ? false : true,
            fallbackLng: 'en',
            supportedLngs: ['en', 'it', 'es', 'tr'],
            whitelist: ['en', 'it', 'es', 'tr'], //Array of abbreviations of the languages
            interpolation: {
                escapeValue: false,
            },
            ns: ['translation'], //Names of the translation files, include file names in array if > 1
            backend: {
                loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json`, //Path to the translation files
                addPath: false,
            },
            detection: {
                order: [
                    'localStorage',
                    'queryString',
                    'cookie',
                    'sessionStorage',
                    'navigator',
                    'htmlTag',
                    'path',
                    'subdomain',
                ],
                cache: ['cookie'],
                lookupLocalStorage: 'lng',
                checkWhitelist: true,
            },
            saveMissing: true,
            saveMissingTo: 'all',
        } as InitOptions)
}
