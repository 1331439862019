import { useMemo } from 'react'
import useResponsiveFontSize from '../CustomHooks/useResponsiveFontSize'

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    return useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    iconColor: '#666EE8',
                    lineHeight: '25px',
                    fontWeight: 300,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    fontSmoothing: 'antialiased',
                },
                invalid: {
                    color: '#9e2146',
                    border: '1px solid red !important',
                    iconColor: '#fa755a',
                },
            },
        }),
        [fontSize]
    )
}

export default useOptions
